import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useState } from 'react';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
// @mui
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
// types
import { IJobFilterValue, IJobFilters } from 'src/types/job';
import { IStoreItem } from 'src/types/store';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import useHasRoles from 'src/hooks/use-has-roles';
import EmptyContent from 'src/components/empty-content';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { Box, CircularProgress } from '@mui/material';
import JobFiltersResult from '../job-filters-result';
import StoreList from '../store-list';
import StoreFilter from './store-filter-view';



// ----------------------------------------------------------------------

const defaultFilters = {
  roles: [],
  locations: [],
  benefits: [],
  experience: 'all',
  employmentTypes: [],
  whatsappStatus: null,
  botStatus: null,
  name: ''
};

// ----------------------------------------------------------------------

export default function StoreListView() {
  const settings = useSettingsContext();
  const isAdmin = useHasRoles(['ROLE_ADMIN']);

  const [totalStores, setTotalStores] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [sortBy, setSortBy] = useState('latest');

  const [storesFiltered, setStoresFiltered] = useState<number>(0);
 

  const [filters, setFilters] = useState(defaultFilters);
  // const [stores] = useStores();
  const [stores, setStores] = useState<IStoreItem[]>([]);

  const dataFiltered = applyFilter({
    inputData: stores,
    filters,
    sortBy,
  });
  const canReset = !isEqual(defaultFilters, filters);

  const notFound = !dataFiltered.length && canReset;

  const getStores = useCallback(() => {

    const searchName: string = filters.name ? `&order=name&dir=ASC&search=${filters.name}` : ""
    const state = filters.botStatus != null ? (filters.botStatus ? '&state_active=active' : "&state_active=inactive"): ''  
    const status = filters.whatsappStatus != null ? (filters.whatsappStatus ? '&status_active=active' : "&status_active=inactive"): ''  
    const QUERY = `?page=${page}&length=10${searchName}${state}${status}`
    if (page === 0) {
      setIsLoading(true)
    }
    

    axios
      .get(API_ENDPOINTS.store.listQuery(QUERY))
      .then(({ data }) => {
        setTotalStores(data.total);
        setStoresFiltered(data.filtered);
        if (page === 0) {
          setStores([]);
        }

        setStores((prevData) => {
          // Filtrar los nuevos datos para evitar duplicados
          const newData = data.rows.filter(
            (newItem: any) => !prevData.some((existingItem) => existingItem.id === newItem.id)
          );
          
          return [...prevData, ...newData];
        });
        setIsLoading(false)
        
    
      })
      .catch((error) => {
        // Manejar errores
      });
  }, [page, filters]);



  
  useEffect(() => {
    getStores(); 
  }, [getStores]); 
  


  const handlePaginationChange = () => {
    let page_aux = page;
    setPage((page_aux += 1));
  };

  
  const handleFilters = useCallback((name: string, value: IJobFilterValue) => {
    setPage(0);
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);


  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
    setPage(0)
  }, []);

  

  const renderFilters = (
    <> {/* Este es el nuevo elemento padre */}
     <Stack direction="column" spacing={2}>
        <Stack>
          <StoreFilter 
            filters={filters}
            onFilters={handleFilters}
            onResetFilters={() => {
              setFilters(defaultFilters);
            }}
            canReset={canReset} 
          />
        </Stack>
     </Stack>

    </> 
  );
  

  const renderResults = (
    <>
    <Stack spacing={1.5}>
    <Box sx={{ typography: 'body2' }}>
      <strong>{storesFiltered}</strong>
      <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
        resultados
      </Box>
    </Box>

    <Stack flexGrow={1} spacing={1} direction="row" flexWrap="wrap" alignItems="center">

      <Button
        color="error"
        onClick={handleResetFilters}
        startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
      >
        Limpiar
      </Button>
    </Stack>
  </Stack>
    </>
    
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Stack
        direction="column"
        alignItems="start"
        justifyContent="space-between"
        sx={{ py: 2, pr: 1, pl: 2.5 }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Listado de Comercios
        </Typography>

        {isAdmin && (
          <Button
            sx={{ my: 3 }}
            variant="contained"
            endIcon={<Iconify icon="material-symbols:add" />}
            component={RouterLink}
            href={paths.dashboard.store.new}
          >
            Agregar nueva
          </Button>
        )}
      </Stack>

      <Stack
        spacing={2.5}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {renderFilters}

        {canReset && !isLoading && renderResults}
      </Stack>

      {notFound && <EmptyContent filled title="No Data" sx={{ py: 10 }} />}
      <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pr: 5, pl: 2.5 }}
        >
        {isLoading && <CircularProgress size={64} color="info" />}
      </Stack>
     
      {!isLoading &&  <StoreList stores={stores} />}
      {!isLoading && (
        <>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          sx={{ py: 5, pr: 5, pl: 2.5 }}
        >
          
          <Typography variant="subtitle2" component="span" sx={{ mx: 0.25 }}>
              Mostrando {stores.length} de {storesFiltered}
          </Typography>

          {stores.length < storesFiltered && (
          <Button
            sx={{ my: 3 }}
            variant="contained"
            endIcon={<Iconify icon="mdi:package-variant-closed-delivered" />}
            onClick={handlePaginationChange}
          >
           
            Cargar más
          </Button>
        )}
        </Stack>
        </>
      )}
       

    </Container>
  );
}

// ----------------------------------------------------------------------

const applyFilter = ({
  inputData,
  filters,
  sortBy,
}: {
  inputData: IStoreItem[];
  filters: IJobFilters;
  sortBy: string;
}) => {
  const { employmentTypes, experience, roles, locations, benefits } = filters;

  // SORT BY
  if (sortBy === 'latest') {
    inputData = orderBy(inputData, ['createdAt'], ['desc']);
  }

  if (sortBy === 'oldest') {
    inputData = orderBy(inputData, ['createdAt'], ['asc']);
  }

  if (sortBy === 'popular') {
    inputData = orderBy(inputData, ['totalViews'], ['desc']);
  }

  return inputData;
};
