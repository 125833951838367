import * as Yup from 'yup';
import React, { useMemo, useEffect, useCallback, useState } from 'react'
import { IPromptItem } from 'src/types/prompt';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'src/routes/hook';
import { useResponsive } from 'src/hooks/use-responsive';
import { paths } from 'src/routes/paths';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFEditor, RHFSelect, RHFTextField } from 'src/components/hook-form';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/system/Stack';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { ICountryItem } from 'src/types/location';
import { ICategoryItem } from 'src/types/category';
import { MenuItem } from '@mui/material';
import { IStoreItem } from 'src/types/store';
import useHasRoles from 'src/hooks/use-has-roles';
import useStores from 'src/hooks/use-stores';

type Props = {
  currentDenyReason?: ICategoryItem;
  };
type FormValuesProps = any;

export default function DenyReasonNewEditForm({ currentDenyReason }: Props) {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');
  
  const { enqueueSnackbar } = useSnackbar();
  const [stores] = useStores();
  const NewDenyReasonSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    store: Yup.string().required('La tienda es requerida'),
  });
  const defaultValues = useMemo(
    () => ({
      name: currentDenyReason?.name || '',
      store: currentDenyReason?.store?.id || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDenyReason]
  );
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewDenyReasonSchema),
    defaultValues,
    mode: 'onBlur',
  });
  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  useEffect(() => {
    if (currentDenyReason) {
      reset(defaultValues);
    }
  }, [currentDenyReason, defaultValues, reset]);

  const editDenyReason = useCallback(
    async (id: string, params: FormValuesProps) => {
      const {name, store} = params;
      const DATA = {
        name,
        store: parseInt(store, 10),
      }
      axios
    .put(API_ENDPOINTS.denyReason.edit(id), DATA)
    .then(({data}) => {
      enqueueSnackbar('Motivo de rechazo editado con éxito');
      reset();
      router.push(paths.dashboard.store.details(store))
      // router.push(paths.dashboard.denyReason.root);
    })
    .catch((error) => {
      
    })
    },
    [enqueueSnackbar, reset, router],
  ); 
  const createDenyReason= useCallback(
    async (params: FormValuesProps) => {
      const {name, store} = params;
      const DATA = {
        name,
        store: parseInt(store, 10),
      }
      axios
      .post(API_ENDPOINTS.denyReason.create, DATA)
      .then(({data}) => {
        
        enqueueSnackbar('Motivo de rechazo creado con éxito');
        reset();
        router.push(paths.dashboard.store.details(store))
        // router.push(paths.dashboard.denyReason.root);
      })
      .catch((error) => {
        
      })
    },
    [enqueueSnackbar, reset, router],
  );

  const onSubmit = useCallback(
    async (params: FormValuesProps) => {
      if (currentDenyReason) {
        editDenyReason(currentDenyReason.id, params) 
      } else {
        createDenyReason(params);
      }
    },
    [currentDenyReason, editDenyReason, createDenyReason]
  );
  
  
  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Motivo de rechazo
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
           Complete los siguientes datos...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Detalle" />}

          <Stack spacing={3} sx={{ p: 3 }}>
          <Stack spacing={1.5}>
              <Typography variant="subtitle2">Tienda</Typography>
              <RHFSelect
                name="store"
                placeholder="Seleccione una provincia"
                InputLabelProps={{ shrink: true }}
                defaultValue=""
              >
                <MenuItem key="default-p" value="">
                  Seleccione una tienda
                </MenuItem>
                {stores.map((store: IStoreItem) => (
                  <MenuItem key={store?.id} value={store?.id}>
                    {store?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Motivo de rechazo</Typography>
              <RHFTextField name="name" placeholder="Ingrese un motivo de rechazo" />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
      
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentDenyReason ? 'Crear Motivo de rechazo' : 'Guardar cambios'}
        </LoadingButton>
      </Grid>
    </>
  );
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
