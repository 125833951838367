import React, { useCallback, useEffect, useState } from 'react';
// @mui
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { fDateTime } from 'src/utils/format-time';

import { useSnackbar } from 'src/components/snackbar';
import useLocales from 'src/locales/use-locales';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
// routes
import { paths } from 'src/routes/paths';
// components
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { reset } from 'src/redux/slices/notification';

import { Badge, Button, Chip, FormControl, Grid, InputLabel, OutlinedInput, Select, Tooltip } from '@mui/material';

import { format } from 'date-fns';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { useBoolean } from 'src/hooks/use-boolean';
import { RouterLink } from 'src/routes/components';
import { useRouter } from 'src/routes/hook';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { Mixpanel } from 'src/Mixpanel';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { fCurrency } from 'src/utils/format-number';

import {
  AccessAlarm,
  Chat,
  ChatBubbleOutline,
  ChatBubbleOutlineRounded,
} from '@mui/icons-material';

import useHasRoles from 'src/hooks/use-has-roles';
import { LoadingButton } from '@mui/lab';
import printJS from 'print-js-updated';
import { useReactToPrint } from 'react-to-print';

import OrderPickUpTimeModal from './order-pickupTime-modal';
import OrderRejectedModal from './order-rejected-modal';
import ConversationAvatar from '../conversation/conversation-avatar';





// ----------------------------------------------------------------------

type Props = {
  order: any;
  onView: VoidFunction;
  onEdit: VoidFunction;
  onDelete: VoidFunction;
  onStatusChange?: VoidFunction;
  shouldReload?: boolean;
};

export default function OrderItem({
  order,
  onView,
  onEdit,
  onDelete,
  shouldReload = true,
  onStatusChange,
}: Props) {
  const popover = usePopover();
  const { t } = useLocales();
  const router = useRouter();
  const getColor = (
    nextState: any
  ): 'success' | 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' =>
    nextState?.class === 'order-state-accepted'
      ? 'success'
      : nextState?.class === 'order-state-rejected'
      ? 'error'
      : 'primary';


  //  const { id, name, address, state, delivery, takeaway, store_categories} = order;
  const [currentOrder, setCurrentOrder] = useState(order);
  const dispatch = useDispatch();
  const [pdfFile, setPDFFile] = useState<any>('');
  const componentRef = React.useRef(null);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;
  const [printing, setPrinting] = useState<boolean>(false);


  const {
    state,
    id,
    customer,
    paymentMethod,
    created_at,
    type,
    conversation,
    pickupTime,
    store,
    orderProducts,
    nextStates,
  } = currentOrder;
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(
    conversation?.unreadMessagesAfterAccepted
  );
  const notification = useSelector((stateConversation: any) => stateConversation.notification);

  const isAdmin = useHasRoles(['ROLE_ADMIN']);
  useEffect(() => {
    if (notification.conversationUpdate) {
      const conversationUpdate = notification.conversationUpdate;
      if (
        conversationUpdate?.order_id &&
        conversationUpdate.order_id === parseInt(currentOrder?.id, 10)
      ) {
        axios
          .get(API_ENDPOINTS.order.details(conversationUpdate?.order_id))
          .then(({ data }) => {
            setCurrentOrder(data);
            dispatch(reset());
          })
          .catch((error) => {});
      }
    }
  }, [notification, currentOrder, dispatch, store]);

  useEffect(() => {
    setUnreadMessagesCount(currentOrder?.conversation?.unreadMessagesAfterAccepted);
  }, [currentOrder]);
  const rejectModal = useBoolean();
  const pickUpModal = useBoolean();
  const [pickUpTime, setpickUpTime] = useState(store?.waiting_time || '0');
  const closeModal = () => {
    rejectModal.onFalse();
    pickUpModal.onFalse();
  };
  const { enqueueSnackbar } = useSnackbar();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const desktopPrint = () => {
    const base64data = pdfFile.split(';base64,').pop(); // Obtener la parte Base64
    if (base64data) {
      printJS({ printable: base64data, type: 'pdf', base64: true });
    }
  };




  const printOrder = useCallback(() => {
    if (id) {
     axios
       .get(API_ENDPOINTS.order.pdf(id), { responseType: 'blob' })
       .then(async ({ data }) => {
         const reader = new FileReader();
         reader.onloadend = () => {
           setPDFFile(reader.result);
         };
         reader.readAsDataURL(data);
       })
       .catch((error) => {
         setPrinting(false);
         console.error('Error al obtener el PDF:', error);
       });
    }
     
   }, [id]);

   useEffect(() => {
    printOrder();
  }, [printOrder]);

  const rejectOrder = useCallback(
    async (denyReason_id: string) => {
      axios
        .put(API_ENDPOINTS.order.deny(id, denyReason_id))
        .then(({ data }) => {
          enqueueSnackbar('Orden rechazada');
          Mixpanel.track('Rejected Order', { id, denyReasonId: denyReason_id });
          setCurrentOrder(data?.order);
          if (onStatusChange) {
            onStatusChange();
          }
          /* if (shouldReload) {
             router.reload();
           } */
        })
        .catch((error) => {});
    },
    [id, enqueueSnackbar, onStatusChange]
  );
  const acceptOrder = useCallback(
    async (val: string) => {
      axios
        .put(API_ENDPOINTS.order.accepted(id, val))
        .then(({ data }) => {
          enqueueSnackbar('Orden aceptada');
          Mixpanel.track('Accepted Order', { id, pickUpTime: val });
          setCurrentOrder(data?.order);
          if (onStatusChange) {
            onStatusChange();
          }
          // router.reload();
        })
        .catch((error) => {});
    },
    [id, enqueueSnackbar, onStatusChange]
  );
  const changeStatus = useCallback(
    async (URL: string, stateClass: string) => {
      axios
        .put(URL)
        .then(({ data }) => {
          enqueueSnackbar('Se actualizó el estado de la orden');
          if (stateClass === 'order-state-finalized') {
            Mixpanel.track('Completed Order', { id });
          } else if (stateClass === 'order-state-accepted') {
            const TIME = pickUpTime === '0' ? '' : String(pickUpTime);
            Mixpanel.track('Accepted Order', { id, pickUpTime: TIME });
          }
          /*  if (shouldReload) {
              router.reload();
            } */
          if (onStatusChange) {
            onStatusChange();
          }

          setCurrentOrder(data?.order);
        })
        .catch((error) => {});
    },
    [enqueueSnackbar, onStatusChange, id, pickUpTime]
  );
  const updateState = (stateClass: string) => {
    let URL = '';
    switch (stateClass) {
      case 'order-state-accepted':
         pickUpModal.onTrue();
        // URL = API_ENDPOINTS.order.accepted(id, pickUpTime === '0' ? '' : String(pickUpTime));
        break;
      case 'order-state-rejected':
        rejectModal.onTrue();
        break;
      case 'order-state-finalized':
        URL = API_ENDPOINTS.order.finalized(id);
        break;
      case 'order-state-done':
        URL = API_ENDPOINTS.order.done(id);
        break;
      case 'order-state-ready-to-pick-up':
        URL = API_ENDPOINTS.order.readyToPickup(id);
        break;
      case 'order-state-sent':
        URL = API_ENDPOINTS.order.sent(id);
        break;
      default:
        break;
    }
    if (URL) {
      changeStatus(URL, stateClass);
    }
  };

  const apiDateObj = new Date(pickupTime);
  const adjustedDateObj = new Date(
    apiDateObj.getUTCFullYear(),
    apiDateObj.getUTCMonth(),
    apiDateObj.getUTCDate(),
    apiDateObj.getUTCHours(),
    apiDateObj.getUTCMinutes(),
    apiDateObj.getUTCSeconds()
  );


  const formattedPickupTime = adjustedDateObj.getHours() !== 0 || adjustedDateObj.getMinutes() !== 0 ? fDateTime(adjustedDateObj, 'HH:mm') : '';
  const getVariant = (
    nextState: any
  ): 'contained' | 'soft' =>
    nextState?.class === 'order-state-accepted'
      ? 'contained'
      : nextState?.class === 'order-state-rejected'
        ? 'soft'
        : 'contained'

  return (
    <>
      <Card sx={{ width: '100%' }}>
        {/* <IconButton onClick={popover.onOpen} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton> */}                
        <Stack sx={{ p: 3, pb: 2 }}>
          <ListItemText
            sx={{ mb: 0.5 }}
            primary={
              <Link component={RouterLink} href={paths.dashboard.order.details(id)} color="inherit">
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: '100%' }}
                >
                  <Stack direction="row" spacing={3} alignItems="center" sx={{ flexShrink: 0 }}>
                    <Stack direction="column" alignItems="flex-start">
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="body2" noWrap>
                          #{id}
                        </Typography>
                        <Label
                          variant="soft"
                          color={
                            (state?.class === 'order-state-accepted' && 'success') ||
                            (state?.class === 'order-state-created' && 'warning') ||
                            (state?.class === 'order-state-rejected' && 'error') ||
                            (state?.class === 'order-state-finalized' && 'default') ||
                            'primary'
                          }
                        >
                          {t(state?.name)}
                        </Label>
                        {unreadMessagesCount > 0 && (
                          <Chip
                            icon={<Iconify icon="majesticons:chat-line" width={15} />}
                            sx={{ borderRadius: 20 }}
                            label={unreadMessagesCount}
                            size="small"
                            color="error"
                          />
                        )}
                      </Stack>
                      <Typography variant="caption" color="grey" noWrap>
                        {created_at && format(new Date(created_at), 'dd MMM yyyy, p')}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Link>
            }
            primaryTypographyProps={{
              typography: 'subtitle1',
            }}
          />

          <Divider sx={{ borderStyle: 'groove' }} />
          <Stack spacing={0.5} sx={{ mt: 0.5, mb: 1 }}>
            {isAdmin && (
              <Stack spacing={1} direction="row" alignItems="center" sx={{ typography: 'caption' }}>
                <Iconify width={16} icon="clarity:store-line" color="primary.main" />
                {store?.name}
              </Stack>
            )}
            {/*
            <Stack spacing={1} direction="row" alignItems="center" sx={{ typography: 'caption' }}>
              <>
                {customer?.frecuent ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Iconify width={16} icon="solar:star-bold" color="warning.main" />
                  </div>
                ) : (
                  <Iconify width={16} icon="tabler:user" color="primary.main" />
                )}
              </>
              {customer?.name}, {customer?.username}
            </Stack>
              */}

            <Stack spacing={1} direction="row" alignItems="center" sx={{ typography: 'caption' }}>
              <Iconify width={16} icon="tdesign:money" color="primary.main" />
              {t(`paymentMethod.${paymentMethod?.name}`)} {paymentMethod?.enterprise}
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center" sx={{ typography: 'caption' }}>
              <Iconify
                width={16}
                icon={type?.id === 1 ? 'ic:baseline-delivery-dining' : 'ep:takeaway-box'}
                color="primary.main"
              />
              {type?.name}{' '}
              <Typography variant="caption" noWrap color="black">
                {!!type?.address &&
                  `${type?.address?.street} ${type?.address?.houseNumber} ${type?.address?.details}`}{' '}
                {formattedPickupTime}
              </Typography>
            </Stack>
            {!!type?.delivery_zone && (
              <Stack spacing={1} direction="row" alignItems="center" sx={{ typography: 'caption' }}>
                <Iconify width={16} icon="lets-icons:map-fill" color="primary.main" />
                <Typography variant="caption" noWrap color="black">
                  {!!type?.delivery_zone &&
                    `${type?.delivery_zone?.name} ${fCurrency(type.delivery_zone.price)}`}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Divider sx={{ borderStyle: 'dashed' }} />
          {currentOrder?.new_method_used && (
            <Stack direction="column" sx={{ mt: 2, whiteSpace: 'break-spaces' }}>
              <Typography variant="body2" color="black">
                <strong>{currentOrder?.conversation?.conversation_summary}</strong>
              </Typography>
            </Stack>
          )}
          <Stack direction="column" sx={{ mt: 2 }}>
            {orderProducts?.map((orderProduct: any) => (
              <React.Fragment key={`${id}-product-${orderProduct.id}`}>
                <Typography variant="subtitle1" color="black">
                  - <strong>{orderProduct?.quantity} x</strong> {orderProduct?.product?.name}
                  {orderProduct?.comment ? `- ${orderProduct?.comment}` : ''}
                </Typography>
                {orderProduct.orderProductAddons.map((addon: any) => (
                  <Typography
                    variant="subtitle2"
                    color="black"
                    key={`${id}-product-addon-${addon.id}`}
                    sx={{ px: 3 }}
                  >
                    - <strong>{addon?.quantity} x</strong> {addon?.productAddon?.name}
                  </Typography>
                ))}
              </React.Fragment>
            ))}
          </Stack>
        </Stack>
       {/*   {state.class === 'order-state-created' && (
          <Stack spacing={1.5} sx={{ p: 2 }}>
           
            <FormControl
              sx={{
                flexShrink: 0,
              }}
            >
              {pickUpTime >= 0 ? (
                <TextField
                  type="number"
                  inputProps={{ min: 1 }}
                  label="Tiempo de preparación"
                  helperText="Usa las opciones abajo para autocompletar."
                  value={pickUpTime}
                  onChange={(ev) => setpickUpTime(ev.target.value)}
                  InputProps={{
                    sx: {
                      textAlign: 'center',
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <Box component="span">minutos</Box>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  label="Tiempo de preparación"
                  helperText="Usa las opciones abajo para autocompletar."
                  value="No indicar"
                  disabled
                />
              )}
            </FormControl>
            
            <Box
              display="grid"
              gridTemplateColumns="repeat(3, 1fr)"
              sx={{ typography: 'subtitle1' }}
              columnGap={1}
              rowGap={1}
            >
              <Chip
                key="0"
                color="primary"
                label="No indicar"
                size="small"
                variant={pickUpTime === '-1' ? 'filled' : 'soft'}
                onClick={() => {
                  setpickUpTime('-1');
                }}
              />
              <Chip
                key="15"
                color="primary"
                label="15 min"
                size="small"
                variant={pickUpTime === '15' ? 'filled' : 'soft'}
                icon={<Iconify icon="material-symbols-light:alarm-outline" />}
                onClick={() => {
                  setpickUpTime('15');
                }}
              />
              <Chip
                key="30"
                color="primary"
                label="30 min"
                size="small"
                variant={pickUpTime === '30' ? 'filled' : 'soft'}
                icon={<Iconify icon="material-symbols-light:alarm-outline" />}
                onClick={() => {
                  setpickUpTime('30');
                }}
              />
              <Chip
                key="45"
                color="primary"
                label="45 min"
                size="small"
                variant={pickUpTime === '45' ? 'filled' : 'soft'}
                icon={<Iconify icon="material-symbols-light:alarm-outline" />}
                onClick={() => {
                  setpickUpTime('45');
                }}
              />
              <Chip
                key="60"
                label="60 min"
                color="primary"
                size="small"
                variant={pickUpTime === '60' ? 'filled' : 'soft'}
                icon={<Iconify icon="material-symbols-light:alarm-outline" />}
                onClick={() => {
                  setpickUpTime('60');
                }}
              />
              <Chip
                key="0"
                color="primary"
                label="Personalizado"
                size="small"
                variant={pickUpTime === '-1' ? 'filled' : 'soft'}
                onClick={() => {
                  setpickUpTime('-1');
                }}
              />
            </Box>
            
          </Stack>
        )} */}
        <Stack
          spacing={1.5}
          display="grid"
          gridTemplateColumns={nextStates.length === 1 ? '1fr' : 'repeat(2, 1fr)'}
          sx={{ p: 3 }}
        >
          {nextStates.map((nextState: any) => (
            <Button
              fullWidth
              key={`order${id}-${nextState.class}`}
              color={getColor(nextState)}
              variant="contained"
              onClick={() => updateState(nextState?.class)}
            >
              {t(`state.${nextState?.name}`)}
            </Button>
          ))}
        </Stack>
        {pdfFile && (
          <Stack spacing={2} sx={{ px: 1 }}>
            <LoadingButton
              color="inherit"
              variant="contained"
              sx={{ width: '100%' }}
              startIcon={<Iconify icon="solar:printer-minimalistic-bold" />}
              onClick={isMobile ? handlePrint : desktopPrint}
              loading={printing}
            >
              Imprimir Comanda
            </LoadingButton>
          </Stack>
        )}
      </Card>
      {rejectModal.value && (
        <OrderRejectedModal
          open={rejectModal.value}
          storeId={store.id}
          onClose={closeModal}
          onReject={rejectOrder}
        />
      )}
      {pickUpModal.value && (
        <OrderPickUpTimeModal
          open={pickUpModal.value}
          onClose={closeModal}
          onSelect={acceptOrder}
          store={store}
        />
      )}
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            onView();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          Detalle
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            onEdit();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Editar
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            onDelete();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Eliminar
        </MenuItem>
      </CustomPopover>
    </>
  );
}
