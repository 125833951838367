import { Helmet } from 'react-helmet-async';
// sections
import { ProductComboCreateView } from 'src/sections/product-combos/view';

// ----------------------------------------------------------------------

export default function ProductCombosCreatePage() {
  return (
    <>
      <Helmet>
        <title> Nuevo Combo </title>
      </Helmet>

      <ProductComboCreateView />
    </>
  );
}
