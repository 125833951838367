import { Helmet } from 'react-helmet-async';
// sections
import { StoreCategoryListView } from 'src/sections/store-category/view';

// ----------------------------------------------------------------------

export default function StoreCategoryListPage() {
  return (
    <>
      <Helmet>
        <title>Tipos de Comercios</title>
      </Helmet>

      <StoreCategoryListView />
    </>
  );
}
