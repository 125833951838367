// spinnerSlice.js
import { createSlice } from '@reduxjs/toolkit';

const storeSlice = createSlice({
  name: 'store',
  initialState: {},
  reducers: {
    set: (state, {payload}) => {      
      state = payload;
      return { ...state, ...payload }
    },
    get: (state) => state    
    ,
  },
});

export const { set, get } = storeSlice.actions;
export default storeSlice.reducer;