// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// types
// utils
import useLocales from 'src/locales/use-locales';
// routes
import { paths } from 'src/routes/paths';
// components
import { Button, FormControlLabel, Switch } from '@mui/material';
import { useState } from 'react';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { RouterLink } from 'src/routes/components';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { Mixpanel } from 'src/Mixpanel';
import Label from 'src/components/label';
import { useDispatch } from 'src/redux/store';
import { showSpinner } from 'src/redux/slices/spinner';
import LoadingButton from '@mui/lab/LoadingButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';

// ----------------------------------------------------------------------

type Props = {
  row: any;
  onView?: VoidFunction;
  onEdit?: VoidFunction;
  onDisable?: VoidFunction;
  onEnable?: VoidFunction;
  onDeleteRow: VoidFunction;
  onQRGeneration: VoidFunction;
};

export default function InstanceItem({
  row,
  onView,
  onEdit,
  onEnable,
  onDisable,
  onDeleteRow,
  onQRGeneration
}: Props) {
  const popover = usePopover();
  const { t } = useLocales();
  const { id, username, state, basePath, store, channelName, actualStatus } = row;
  const [active, setActive] = useState(state?.name === 'Active');
  const [generatingQR, setGeneratingQR] = useState(false);
  const [stateName, setStateName] = useState(state?.name);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const confirm = useBoolean();

  const handleChange = (event: any) => {
    if (event.target.checked === true) {
      setActive(true);
      axios
        .put(API_ENDPOINTS.channel.active(id))
        .then(({ data }) => {
          enqueueSnackbar('Activado con éxito');
          Mixpanel.track('User Activates Bot', {
            store_id: store?.id,
            store_name: store?.name,
            channel: channelName,
            username,
          });
          setStateName('Active');
        })
        .catch((error) => {
          setActive(false);
          enqueueSnackbar('Se ha producido un error', { variant: 'error' });
        });
    } else {
      setActive(false);
      axios
        .put(API_ENDPOINTS.channel.pause(id))
        .then(({ data }) => {
          enqueueSnackbar('Pausado con éxito');
          Mixpanel.track('User Pauses Bot', {
            store_id: store?.id,
            store_name: store?.name,
            channel: channelName,
            username,
          });
          setStateName('Paused');
        })
        .catch((error) => {
          setActive(true);
          enqueueSnackbar('Se ha producido un error', { variant: 'error' });
        });
    }
  };
  const generateQR = () => {
    setGeneratingQR(true);
    onQRGeneration();
    dispatch(showSpinner());
    axios
      .post(API_ENDPOINTS.instance.qr(basePath, id))
      .then(({ data }) => {
        Mixpanel.track('User Requests to Activate an Instance', {
          store_id: store?.id,
          store_name: store?.name,
          channel: channelName,
          username,
        });
        setGeneratingQR(false);
        enqueueSnackbar('QR solicitado, en unos instantes podrás escanearlo');
      })
      .catch((error) => {
        enqueueSnackbar('Se ha producido un error al solicitar el QR', { variant: 'error' });
      });
  };
  return (
    <>
      <Card>
        <IconButton onClick={confirm.onTrue} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <Iconify icon="solar:trash-bin-trash-bold"  sx={{ color: 'error.main' }}/>
        </IconButton>

        <Stack sx={{ p: 3, pb: 2 }}>
          
          <Box rowGap={1.5} display="grid" sx={{ p: 1 }}>
            <Stack
              flexShrink={0}
              spacing={0.5}
              direction="row"
              alignItems="center"
              sx={{
                color: stateName === 'Paused' ? 'error.main' : 'primary.main',
                typography: 'caption',
                minWidth: 0,
              }}
            >
              <Iconify width={24} icon={stateName === 'Paused' ? 'lucide:bot-off' : 'lucide:bot'} />
              {t(`Bot ${stateName}`)}
              <Switch
                checked={active}                
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Stack>
            <Stack flexShrink={0} spacing={0.5} direction="row" alignItems="center">
              <Label
                variant="outlined"
                color={
                  (actualStatus?.name === 'Connected' && 'success') ||
                  (actualStatus?.name === 'Disconnected' && 'error') ||
                  (actualStatus?.name === 'Failed' && 'warning') ||
                  'default'
                }
                flexWrap='wrap'
              >
                WhatsApp{' '}
                {actualStatus?.name ? t(`state.${actualStatus.name}`) : t('state.Undefined')}
              </Label>
            </Stack>
          </Box>
        </Stack>
        {actualStatus?.name !== 'Connected' && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Box textAlign='center'>
            <LoadingButton

              variant="contained"
              size="medium"
              sx={{width: '100%', mh: 2, borderTopLeftRadius: '0',  borderTopRightRadius: '0'}}
              onClick={() => generateQR()}
              endIcon={<Iconify icon="solar:qr-code-bold" />}
              loading={generatingQR}
            >
              Generar QR
            </LoadingButton>
            </Box>
          </>
        )}
      </Card>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
  
  <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Eliminar
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Eliminar Instancia"
        content="Estas seguro que deseas eliminar esta instancia?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Eliminar
          </Button>
        }
      />
    </>
  );
}
