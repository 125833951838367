import { useEffect } from 'react';
import { Mixpanel } from 'src/Mixpanel';

const useClickTracking = () => {
  const handleClick = (event: any) => {
    // Registra el clic como desees (por ejemplo, utilizando Mixpanel, Google Analytics, etc.)
    const {target, clientX, clientY} = event;
    Mixpanel.track("Panel Interaction", {innerText: target?.innerText, x: clientX, y: clientY});
    
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []); // Se ejecutará solo al montar y desmontar el componente
};
export default useClickTracking;