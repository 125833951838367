import Container from '@mui/material/Container';
import React from 'react'
import { useSettingsContext } from 'src/components/settings';
import DenyReasonNewEditForm from '../deny-reason-new-edit-form';


export default function DenyReasonCreateView () {
    const settings = useSettingsContext();

    return (
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <DenyReasonNewEditForm />
      </Container>
    );
}
