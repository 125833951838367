import { useCallback, useEffect, useState } from 'react';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
// @mui
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useSnackbar } from 'src/components/snackbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSkeleton,
  emptyRows,
  getComparator,
  useTable
} from 'src/components/table';
import useStores from 'src/hooks/use-stores';

import Scrollbar from 'src/components/scrollbar';

// Components
import { useSettingsContext } from 'src/components/settings';
import { useBoolean } from 'src/hooks/use-boolean';
import { ProductItemSkeleton } from 'src/sections/product/product-skeleton';

// button

import MethodsTableFiltersResult from '../methods-table-filters-result';
import MethodsTableToolbar from '../methods-table-toolbar';

import MethodsTableRow from '../methods-table-row';
import PaymentMethodsQuickInstanceForm from '../payment-quick-methods-form';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: '# ID', width: 280 },
  { id: 'name', label: 'Metodo de Pago', width: 280 },
  { id: 'description', label: '$', width: 280 },
  { id: 'enterprise', label: 'Agente Bancario', width: 280 },
  { id: 'link', label: 'Link Pago / N° de Cuenta', width: 280 },
  // { id: 'delivery', label: 'Delivery', width: 280 },
  // { id: 'takeaway', label: 'Retiro en tienda', width: 380 },
  { id: 'status', label: 'Estado', width: 280 },
  { id: '', width: 88 },
];

const defaultFilters: {
  store: string;
  status: string;
} = {
  store: '',
  status: 'all',
};

export default function PaymentMethodsView({store}: any) {
  const table = useTable({ defaultOrderBy: 'id' });

  const [tableData, setTableData] = useState<any[]>([]);
  const [currentPaymentMethod, setcurrentPaymentMethod] = useState<any>();
  const [stores] = useStores();
  const [filters, setFilters] = useState(defaultFilters);
  const quickEdit = useBoolean();
  const [loading, setLoading] = useState<boolean>(true);
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const { enqueueSnackbar } = useSnackbar();

  const settings = useSettingsContext();

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !!filters.store;

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;


  const AddNewPaymentMethod = () => {
    quickEdit.onTrue();
    setcurrentPaymentMethod(null);
  }

  const toogleDelivery = useCallback((item: any) => {
   
    axios
    .put(API_ENDPOINTS.paymentMethods.deliveryToogle(item.id))
    .then((val) => {
      setTableData([...tableData]);
      enqueueSnackbar('Metodo de pago actualizado con éxito');
    })
    .catch((err) => {
      enqueueSnackbar('Ocurrió un error al actualizar el método de pago', {variant: 'error'});
      setTableData([...tableData]);
    });   
  }, [enqueueSnackbar, tableData]);

  const toogleTakeaway = useCallback((item: any) => {

    // tableData.filter((data) => data.id === item.id)[0].delviery = !item.delivery;

    axios
    .put(API_ENDPOINTS.paymentMethods.takeawayToogle(item.id))
    .then((val) => {
      setTableData([...tableData]);
      enqueueSnackbar('Metodo de pago actualizado con éxito');
    })
    .catch((err) => {
      enqueueSnackbar('Ocurrió un error al actualizar el método de pago', {variant: 'error'});
      setTableData([...tableData]);
    });   
  }, [tableData,enqueueSnackbar]);






  const toogleStatus = useCallback((item: any) => {

    console.log(item.active)

    const URL = item.active
    ? API_ENDPOINTS.paymentMethods.active(item.id)
    : API_ENDPOINTS.paymentMethods.dissable(item.id);
 
  axios
    .put(URL)
    .then((val) => {
      enqueueSnackbar('Metodo de pago actualizado con éxito');

      setTableData([...tableData]);
    })
    .catch((error) => {
      
    });

  }, [enqueueSnackbar, tableData]);





  const handleFilters = useCallback(
    (name: string, value: any) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleDeleteRow = useCallback((payment_store: any) => {
    
    const {id, basePath} = payment_store; 
    axios
    .delete(API_ENDPOINTS.paymentMethods.delete(id, basePath))
    .then((val) => {
      enqueueSnackbar('Metodo de pago eliminado con éxito');
      const deleteRow = tableData.filter((row) => row.id !== payment_store.id);
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage.length);
    })
    .catch((err) => {
      
    });   
  }, [dataInPage.length, table, tableData, enqueueSnackbar]);

  /* useEffect(() => {
    getPaymentStoreList(filters.store);
  }, [filters.store]);
*/
useEffect(() => {
  if (store?.id){    
    setLoading(true);
    getPaymentStoreList(store.id);
  }
}, [store]);
  const getPaymentStoreList = (storeId: string) => {    
    axios
      .get(API_ENDPOINTS.paymentMethods.list_payment_methods_stores(storeId))
      .then(({ data }) => {
        setLoading(false);
        setTableData(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
    

       
      
       
          <MethodsTableToolbar filters={filters} onFilters={handleFilters} storeOptions={stores} onAddNewPaymentMethod={AddNewPaymentMethod} />
          {canReset && (
            <MethodsTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={dataFiltered.length}
              storeOptions={stores}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />

                <TableBody>
                {loading ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))
                  ) :   dataFiltered
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row) => (
                      <MethodsTableRow
                        key={row.id}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row)}
                        onEditRow={() => {                    
                          setcurrentPaymentMethod(row);
                          quickEdit.onTrue();                          
                        }}
                        OnHandleDelivery={() => toogleDelivery(row)}
                        OnHandleTakeaway={() => toogleTakeaway(row)}
                        OnHandleStatus={() => toogleStatus(row)}
                      />
                    ))
                    }
                

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
       
        <PaymentMethodsQuickInstanceForm
        currentPaymentMethod={currentPaymentMethod}
        store={store}
        open={quickEdit.value}
        onClose={() => {
          quickEdit.onFalse();
          getPaymentStoreList(store.id);
        }}
      />
      </Container>
    </>
  );
}

// ---------------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: any[];
  comparator: (a: any, b: any) => number;
  filters: any;
}) {
  const { store, status } = filters;
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  inputData = stabilizedThis.map((el) => el[0]);

  if (status !== 'all') {
    inputData = inputData.filter((instance) => instance.state?.name === status);
  }

  return inputData;
}
