import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// routes
// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// types
import { IUserItem } from 'src/types/user';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { CustomFile } from 'src/components/upload';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
  RHFMultiSelect,
} from 'src/components/hook-form';
import { IStoreType } from 'src/types/store';
import useStores from 'src/hooks/use-stores';
import useRoles from 'src/hooks/use-roles';
import useUserStates from 'src/hooks/use-user-states';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { InputAdornment, Stack } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { setNotificationQR } from 'src/redux/slices/notification';
import { useBoolean } from 'src/hooks/use-boolean';
// ----------------------------------------------------------------------

type Props = {
  qrCode?: string;
  channel?: string;
  open?: boolean;
  onClose?: VoidFunction;
  showQrModal: boolean;
};

export default function QRInstance({ channel, open, onClose, qrCode, showQrModal = true }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const qrModal = useBoolean();
  const [qr, setQR] = useState('');

  const notification = useSelector((state: any) => state.notification);
  const dispatch = useDispatch();
  useEffect(() => {
    if (notification.notification) {
      const QR = notification.notification;
      setQR(QR);
      if (qrModal.value === false) {
        qrModal.onTrue();
      }
      dispatch(setNotificationQR(''));
    }
    if (notification.instanceState) {
      const { event_name } = notification.instanceState;
      // const {event_name} = data;
      switch (event_name.toLowerCase()) {
        case 'auth_failed':
          enqueueSnackbar('Hubo un error al escanear el QR', { variant: 'warning' });
          break;
        case 'disconnected':
          qrModal.onFalse();
          break;
        case 'ready':
          enqueueSnackbar('Instancia conectada con éxito', { variant: 'success' });
          qrModal.onFalse();
          break;
        case 'connected':
          qrModal.onFalse();
          break;
        default:
          break;
      }
    }
  }, [notification, dispatch, qrModal, enqueueSnackbar]);

  /* notificacion background */

  useEffect(() => {
    const handleServiceWorkerMessage = (event: any) => {
      const aux = event.data.data;
      const TYPE = JSON.parse(aux.data);
      const { event_name } = TYPE.data;

      if (event.data.type === 'notification') {
        // Aquí puedes ejecutar una función para actualizar el estado de tu aplicación
        // basado en los datos de la notificación push.
        const notificationData = event.data.payload;
        setQR(notificationData);
        if (qrModal.value === false) {
          qrModal.onTrue();
        }
      }
    };
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);
    }

    return () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
      }
    };
  }, [qrModal]);

  return (
    <Dialog
      // fullWidth
      maxWidth={false}
      open={showQrModal && qrModal.value}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <DialogTitle>Escanea el siguiente código QR</DialogTitle>

      <DialogContent>
        <QRCodeSVG
          value={qr}
          size={300}
          imageSettings={{
            src: '/assets/logonegro.svg',
            x: undefined,
            y: undefined,
            height: 50,
            width: 100,
            excavate: true,
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={qrModal.onFalse}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
