import { useEffect, useCallback, useState, useMemo } from 'react';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
// @mui
import Container from '@mui/material/Container';

// Components
import { useSettingsContext } from 'src/components/settings';

// hook
import { useParams } from 'src/routes/hook';

import PaymentStoreEditForm from '../payment-quick-methods-edit';
// ----------------------------------------------------------------------


export default function PaymentMethodsEditView() {
  const settings = useSettingsContext();

  const [currentPaymentWireStore, setcurrentPaymentWireStores] = useState(undefined);
  const [currentPaymentLinkStore, setcurrentPaymentLinkStores] = useState(undefined);
  const params = useParams();

  const { id } = params;

  useEffect(() => {
    axios
      .get(API_ENDPOINTS.paymentMethods.details_payment_methods_wire_transfer(id || ''))
      .then(({ data }) => {
        setcurrentPaymentWireStores(data);
      });

    axios
      .get(API_ENDPOINTS.paymentMethods.details_payment_methods_link_button(id || ''))
      .then(({ data }) => {
        setcurrentPaymentLinkStores(data);
      });
  }, [id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <PaymentStoreEditForm
        currentPaymentWireStore={currentPaymentWireStore}
        currentPaymentLinkStore={currentPaymentLinkStore}
      />
    </Container>
  );
}
