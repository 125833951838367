import * as Yup from 'yup';
import { useCallback, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'src/routes/hook';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
// routes
import { paths } from 'src/routes/paths';
// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// types
import { IUserItem, IUserRole } from 'src/types/user';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { CustomFile } from 'src/components/upload';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
  RHFMultiSelect,
} from 'src/components/hook-form';
import { IStoreQuestion } from 'src/types/store';
import useStores from 'src/hooks/use-stores';
import useRoles from 'src/hooks/use-roles';
import useUserStates from 'src/hooks/use-user-states';
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<IStoreQuestion, 'question'> {
  question: string;
}

type Props = {
  open: boolean;
  onClose: VoidFunction;
  currentQuestion: IStoreQuestion;
};

export default function UserQuickEditForm({ currentQuestion, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const NewUserSchema = Yup.object().shape({
    question: Yup.string().required('El pregunta es requerido'),
    answer: Yup.string().required('El respuesta es requerido'),
  });

  const router = useRouter();

  const defaultValues = useMemo(
    () => ({
      id: currentQuestion.id,
      question: currentQuestion.question,
      answer: currentQuestion.answer || '',
    }),
    [currentQuestion]
  );

  const methods = useForm<any>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (currentQuestion) {
      reset(defaultValues);
    }
    reset(defaultValues);
  }, [currentQuestion, defaultValues, reset]);

  const editQuestion = useCallback(
    async (id: string, params: FormValuesProps) => {
      axios
        .put(API_ENDPOINTS.store.storefaq_edit(id), params)
        .then(({ data }) => { 
          
          enqueueSnackbar('Usuario editado con éxito');
          reset();
          window.location.reload();
        })
        .catch((error) => {
          
        });
    },
    [enqueueSnackbar, reset]
  );

  const onSubmit = useCallback(
    async (data: FormValuesProps) => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));
        editQuestion(currentQuestion?.id, data);
        reset();
        onClose();
        enqueueSnackbar('Update success!');
      } catch (error) {
        console.error(error);
      }
    },
    [enqueueSnackbar, onClose, reset, currentQuestion, editQuestion]
  );

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Editar Preguntas y Respuestas</DialogTitle>

        <DialogContent>
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <Stack
                spacing={1.5}
                sx={{
                  width: '100%',
                }}
              >
                <Typography variant="subtitle2">Pregunta</Typography>
                <RHFTextField name="question" />
              </Stack>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <Stack
                sx={{
                  width: '100%',
                }}
                spacing={1.5}
              >
                <Typography variant="subtitle2">Respuesta</Typography>
                <RHFTextField name="answer" />
              </Stack>
            </Stack>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Editar
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
