// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
// types
import { IJobItem } from 'src/types/job';
import { IStoreItem } from 'src/types/store';
// utils
import useLocales from 'src/locales/use-locales';
import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import ChannelCarousel from '../channel/channel-carousel';

// ----------------------------------------------------------------------

type Props = {
  store: IStoreItem;
  onView: VoidFunction;
  onEdit: VoidFunction;
  onDisable: VoidFunction;
  onEnable: VoidFunction;
};

export default function StoreItem({ store, onView, onEdit, onEnable, onDisable }: Props) {
  const popover = usePopover();
  const { t } = useLocales();
  
  const { id, name, address, state, delivery, takeaway, store_categories, faqs, hasChannelConnected, channelCount, channelsList} = store;

  
  return (
    <>
      <Card>
        <IconButton onClick={popover.onOpen} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>

        <Stack sx={{ p: 3, pb: 2 }}> 
         <ListItemText
            sx={{ mb: 1 }}
            primary={
              <Link component={RouterLink} href={paths.dashboard.store.details(id)} color="inherit">
                {name}
              </Link>
            }
            secondary={`${address?.street}, ${address?.houseNumber}. ${address?.city?.name}`}
            primaryTypographyProps={{
              typography: 'subtitle1',
            }}
            secondaryTypographyProps={{
              mt: 1,
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
          />
          <Box rowGap={1.5} display="grid"  sx={{ p: 1 }}>
            <Stack
             flexShrink={0}
              spacing={0.5}
              direction="row"
              alignItems="center"
              sx={{ color: state.name === 'Disabled' ? 'error.main' : 'primary.main', typography: 'caption',minWidth: 0}}
            >
              <Iconify width={16} icon="ic:outline-store" />
              {t(state.name)}
            </Stack>
          </Box>
          <ChannelCarousel channelsList={channelsList} />
      
         
        </Stack>
        
      

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box rowGap={1.5} display="grid" gridTemplateColumns="repeat(2, 1fr)" sx={{ p: 3 }}>
        {delivery &&  <Stack
              key={`store-${id}-delivery`}
              spacing={0.5}
              flexShrink={0}
              direction="row"
              alignItems="center"
              sx={{ color: 'text.disabled', minWidth: 0 }}
            >
             <Iconify width={16} icon="ic:baseline-delivery-dining" sx={{ flexShrink: 0 }} />
              <Typography variant="caption" noWrap>
                Delivery
              </Typography>
            </Stack>}
            {takeaway &&  <Stack
              key={`store-${id}-takeaway`}
              spacing={0.5}
              flexShrink={0}
              direction="row"
              alignItems="center"
              sx={{ color: 'text.disabled', minWidth: 0 }}
            >
             <Iconify width={16} icon="ep:takeaway-box" sx={{ flexShrink: 0 }} />
              <Typography variant="caption" noWrap>
              Takeaway
              </Typography>
            </Stack>}
         
        </Box>
      </Card>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            onView();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          Detalle
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            onEdit();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Editar
        </MenuItem>
        
        <MenuItem
          onClick={() => {
            popover.onClose();
            if (state.name === 'Dissabled'){
              onEnable();
            } else {
              onDisable();
            }
          }}
          sx={{ color: state.name === "Dissabled" ? 'success.main': 'error.main' }}
        >
          <Iconify icon={state.name === "Dissabled" ? 'fluent:presence-available-16-regular': 'vaadin:ban'} />
          {state?.name === "Dissabled" ? 'Habilitar': 'Deshabilitar'}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
