import { Helmet } from 'react-helmet-async';
// sections

import { DenyReasonEditView } from 'src/sections/deny-reason/view';

// ----------------------------------------------------------------------

export default function DenyReasonEditPage() {
  return (
    <>
      <Helmet>
        <title> Editar Motivo de rechazo </title>
      </Helmet>

      <DenyReasonEditView />
    </>
  );
}
