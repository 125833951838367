import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { useEffect, useState } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useParams } from 'src/routes/hook';
import { useSettingsContext } from 'src/components/settings';
import ProductAddonsNewEditForm from '../product-addons-new-edit-form';
//

// ----------------------------------------------------------------------

export default function ProductAddonsEditView() {
  const settings = useSettingsContext();

  const  [currentCategory, setcurrentCategory] = useState(undefined);
  const params = useParams();

  const { id } = params;
  
  useEffect(() => {
    axios.get(API_ENDPOINTS.productAddonCategory.details(id || '')).then(({data}) => {
      setcurrentCategory(data);
    })
  }, [id])
  

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <ProductAddonsNewEditForm currentCategory={currentCategory} />
    </Container>
  );
}
