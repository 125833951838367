import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { useState, useCallback, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, ButtonBase, Stack, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useParams, useRouter } from 'src/routes/hook';
// types
import { IOrderItem, IOrderTableFilters, IOrderTableFilterValue } from 'src/types/order';
// _mock
import { _orders, ORDER_STATUS_OPTIONS } from 'src/_mock';
// utils
import { fTimestamp } from 'src/utils/format-time';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { isDateError } from 'src/components/custom-date-range-picker';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
  TableSkeleton,
} from 'src/components/table';
//
import { useSnackbar } from 'src/components/snackbar';
import useHasRoles from 'src/hooks/use-has-roles';
import StoreSeach from 'src/components/store-search/store-search';
import { RootState, useSelector } from 'src/redux/store';
import ProductComboTableRow from '../product-category-addons-table-row';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Nombre' },
  { id: 'price', label: 'Precio' },
  { id: 'active', label: 'Disponible' },
  { id: '', width: 88 },
];

const defaultFilters = {
  name: '',
  status: 'all',
  startDate: null,
  endDate: null,
};

// ----------------------------------------------------------------------

export default function ProductComboView() {
  const table = useTable({ defaultOrderBy: 'id' });
  const params = useParams();

  const settings = useSettingsContext();

  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const confirm = useBoolean();
  const [tableData, setTableData] = useState<any[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const isAdmin = useHasRoles(['ROLE_ADMIN']);
  const [selectedStore, setSelectedStore] = useState('');
  const [filters, setFilters] = useState(defaultFilters);
  const stateStore: any = useSelector((state: RootState) => state.store);

  const dateError = isDateError(filters.startDate, filters.endDate);
  useEffect(() => {
    setSelectedStore(stateStore.id);
  }, [stateStore.id]);

  useEffect(() => {
    if (selectedStore) {
      axios.get(API_ENDPOINTS.productCombo.storeQuery(selectedStore, `?page=${page}&length=50`)).then(({ data }) => {        
        setLoading(false);
        setTotalData(data.total);
        setTableData(data.rows);
      });
    }
  }, [selectedStore, page]);
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
    dateError,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset =
    !!filters.name || filters.status !== 'all' || (!!filters.startDate && !!filters.endDate);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRow = useCallback(
    (comboId: string) => {
      axios
        .delete(API_ENDPOINTS.productCombo.delete(comboId))
        .then((val) => {
          enqueueSnackbar('Combo eliminado con éxito');
          const deleteRow = tableData.filter((row) => row.id !== comboId);
          setTableData(deleteRow);
          table.onUpdatePageDeleteRow(dataInPage.length);
        })
        .catch((err) => {});
    },
    [dataInPage.length, table, tableData, enqueueSnackbar]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter((row) => table.selected.includes(row.id));

    deleteRows.forEach((row) => {
      handleDeleteRow(row.id);
    });
  }, [table, tableData, handleDeleteRow]);

  const handleAvailability = (item: any) => {
    const URL = item.active
      ? API_ENDPOINTS.productCombo.deactivate(item.id)
      : API_ENDPOINTS.productCombo.activate(item.id);
    tableData.filter((data) => data.id === item.id)[0].active = !item.active;

    setTableData([...tableData]);
    axios
      .put(URL)
      .then(({ data }) => {
        enqueueSnackbar('Combo actualizado con éxito');
      })
      .catch((error) => {});
  };
  const createProductCombo = () => {
    router.push(paths.dashboard.productCombos.new);
  };
  const handleFilterStore = useCallback((storeId: number) => {    
    setLoading(true);
    setSelectedStore(String(storeId));
  }, []);
  const handleEditRow = useCallback(
    (id: string) => {
      router.push(paths.dashboard.productCombos.edit(id));
    },
    [router]
  );

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <Stack spacing={2}>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Combos
          </Typography>
          {isAdmin && (
            <>
              <StoreSeach onChange={handleFilterStore} />
            </>
          )}
        </Stack>
        <Stack
          direction="column"
          alignItems="start"
          justifyContent="space-between"
          sx={{ py: 2, pr: 1, pl: 2.5 }}
        >
          <Button
            sx={{ my: 3 }}
            variant="contained"
            endIcon={<Iconify icon="material-symbols:add" />}
            onClick={createProductCombo}
          >
            Agregar combo
          </Button>
        </Stack>

        <Card>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {loading ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                      {dataFiltered
                        .slice(
                          table.page * table.rowsPerPage,
                          table.page * table.rowsPerPage + table.rowsPerPage
                        )
                        .map((row: any) => (
                          <ProductComboTableRow
                            key={row.id}
                            row={row}
                            selected={table.selected.includes(row.id)}
                            onSelectRow={() => table.onSelectRow(row.id)}
                            onDeleteRow={() => handleDeleteRow(row.id)}
                            onEditAddon={() => {
                              handleEditRow(row.id)
                            }}
                            handleChangeAvailability={() => handleAvailability(row)}
                          />
                        ))}
                    </>
                  )}
                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalData}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={(event, newPage) => {
              const comboList = dataFiltered.slice(
                newPage * table.rowsPerPage,
                newPage * table.rowsPerPage + table.rowsPerPage
              );
              if (comboList.length === 0) {
                setPage(page + 1);
              }
              table.onChangePage(event, newPage);
            }}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Eliminar selección"
        content={
          <>
            Estás seguro que deseas eliminar <strong> {table.selected.length} </strong> Combos?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Eliminar
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
  dateError,
}: {
  inputData: IOrderItem[];
  comparator: (a: any, b: any) => number;
  filters: IOrderTableFilters;
  dateError: boolean;
}) {
  const { status, name, startDate, endDate } = filters;
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (order: any) =>
        order?.order?.orderNumber.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
        order.customer.name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
        order.customer.email.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (status !== 'all') {
    inputData = inputData.filter((order: any) => order?.lastStatus?.title === status);
  }

  if (!dateError) {
    if (startDate && endDate) {
      inputData = inputData.filter(
        (order: any) =>
          fTimestamp(order?.order?.date) >= fTimestamp(startDate) &&
          fTimestamp(order?.order?.date) <= fTimestamp(endDate)
      );
    }
  }

  return inputData;
}
