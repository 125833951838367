import { format } from 'date-fns';
import { useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// utils
import { fCurrency } from 'src/utils/format-number';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { Tooltip } from '@mui/material';
import useHasRoles from 'src/hooks/use-has-roles';
import ConversationAvatar from '../conversation/conversation-avatar';

// ----------------------------------------------------------------------

type Props = {
  // row: IOrderItem;
  row: any;
  selected: boolean;
  onViewRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  imageData: any;
};

export default function OrderTableRow({
  row,
  selected,
  onViewRow,
  onSelectRow,
  onDeleteRow,
  imageData
}: Props) {
  const {state, store, id,  customer, paymentMethod, created_at, type, conversation, calculatedTotal} = row;
  
  const collapse = useBoolean();
  const {t} = useLocales();
  const getImagePath = (imagePath: string) => {
    const url = `${imageData.url_init}${imagePath}${imageData.url_end}`
    return url;
  }
  const isAdmin = useHasRoles(['ROLE_ADMIN']);

  const renderPrimary = (
    <TableRow hover selected={selected}>     
      <TableCell>
        <Tooltip title="Ver orden">
        <Box
          onClick={onViewRow}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          #{id}
        </Box>
        </Tooltip>
      </TableCell>

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
      
        <ConversationAvatar name={customer?.name} imageUrl={customer?.avatar} height={40} width={40} />

        <ListItemText
          sx={{ml:2}}
          primary={customer?.name}
          secondary={customer?.username}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
        />
      </TableCell>
      {isAdmin && (
        <TableCell>
          {store?.name}
        </TableCell>
      )}
      <TableCell>
        <ListItemText
          primary={format(new Date(created_at), 'dd/MM/yyyy')}
          secondary={format(new Date(created_at), 'p')}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>

      {/* <TableCell align="center"> {totalQuantity} </TableCell> */}

 
      <TableCell>
      {t(`paymentMethod.${paymentMethod?.name}`)} {paymentMethod?.enterprise}
      </TableCell>
          <TableCell>
            {t(type?.name)}
          </TableCell>
          <TableCell>
            {fCurrency(calculatedTotal)}
          </TableCell>
          <TableCell>
        <Label
          variant="soft"
          color={
            (state?.class === 'order-state-accepted' && 'success') ||
            (state?.class === 'order-state-created' && 'warning') ||
            (state?.class === 'order-state-rejected' && 'error') ||
            (state?.class === 'order-state-finalized' && 'default') ||
            (state?.class === 'order-state-finalizedfinalized_cron' && 'default') ||
            'primary'
          }
        >
          {t(state?.name)}
        </Label>
      </TableCell>
      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <IconButton
          color={collapse.value ? 'inherit' : 'default'}
          onClick={collapse.onToggle}
          sx={{
            ...(collapse.value && {
              bgcolor: 'action.hover',
            }),
          }}
        >
          <Iconify icon="eva:arrow-ios-downward-fill" />
        </IconButton>

      </TableCell>
    </TableRow>
  );

  const renderSecondary = (
    <TableRow>
      <TableCell sx={{ p: 0, border: 'none' }} colSpan={8}>
        <Collapse
          in={collapse.value}
          timeout="auto"
          unmountOnExit
          sx={{ bgcolor: 'background.neutral' }}
        >
          {/* <Stack component={Paper} sx={{ m: 1.5 }}>
            {!!items && items.map((item: any) => (
              <Stack
                key={item.id}
                direction="row"
                alignItems="center"
                sx={{
                  p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
                  '&:not(:last-of-type)': {
                    borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}`,
                  },
                }}
              >
                <Avatar
                  src={item?.variant?.product?.image ? getImagePath(item?.variant?.product?.image) : item?.product?.image ?  getImagePath(item?.product?.image) : ''}
                  variant="rounded"
                  sx={{ width: 48, height: 48, mr: 2 }}
                />

                <ListItemText
                  primary={item?.variant?.product?.title ? item?.variant?.product?.title : item?.product?.title}
                  secondary={item?.variant?.product?.description ? item?.variant?.product?.description : item?.product?.description}
                  primaryTypographyProps={{
                    typography: 'body2',
                  }}
                  secondaryTypographyProps={{
                    component: 'span',
                    color: 'text.disabled',
                    mt: 0.5,
                  }}
                />

                <Box>x{item.quantity}</Box>

                <Box sx={{ width: 110, textAlign: 'right' }}>{fCurrency(item.price)}</Box>
              </Stack>
            ))}
          </Stack> */}
          <Stack sx={{ m: 1.5 }} pl={5} pr={5} whiteSpace='break-spaces'>
          {conversation?.conversation_summary}
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {renderPrimary}

      {renderSecondary}

    </>
  );
}
