import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
// @mui
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
// redux
import { RootState, useSelector } from 'src/redux/store';
// routes
import { useRouter } from 'src/routes/hook';
// types
import { IProduct, IProductTableFilterValue } from 'src/types/product';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import useStores from 'src/hooks/use-stores';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { enqueueSnackbar } from 'src/components/snackbar';
import { TableHeadCustom, TableNoData, TableSkeleton, useTable } from 'src/components/table';
import StoreSeach from 'src/components/store-search/store-search';
import useHasRoles from 'src/hooks/use-has-roles';
import { useLocales } from 'src/locales';
import { fDateTime } from 'src/utils/format-time';
import StoreSeachMultiple from 'src/components/store-search/store-search-multiple';
import { DatePicker } from '@mui/x-date-pickers';
import OrderTableRow from './order-table-row';
import ConversationChatView from '../conversation/conversation-chat-view';
import OrderItem from './order-item';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'order', label: 'Pedido', width: 80 },
  { id: 'name', label: 'Cliente', width: 180 },
  { id: 'variants', label: 'Fecha' },
  { id: 'paymentMethod', label: 'Método de Pago' },
  { id: 'shippingMethod', label: 'Método de envío' },
  { id: 'total', label: 'Total' },
  { id: 'state', label: 'Estado' },
  { id: '', width: 88 },
];

const TABLE_HEAD_ADMIN = [
  { id: 'order', label: 'Pedido', width: 80 },
  { id: 'name', label: 'Cliente', width: 180 },
  { id: 'store', label: 'Tienda', width: 100 },
  { id: 'variants', label: 'Fecha' },
  { id: 'paymentMethod', label: 'Método de Pago' },
  { id: 'shippingMethod', label: 'Método de envío' },
  { id: 'total', label: 'Total' },
  { id: 'state', label: 'Estado' },
  { id: '', width: 88 },
];

const defaultFilters = {
  name: '',
  publish: [],
  stock: [],
  store: '',
  category: '',
};
const DEFAULT_STATES = [
  { id: 'order-state-rejected', name: 'Rejected', classPanel: 'order-state-panel-rejected' },
  { id: 'order-state-finalized', name: 'Finalized', classPanel: 'order-state-panel-finalized' },
];
// ----------------------------------------------------------------------

export default function OrderListAllView({ showTitle = true }: any) {
  const router = useRouter();
  const table = useTable();
  const settings = useSettingsContext();
  const tableEl: any = useRef();
  const [distanceBottom, setDistanceBottom] = useState(0);
  // hasMore should come from the place where you do the data fetching
  // for example, it could be a prop passed from the parent component
  // or come from some store
  const [hasMore, setHasMore] = useState(true);
  const [tableData, setTableData] = useState<IProduct[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<any>(undefined);
  const pageRef = useRef(page);

  const [orders, setOrders] = useState<IProduct[]>([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [stores] = useStores({ light: true });
  const [showChat, setShowChat] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [currentOrder, setCurrentOrder] = useState<any>();
  const isMobile = width <= 768;
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [orderNumberFilter, setOrderNumberFilter] = useState<string>('');
  const [categories, setCategories] = useState([]);
  const [totalOrdersStates, setTotalOrdersState] = useState<any[]>(DEFAULT_STATES);
  const [status, setStatus] = useState<any[]>(DEFAULT_STATES.map((state) => state.id));

  const confirm = useBoolean();
  const openPDFModal = useBoolean();
  // order table by ID, desc order
  table.orderBy = 'id';
  table.order = 'desc';

  const isAdmin = useHasRoles(['ROLE_ADMIN']);

  const [selectedStores, setSelectedStores] = useState<any[]>([]);

  const stateStore: any = useSelector((state: RootState) => state.store);
  const storeRef = useRef<any>([]);

  const scrollListener = useCallback(() => {
    const bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    // if you want to change distanceBottom every time new data is loaded
    // don't use the if statement
    if (!distanceBottom) {
      // calculate distanceBottom that works for you
      setDistanceBottom(Math.round(bottom * 0.2));
    }
    if (tableEl.current.scrollTop > bottom - distanceBottom && hasMore && !loading) {
      setPage(page + 1);
    }
  }, [hasMore, setPage, page, loading, distanceBottom]);
  useLayoutEffect(() => {
    const tableRef: any = tableEl.current;
    if (tableRef) {
      tableRef.addEventListener('scroll', scrollListener);
    }
    return () => {
      if (tableRef) {
        tableRef.removeEventListener('scroll', scrollListener);
      }
    };
  }, [scrollListener]);

  const getOrderList = useCallback(
    (storeId?: string) => {
      let QUERY = `?page=${pageRef.current}&length=10`;
      if (startDate) {
        QUERY += `&since=${fDateTime(new Date(startDate), 'yyyy-MM-dd')}`;
      }
      if (endDate) {
        QUERY += `&to=${fDateTime(new Date(endDate), 'yyyy-MM-dd')}`;
      }
      if (status.length > 0) {
        QUERY += `${status.map((state) => `&class[]=${state}`).join('')}`;
      }
      const URL =
        /* storeRef.current
        ? API_ENDPOINTS.order.stores(
            storeRef.current,
            `?page=${pageRef.current}&length=25&category=${filters.category}&search=${filters.name}`
          )
        : */
        API_ENDPOINTS.order.stores(QUERY);
      const PARAMS: any = {
        stores: storeRef.current,
      };
      axios
        .post(URL, PARAMS)
        .then(({ data }) => {
          setLoading(false);
          setOrders((prevData) => {
            // Filtrar los nuevos datos para evitar duplicados
            const newData = data.rows.filter(
              (newItem: any) =>
                !prevData.some((existingItem: any) => existingItem.id === newItem.id)
            );
            // Concatenar los datos filtrados con los datos existentes
            return [...prevData, ...newData];
          });
          if (data.rows.length === 0) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        })
        .catch((error) => {});
    },
    [endDate, startDate, status]
  );

  useEffect(() => {
    pageRef.current = page;
    if (page >= 0 && storeRef.current) {
      setLoading(true);
      getOrderList();
    }
  }, [page, getOrderList]);

  useEffect(() => {
    if (stateStore?.id) {
      setSelectedStores([stateStore?.id]);
    }
  }, [stateStore?.id]);

  const getTotalOrdersByState = useCallback(() => {
    const URL = API_ENDPOINTS.store.workFlowStateStores;
    const PARAMS: any = {
      stores: selectedStores,
    };
    axios
      .post(URL, PARAMS)
      .then(({ data }) => {
        setTotalOrdersState([...data, ...DEFAULT_STATES]);
      //  setStatus(DEFAULT_STATES.map((state) => state.id));
      })
      .catch((error) => {
        // Manejar errores si es necesario
      });
  }, [selectedStores]);

  useEffect(() => {
    if (selectedStores.length > 0) {
      storeRef.current = selectedStores;
      setHasMore(true);
      setOrders([]);
      setPage(0);
      getOrderList();
      getTotalOrdersByState();
    } else {
      setCategories([]);
    }
  }, [selectedStores, getOrderList, getTotalOrdersByState]);

  const denseHeight = table.dense ? 60 : 80;

  const canReset = startDate || endDate;
  const { t } = useLocales();
  const handleFilters = useCallback((name: string, value: IProductTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);
  useEffect(() => {
    setPage(0);
    setHasMore(true);
    setOrders([]);
    setLoading(true);
  }, [filters.category, filters.name]);

  const handleViewRow = useCallback((order: any) => {
    setCurrentOrder(order);
    setShowChat(true);
  }, []);

  const handleResetFilters = useCallback(() => {
    // setFilters(defaultFilters);
    console.log('handleResetFilters');
    setTableData([]);
    setPage(0);
  }, []);

  const handleFilterStartDate = (event: any) => {
    setStartDate(event);
  };
  const handleFilterEndDate = (event: any) => {
    setEndDate(event);
  };
  const handleFilterStatus = (event: any) => {
    const value = event.target.value;
    console.log('seleccione', value);
    setStatus(value);
  };
  const resetDatesFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setPage(0);
  };
  useEffect(() => {
    setPage(0);
    setOrders([]);
  }, [startDate, endDate]);
  const handleStateChange = () => {
    setOrders([]);
    setPage(0);
    getOrderList();
  };
  const searchOrderByID = () => {
    if (orderNumberFilter) {
      axios
        .get(API_ENDPOINTS.order.details(orderNumberFilter))
        .then(({ data }) => {
          setOrders([data]);
        })
        .catch((error) => {
          enqueueSnackbar('Orden no encontrada', { variant: 'error' });
        });
    }
  };
  const changeStoreSelection = useCallback((data: any) => {
    const STORES_ID = data.map((item: any) => item.id);
    setSelectedStores(STORES_ID);
  }, []);
  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {showTitle && (
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="space-between"
            sx={{ py: 2, pr: 1, pl: 2.5 }}
          >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Listado de Pedidos
            </Typography>
          </Stack>
        )}

        <Card>
          {isAdmin && (
            <>
              <Stack sx={{ p: 2 }}>
                <StoreSeachMultiple
                  onChange={changeStoreSelection}
                  selectedStores={selectedStores}
                />
              </Stack>
              <Divider sx={{ borderStyle: 'dashed' }} />
            </>
          )}

          <>
            <Stack
              sx={{ p: 2 }}
              direction={{
                xs: 'column',
                md: 'row',
              }}
              spacing={1}
            >
              <Stack direction="column">
                <Stack direction="row" spacing={1}>
                  <DatePicker
                    label="Fecha desde"
                    value={startDate}
                    format="dd/MM/yyyy"
                    maxDate={new Date()}
                    onChange={handleFilterStartDate}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    sx={{
                      maxWidth: { md: 200 },
                    }}
                  />
                  <DatePicker
                    label="Fecha hasta"
                    value={endDate}
                    onChange={handleFilterEndDate}
                    maxDate={new Date()}
                    minDate={startDate || null}
                    format="dd/MM/yyyy"
                    slotProps={{ textField: { fullWidth: true } }}
                    sx={{
                      maxWidth: { md: 200 },
                    }}
                  />

                  <Select
                    value={status}
                    // autoWidth
                    labelId="select-multiple-chip-label"
                    multiple                    
                    label="Estados"
                    onChange={handleFilterStatus}
                    input={<OutlinedInput id="select-multiple-chip" label="Name" />}
                    renderValue={(selected) => {                      
                      const OPTION = selected.map((value) => totalOrdersStates.find((state) => state.id === value));                    
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {OPTION.map((value) => (
                            <Chip key={value.id} size="small" label={t(value.name)} />
                          ))}
                        </Box>
                      );
                    }}
                    sx={{ textTransform: 'capitalize', width: '100%' }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5 + 8,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {totalOrdersStates &&
                      totalOrdersStates.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          <Checkbox
                            disableRipple
                            size="small"
                            checked={status.includes(option.id)}
                          />
                          {t(option.name)}
                        </MenuItem>
                      ))}
                  </Select>
                </Stack>
                {canReset && (
                  <Button
                    color="error"
                    sx={{ flexShrink: 0 }}
                    onClick={resetDatesFilter}
                    startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                  >
                    Limpiar filtros
                  </Button>
                )}
              </Stack>

              <Stack direction="row" spacing={1} sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  value={orderNumberFilter}
                  onChange={(event) => {
                    const { value } = event.target;
                    setOrderNumberFilter(value);
                    if (value === '') {
                      handleStateChange();
                    }
                  }}
                  onKeyDown={(event: any) => {
                    if (event.key === 'Enter') {
                      searchOrderByID();
                    }
                  }}
                  type="number"
                  inputMode="numeric"
                  placeholder="Buscar número de orden..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button color="primary" variant="contained" onClick={searchOrderByID}>
                  <Iconify icon="eva:search-fill" />
                </Button>
              </Stack>
            </Stack>
          </>

          <TableContainer
            sx={{
              position: 'relative',
              maxHeight: { xs: '90vh', sm: '500px' },
              maxWidth: '100%',
              overflowX: 'auto',
            }}
            ref={tableEl}
          >
            <Scrollbar>
              <Table size="small" stickyHeader>
                <TableHeadCustom
                  check={false}
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={isAdmin ? TABLE_HEAD_ADMIN : TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) => {
                    table.onSelectAllRows(
                      checked,
                      orders.map((row) => row.id)
                    );
                  }}
                />

                <TableBody>
                  {false ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                      {orders.map((row) => (
                        <OrderTableRow
                          key={row.id}
                          row={row}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onViewRow={() => handleViewRow(row)}
                          imageData={undefined}
                          onDeleteRow={() => {}}
                        />
                      ))}
                    </>
                  )}
                  <TableNoData notFound={orders.length === 0} />
                </TableBody>
              </Table>
              {loading && (
                <>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ pr: 5, pl: 2.5 }}
                  >
                    <CircularProgress />
                    <Typography variant="body2">Cargando pedidos...</Typography>
                  </Stack>
                </>
              )}
            </Scrollbar>
          </TableContainer>
        </Card>
      </Container>
      <Drawer
        anchor="right"
        open={showChat}
        sx={{
          width: isMobile ? '100%' : '80%',
          '& .MuiDrawer-paper': {
            width: isMobile ? '100%' : '80%',
            maxHeight: '100vh',
            overflow: 'hidden',
          },
        }}
        onClose={() => setShowChat(false)}
      >
        <Stack direction="row">
          <ConversationChatView
            conversationId={currentOrder?.conversation?.id}
            onUpdateChat={() => {}}
            onParentEvent={() => setShowChat(false)}
          />
        </Stack>
      </Drawer>
    </>
  );
}
