import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import Pagination, { paginationClasses } from '@mui/material/Pagination';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import { useSnackbar } from 'src/components/snackbar';
// types
import { IJobItem } from 'src/types/job';
import { IStoreItem } from 'src/types/store';
//
import StoreItem from './store-item';

// ----------------------------------------------------------------------

type Props = {
  stores: IStoreItem[];
};

export default function StoreList({ stores }: Props) {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const handleView = useCallback(
    (id: string) => {
      router.push(paths.dashboard.store.details(id));
    },
    [router]
  );

  const handleEdit = useCallback(
    (id: string) => {
      router.push(paths.dashboard.store.edit(id));
    },
    [router]
  );

  const handleDisable = useCallback((id: string) => {
    axios.put(API_ENDPOINTS.store.dissable(id)).then(async (val) => {
      await enqueueSnackbar('Tienda deshabilitada', {variant: 'success'});
      router.reload();
    }).catch((err)=>{
      enqueueSnackbar('Se ha producido un error al intentar deshabilitar la tienda', {variant: 'error'});
      
    })
  }, [enqueueSnackbar, router]);
  const handleEnable = useCallback((id: string) => {
    axios.put(API_ENDPOINTS.store.enable(id)).then(async (val) => {
      await enqueueSnackbar('Tienda habilitada', {variant: 'success'});
      router.reload();
    }).catch((err)=>{
      enqueueSnackbar('Se ha producido un error al intentar habilitada la tienda', {variant: 'error'});
      
    })
  }, [enqueueSnackbar, router]);

  return (
    <>
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        {stores.map((job) => (
          <StoreItem
            key={job.id}
            store={job}
            onView={() => handleView(job.id)}
            onEdit={() => handleEdit(job.id)}
            onDisable={() => handleDisable(job.id)}
            onEnable={() => handleEnable(job.id)}
          />
        ))}
      </Box>

      {/* stores.length > 8 && (
        <Pagination
          count={8}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
        ) */}
    </>
  );
}
