import { Helmet } from 'react-helmet-async';
// sections

import {StoreFlowMessageView} from 'src/sections/store/view';

// ----------------------------------------------------------------------

export default function StoreFlowMessagesPage() {
  return (
    <>
      <Helmet>
        <title> Configuración de tienda </title>
      </Helmet>

      <StoreFlowMessageView />
    </>
  );
}
