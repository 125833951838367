import { useCallback, useEffect, useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// types
import { IProductTableFilters, IProductTableFilterValue } from 'src/types/product';
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { IStoreType } from 'src/types/store';
import { RHFSelect } from 'src/components/hook-form';
import { Box, Button } from '@mui/material';
import useHasRoles from 'src/hooks/use-has-roles';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { RootState, useSelector } from 'src/redux/store';
import ChannelQuickInstanceForm from '../channel/channel-quick-instance-form';

// ----------------------------------------------------------------------

type Props = {
  filters: any;
  onFilters: (name: string, value: IProductTableFilterValue) => void;
  storeOptions: any[];
};

export default function ChannelTableToolbar({
  filters,
  onFilters,
  storeOptions
}: Props) {
  const popover = usePopover();
  const [channels, setChannels] = useState<any[]>([]);
  const [selectedChannel, setselectedChannel] = useState<any>({ name: '', base_path: '', username_type: '' });
  const isAdmin = useHasRoles(['ROLE_ADMIN']);
  const stateStore: any = useSelector((state: RootState) => state.store);
    const [billingPlans, setBillingPlans] = useState<any[]>([]);


  useEffect(() => {
    if (stateStore?.id && filters.store !== stateStore.id) {

      onFilters('store', stateStore.id);
    }
   else if (!filters.store && storeOptions.length > 0 && !isAdmin) {
      onFilters('store',storeOptions[0].id);
    }
  }, [filters.store, onFilters, storeOptions, stateStore, isAdmin]);

  useEffect(() => {
    getChannelList();
  }, []);
  useEffect(() => {
    if (channels && channels.length === 1) {
      setselectedChannel(channels[0]);
    }
  }, [channels]);
  const getChannelList = () => {
    axios
      .get(API_ENDPOINTS.channel.availables)
      .then(({ data }) => {
        setChannels(data);
      })
      .catch((error) => {

      });
  };
  const handleFilterStore = useCallback(
    (event: any) => {
      onFilters(
        'store',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );
  const changeChannel = (event: any) => {

    const NAME = event.target.value;
    const CHANNEL = channels.find((item) => item.name === NAME);
    setselectedChannel(CHANNEL)

    onFilters('channelName', CHANNEL?.name);

  }

  const handleFilterBillingPlan = useCallback(
    (event: any) => {
      console.log("llega", event.target.value )
      onFilters(
        'billingPlan',
         event.target.value
      );
    },
    [onFilters]
  );

  useEffect(() => {
    if (isAdmin){
      axios
      .get(API_ENDPOINTS.billingPlan.list)
      .then(({ data }) => {
        setBillingPlans(data.rows);
      })
      .catch((error) => {
        // Handle error
      });
    }
  }, [isAdmin])

  const quickEdit = useBoolean();


  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
      {isAdmin && (
        <FormControl
        sx={{
          flexShrink: 1,
          width: { xs: 1, md: 200 },
        }}
      >
        <InputLabel>Comercios</InputLabel>
        <Select
          value={filters.store}
          onChange={handleFilterStore}
          input={<OutlinedInput label="Comercios" />}
          renderValue={(selected) => storeOptions.find((store) => store.id === selected)?.name}
          sx={{ textTransform: 'capitalize' }}
          disabled={filters.billingPlan !== ''} 
        >
          {storeOptions && storeOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox
                disableRipple
                size="small"
                checked={(filters.store === option.id)}
              />
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      )}
        <Stack direction="row" spacing={2}>
        <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>Plan de pago</InputLabel>
                <Select            
                  value={filters.billingPlan || ''}
                  onChange={handleFilterBillingPlan}
                  input={<OutlinedInput label="Plan de pago" />}
                  renderValue={(selected) =>                
                    billingPlans.find((item: any) => item?.id === selected)?.name              
                  }
                  sx={{ textTransform: 'capitalize' }}
                >
                  <MenuItem key='default-billing-plan' value=''>
                        Sin Selección
                      </MenuItem>
                  {billingPlans.length>0 &&
                    billingPlans.map((option: any) => (
                      <MenuItem key={option.id} value={option.id}>
                        <Checkbox
                          disableRipple
                          size="small"
                          checked={filters.billingPlan === option.id}
                        />
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
        </FormControl>
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 200 },
            }}
          >
           
            <InputLabel>Canales</InputLabel>
            <Select
              value={selectedChannel ? selectedChannel.name : ''} // Cambiar selectedChannel?.name por selectedChannel ? selectedChannel.name : ''
              onChange={changeChannel}
              input={<OutlinedInput label="Canales" />}
              renderValue={(selected) => channels.find((item) => item.name === selected).name}
              sx={{ textTransform: 'capitalize' }}
            >
              <MenuItem value="">
                <em>Seleccionar canal</em>
              </MenuItem>
              {channels && channels.map((option) => (               
                <MenuItem key={option.name} value={option.name}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={(selectedChannel && selectedChannel.name === option.name)}
                  />
                  {option.name}
                </MenuItem>                
              ))}
            </Select>

          </FormControl>
          <Button variant="contained" onClick={quickEdit.onTrue} disabled={!selectedChannel?.can_create}>
            Crear nueva instancia
          </Button>
        </Stack>
      </Stack>
      <ChannelQuickInstanceForm channel={selectedChannel?.name} open={quickEdit.value} usernameType={selectedChannel?.username_type} onClose={quickEdit.onFalse} basePath={selectedChannel?.base_path} />

    </>
  );
}
