import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { useState, useCallback, useEffect } from 'react';
import { IProductTableFilterValue } from 'src/types/product';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, ButtonBase, Stack, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// types
import { IOrderItem, IOrderTableFilters, IOrderTableFilterValue } from 'src/types/order';
// _mock
import { _orders, ORDER_STATUS_OPTIONS } from 'src/_mock';
// utils
import { fTimestamp } from 'src/utils/format-time';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import useHasRoles from 'src/hooks/use-has-roles';
import useStores from 'src/hooks/use-stores';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { isDateError } from 'src/components/custom-date-range-picker';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
  TableSkeleton,
} from 'src/components/table';
//
import { isEqual } from 'lodash';
import { useSnackbar } from 'src/components/snackbar';
import { useLocales } from 'src/locales';
import StoreSeach from 'src/components/store-search/store-search';
import { RootState, useSelector } from 'src/redux/store';
import CategoryTableRow from '../category-table-row';
import CategoryTableToolbar from '../category-table-toolbar';
import CategoryTableFiltersResult from '../category-table-filters-result';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre', width: 100 },
  { id: 'store', label: 'Tienda', width: 100 },
  { id: 'quantity',label:'Cantidad de productos', width: 100 },
  { id: 'variants-quantity',label:'Cantidad de variantes', width: 100 },
  { id: 'stock', label: 'Disponible', width: 100},
  { id: 'variants', width: 100 },
  { id: '', width: 88 },
];

const defaultFilters = {
  name: '',
  status: 'all',
  startDate: null,
  endDate: null,
  store: '',
};

// ----------------------------------------------------------------------

export default function CountryListView() {
  const table = useTable({ defaultOrderBy: 'id' });

  const settings = useSettingsContext();

  const { enqueueSnackbar } = useSnackbar();

  const router = useRouter();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState<any[]>([]);

  const [filters, setFilters] = useState(defaultFilters);

  const [stores] = useStores()

  const {t} = useLocales();
  const dateError = isDateError(filters.startDate, filters.endDate);
  const stateStore: any = useSelector((state: RootState) => state.store);
  const [selectedStore, setSelectedStore] = useState('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
//    getCategoriesList(String(stateStore?.id))
// setLoading(true);

setTableData([])
    setSelectedStore(String(stateStore?.id));
  }, [stateStore.id])
 
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
    dateError,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IProductTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );
  const createCategory = () => {
    router.push(paths.dashboard.category.new);
  };
  const handleDeleteRow = useCallback(
    (id: string) => {
      axios
        .delete(API_ENDPOINTS.category.delete(id))
        .then((val) => {
          enqueueSnackbar('Categoría eliminada con éxito');
          const deleteRow = tableData.filter((row) => row.id !== id);
          setTableData(deleteRow);
          table.onUpdatePageDeleteRow(dataInPage.length);
        })
        .catch(({error, message}) => {
          enqueueSnackbar(t(error), {variant: 'error'});

        });
    },
    [dataInPage.length, table, tableData, enqueueSnackbar, t]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter((row) => table.selected.includes(row.id));
    
    deleteRows.forEach((row) => {
      handleDeleteRow(row.id);
    });
    /*
    setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    }); */
  }, [table, tableData, handleDeleteRow]);

  const handleViewRow = useCallback(
    (id: string) => {
      router.push(paths.dashboard.order.details(id));
    },
    [router]
  );
  const isAdmin = useHasRoles(['ROLE_ADMIN']);

  const getCategoriesList = useCallback((storeId?: string) => {
    if (storeId !== 'undefined'){
      const URL = storeId ? API_ENDPOINTS.category.store(storeId) : API_ENDPOINTS.category.list;
      axios
        .get(URL)
        .then(({ data }) => {
          setLoading(false);
          setTableData(data.rows);
        })
        .catch((error) => {
          
        });
    }
  },[]);

  useEffect(() => {    
    if(selectedStore){
      setLoading(true);
      getCategoriesList(selectedStore);
    }
  }, [selectedStore, getCategoriesList])

  const handleFilterStore = useCallback((storeId: number) => {    
    table.onResetPage();
    // setPage(0);    
    setSelectedStore(String(storeId));
  }, [table]);
 /* useEffect(() => {
    if (filters?.store) {
      getCategoriesList(filters?.store);
    }else if (!isAdmin){
      setTableData([]);
    }
  }, [filters?.store, setTableData, isAdmin]); */
  const handleAvailability = (item: any) => {
    const URL = item.active ? API_ENDPOINTS.category.deactivate(item.id) : API_ENDPOINTS.category.activate(item.id);
    tableData.filter((data) => data.id === item.id)[0].active = !item.active;
    
    setTableData([...tableData]);
    axios
    .put(URL)
    .then(({ data }) => {
      enqueueSnackbar(data);
      })
      .catch((error) => {
        
      });
  }
  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <Stack
          direction="column"
          alignItems="start"
          justifyContent="space-between"
          sx={{ py: 2, pr: 1, pl: 2.5 }}
        >
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Listado de Categorías
          </Typography>

          <Button
            sx={{ my: 3 }}
            variant="contained"
            endIcon={<Iconify icon="material-symbols:add" />}
            onClick={createCategory}
          >
            Agregar nueva
          </Button>
        </Stack>
        <Card>
       {isAdmin && (
        <>
        <Stack sx={{p:2}}>
         <StoreSeach onChange={handleFilterStore}/>
        </Stack>
         {/* <CategoryTableToolbar filters={filters} onFilters={handleFilters} storeOptions={stores} />
         {canReset && (
          <CategoryTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={dataFiltered.length}
            storeOptions={stores}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}  */}
        </>
       )}

          
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                {loading ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))
                  ): (
                    <>
                  {tableData
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row) => (
                      <CategoryTableRow
                        key={row.id}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        onViewRow={() => handleViewRow(row.id)}
                        handleChangeAvailability= {() => handleAvailability(row)}
                      />
                    ))}
                    </>
                  )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Eliminar selección"
        content={
          <>
            Estás seguro que deseas eliminar <strong> {table.selected.length} </strong> Categorías?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Eliminar
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
  dateError,
}: {
  inputData: IOrderItem[];
  comparator: (a: any, b: any) => number;
  filters: IOrderTableFilters;
  dateError: boolean;
}) {
  const { status, name, startDate, endDate } = filters;
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (order: any) =>
        order?.order?.orderNumber.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
        order.customer.name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
        order.customer.email.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (status !== 'all') {
    inputData = inputData.filter((order: any) => order?.lastStatus?.title === status);
  }

  if (!dateError) {
    if (startDate && endDate) {
      inputData = inputData.filter(
        (order: any) =>
          fTimestamp(order?.order?.date) >= fTimestamp(startDate) &&
          fTimestamp(order?.order?.date) <= fTimestamp(endDate)
      );
    }
  }

  return inputData;
}
