import Container from '@mui/material/Container';
import React from 'react'
import { useSettingsContext } from 'src/components/settings';
import PromptNewEditForm from '../product-addons-new-edit-form';

export default function ProductAddonsCreateView () {
    const settings = useSettingsContext();

    return (
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <PromptNewEditForm />
      </Container>
    );
}
