import React, { useState, useEffect } from 'react';
import { BounceLoader } from 'react-spinners';
import { connect } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import './spinner.css';

// Define el tipo para el estado de Redux si está disponible
interface RootState {
  spinner: {
    loading: boolean;
  };
}

const Spinner = ({ loading }: { loading: boolean }) => {
  const [timer, setTimer] = useState(60);
  const [messageIndex, setMessageIndex] = useState(0);
  const messages = [
    'Estamos dejando todo listo para ti...',
    'Un momento, estamos preparando tu experiencia...',
    'Casi listo, gracias por tu paciencia...'
  ];

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (loading && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (!loading || timer === 0) {
      clearInterval(interval as NodeJS.Timeout);
    }

    if (timer <= 0) {
      window.location.reload();
    }

    return () => clearInterval(interval as NodeJS.Timeout);
  }, [loading, timer]);

  useEffect(() => {
    const messageInterval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 3000);

    return () => clearInterval(messageInterval);
  }, [messages.length]);

  if (!loading) return null;

  return (
    <Box className="backdrop" display="flex" alignItems="center" justifyContent="center">
      <Card sx={{ minWidth: 275, textAlign: 'center', p: 4 }}>
        <CardContent>
          {/*
          <BounceLoader color='#123abc' loading={loading} size={125} />
          */}
          <Typography variant="h5" sx={{ mt: 2, opacity: 0, animation: 'fadeIn 1s forwards' }}>
            {messages[messageIndex]}
          </Typography>
          <CircularProgress variant="determinate" value={(timer / 30) * 100} size={75} sx={{ mt: 2 }} />
          <Typography variant="h6" sx={{ mt: 2 }}>
            {timer}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.spinner.loading,
});

export default connect(mapStateToProps)(Spinner);