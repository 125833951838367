// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import React, { StrictMode, useCallback, useEffect, useState } from 'react';
import 'react-swipeable-list/dist/styles.css';
// components
import { RouterLink } from 'src/routes/components';
import Label from 'src/components/label';
import { format } from 'date-fns';
import useLocales from 'src/locales/use-locales';
import Badge from '@mui/material/Badge';
import { Button, Chip } from '@mui/material';
import Iconify from 'src/components/iconify';
import { fDateTime } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';

// ----------------------------------------------------------------------

type Props = {
  order: any;
  onView: any;
  showCommand?: any;
  updateState?: Function;
};

export default function OrderItemShort({ order, onView, showCommand, updateState }: Props) {
  const getColor = (
    nextState: any
  ): 'success' | 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' =>
    nextState?.class === 'order-state-accepted'
      ? 'success'
      : nextState?.class === 'order-state-rejected'
      ? 'error'
      : 'primary';

  //  const { id, name, address, state, delivery, takeaway, store_categories} = order;
  const [currentOrder, setCurrentOrder] = useState(order);
  useEffect(() => {
    setCurrentOrder(order);
  }, [order]);

  const {
    state,
    id,
    customer,
    paymentMethod,
    created_at,
    type,
    conversation,
    store,
    orderProducts,
    nextStates,
    pickupTime,
    total,
  } = currentOrder;
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(
    conversation?.unreadMessagesAfterAccepted
  );

  const { t } = useLocales();
  const apiDateObj = new Date(pickupTime);
  const adjustedDateObj = new Date(
    apiDateObj.getUTCFullYear(),
    apiDateObj.getUTCMonth(),
    apiDateObj.getUTCDate(),
    apiDateObj.getUTCHours(),
    apiDateObj.getUTCMinutes(),
    apiDateObj.getUTCSeconds()
  );
  const formattedPickupTime = pickupTime ? fDateTime(adjustedDateObj, 'HH:mm') : 'N/A';
  useEffect(() => {
    setUnreadMessagesCount(currentOrder?.conversation?.unreadMessagesAfterAccepted);
  }, [currentOrder]);
  const truncatedTextStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
  };

  return (
    <>
      <Card
        sx={{
          mb: 1,
          flexGrow: 1,
          border: 1,
          // boxShadow: 'none',
          borderColor: 'lightgrey',
        }}
        onClick={(ev: any) => {
          ev.preventDefault();
          onView(order);
        }}
      >
        <Stack sx={{ p: 3, pb: 2 }}>
          <ListItemText
            sx={{ mb: 0.5 }}
            primary={
              <Link component={RouterLink} color="inherit">
                <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" alignItems="center">
                  #{id}
                  <Label
                    variant="soft"
                    color={
                      (state?.class === 'order-state-accepted' && 'success') ||
                      (state?.class === 'order-state-ready-to-pick-up' && 'warning') ||
                      (state?.class === 'order-state-rejected' && 'error') ||
                      (state?.class === 'order-state-finalized' && 'default') ||
                      (state?.class === 'order-state-done' && 'default') ||
                      (state?.class === 'order-state-sent' && 'default') ||
                      'primary'
                    }
                  >
                    {t(state?.name)}
                  </Label>
                  <Typography variant="caption" color="grey">
                    {format(new Date(created_at), 'dd MMM yyyy, p')}
                  </Typography>
                  {/* 
              <Badge color="error" overlap="circular" badgeContent={unreadMessagesCount > 0 ? unreadMessagesCount : null} />
             */}
                  {unreadMessagesCount > 0 && (
                    <Chip
                      icon={<Iconify icon="majesticons:chat-line" width={15} />}
                      sx={{ borderRadius: 20 }}
                      label={unreadMessagesCount}
                      size="small"
                      color="error"
                    />
                  )}
                </Stack>
              </Link>
            }
            primaryTypographyProps={{
              typography: 'subtitle1',
            }}
          />

          <Divider sx={{ borderStyle: 'groove' }} />
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            sx={{ typography: 'subtitle2' }}
            justifyContent="space-between"
          >
            <Typography variant="caption" sx={{ mb: 0.5, color: 'text.secondary' }}>
              {t(`paymentMethod.${paymentMethod?.name}`)} {paymentMethod?.enterprise}
            </Typography>
            {fCurrency(total)}
          </Stack>
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            sx={{ typography: 'subtitle2' }}
            justifyContent="space-between"
          >
            <Typography variant="caption" sx={{ mb: 0.5, color: 'text.secondary' }}>
              {type?.name}{' '}
            </Typography>
            {!!type?.address && `${type?.address?.street} ${type?.address?.houseNumber}`}{' '}
            {formattedPickupTime}
          </Stack>
          {
            !!type?.delivery_zone && 
           
            <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            sx={{ typography: 'subtitle2' }}
            justifyContent="space-between"
          >
            <Typography variant="caption" sx={{ mb: 0.5, color: 'text.secondary' }}>
              Zona de entrega
            </Typography>
            {!!type?.delivery_zone && `${type?.delivery_zone?.name} ${fCurrency(type.delivery_zone.price)}`}

          </Stack>
        
          } 
          <Divider sx={{ borderStyle: 'groove' }} />
          
          <Stack direction="column" sx={{ mt: 2 }}>
          {currentOrder?.new_method_used && (
            <Stack direction="column" sx={{ mt: 2, whiteSpace: 'break-spaces' }}>
              <Typography variant="body2" color="black">
                {currentOrder?.conversation?.conversation_summary}
              </Typography>
            </Stack>
          )}
            {orderProducts.slice(0, 2).map((orderProduct: any) => (
              <Stack key={`product-${orderProduct.id}`}>
                <Typography variant="subtitle1" color="black">
                  - <strong>{orderProduct?.quantity} x</strong> {orderProduct?.product?.name}
                  {orderProduct?.comment ? `- ${orderProduct?.comment}` : ''}
                </Typography>
                {orderProduct.orderProductAddons.map((addon: any) => (
                  <Typography
                    variant="subtitle2"
                    color="black"
                    key={`product-addon-${addon.id}`}
                    sx={{ px: 3 }}
                  >
                    - <strong>{addon?.quantity} x</strong> {addon?.productAddon?.name}
                  </Typography>
                ))}
              </Stack>
            ))}
            {orderProducts.length > 2 && (
              <Typography variant="body2" color="primary">
                Ver más...
              </Typography>
            )}
            {showCommand && (
              <Button
                fullWidth
                sx={{ mt: 2 }}
                key={`detail-order-${order.id}`}
                variant="contained"
                onClick={(ev: any) => {
                  ev.stopPropagation();
                  showCommand();
                }}
              >
                Ver comanda
              </Button>
            )}
            {updateState && (
              <Stack
                spacing={1.5}
                display="grid"
                gridTemplateColumns={nextStates.length === 1 ? '1fr' : 'repeat(2, 1fr)'}
                sx={{ mt: 2 }}
              >
                {nextStates.map((nextState: any) => (
                  <Button
                    fullWidth
                    key={`order${id}-${nextState.class}`}
                    color={getColor(nextState)}
                    variant="contained"
                    onClick={(ev: any) => {
                      ev.stopPropagation();
                      updateState(nextState?.class);
                    }}
                  >
                    {t(`state.${nextState?.name}`)}
                  </Button>
                ))}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Card>
    </>
  );
}
