import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/system/Stack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Carousel, { CarouselArrowIndex, useCarousel } from 'src/components/carousel';
import FormProvider, { RHFTextField, RHFUpload } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { LoadingScreen } from 'src/components/loading-screen';
import { useSnackbar } from 'src/components/snackbar';
import { Upload } from 'src/components/upload';
import { useResponsive } from 'src/hooks/use-responsive';
import useStoreCategories from 'src/hooks/use-stores copy';
import { useLocales } from 'src/locales';
import { RouterLink } from 'src/routes/components';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import { IStoreItem } from 'src/types/store';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
// utils
import { fDate } from 'src/utils/format-time';
import * as Yup from 'yup';
import Image from 'src/components/image';
import { Mixpanel } from 'src/Mixpanel';

// Table Simple

type Props = {
  currentStore?: IStoreItem;
};
type FormValuesProps = any;

export default function StoreSettingsForm({ currentStore }: any) {
  const router = useRouter();
  const storeDetail = paths.dashboard.store.details(currentStore?.id);
  const mdUp = useResponsive('up', 'md');
  const { enqueueSnackbar } = useSnackbar();
  const steps = ['Carta digital', 'Mensajes de cambio de estados'];
  const [activeStep, setActiveStep] = useState(0);
  const [pdfFile, setpdfFile] = useState<any>('');
  const [botPDF, setbotPDF] = useState<any>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isGeneratinBotPDF, setIsGeneratinBotPDF] = useState<boolean>(false);
  const [currentMenu, setCurrentMenu] = useState<string[]>([]);
  const [currentConfiguration, setCurrentConfiguration] = useState<any>();
  const { t } = useLocales();
  const [sendPDF, setSendPDF] = useState<boolean>(currentStore?.send_pdf);
  const defaultValues = useMemo(
    () => ({
      images: [],
      state_created: currentConfiguration?.state_created || '',
      state_accepted: currentConfiguration?.state_accepted || '',
      state_finalized: currentConfiguration?.state_finalized || '',
      state_ready_to_pickup: currentConfiguration?.state_ready_to_pickup || '',
      state_done: currentConfiguration?.state_done || '',
      state_rejected: currentConfiguration?.state_rejected || '',
      state_sent: currentConfiguration?.state_sent || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentConfiguration, currentStore]
  );
  const methods = useForm<FormValuesProps>({
    defaultValues,
    mode: 'onBlur',
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    watch,
    trigger,
  } = methods;
  const values = watch();

  useEffect(() => {
    const defaults = {
      images: [],
      state_created: currentConfiguration?.state_created || '',
      state_accepted: currentConfiguration?.state_accepted || '',
      state_finalized: currentConfiguration?.state_finalized || '',
      state_ready_to_pickup: currentConfiguration?.state_ready_to_pickup || '',
      state_done: currentConfiguration?.state_done || '',
      state_rejected: currentConfiguration?.state_rejected || '',
      state_sent: currentConfiguration?.state_sent || '',
    };
    reset(defaults);
  }, [currentConfiguration, reset]);

  const editStore = useCallback(
    async (id: string, params: any) => {
      axios
        .put(API_ENDPOINTS.storeConfiguration.edit(currentStore.id), params)
        .then((val) => {
          enqueueSnackbar('Configuración actualizada');
          // getConfiguration();
        })
        .catch((err) => {
          enqueueSnackbar('Hubo un error', { variant: 'error' });
        });
    },
    [currentStore, enqueueSnackbar]
  );
  const createStore = useCallback(
    async (params: FormValuesProps) => {
      const {
        name,
        schedule_text,
        street,
        house_number,
        waiting_time,
        delivery_fee,
        delivery_zone,
        city_id,
        latitude,
        longitude,
        prompt_id,
        currency_id,
        delivery,
        takeaway,
        store_categories,
        language,
        order_state_workflow,
        notifications,
        welcome_message,
        welcome_message_check,
        closed_message,
        send_pdf,
        faqs,
      } = params;

      const DATA = {
        name,
        schedule_text,
        waiting_time,
        delivery_fee,
        delivery_zone: delivery_zone || 'El comercio no tiene delivery',
        delivery,
        takeaway,
        city_id: parseInt(city_id, 10),
        street,
        latitude,
        longitude,
        house_number,
        currency_id: parseInt(currency_id, 10),
        prompt_id: parseInt(prompt_id, 10),
        store_categories,
        language: parseInt(language, 10),
        order_state_workflow: parseInt(order_state_workflow, 10),
        notifications,
        send_pdf,
        welcome_message: welcome_message_check ? welcome_message : '',
        closed_message,
      };
      axios
        .post(API_ENDPOINTS.store.create, DATA)
        .then(({ data }) => {
          enqueueSnackbar('Tienda creada con éxito');
          reset();
          router.push(paths.dashboard.store.root);
        })
        .catch((error) => {});
    },
    [enqueueSnackbar, reset, router]
  );
  const carouselLarge = useCarousel({
    rtl: false,
    draggable: true,
    adaptiveHeight: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,

    centerPadding: '0px',
  });

  useEffect(() => {
    carouselLarge.onSetNav();
  }, [carouselLarge]);
  const onSubmit = useCallback(
    async (params: FormValuesProps) => {
      const filteredKeys = Object.keys(params).filter((key) =>
        Object.prototype.hasOwnProperty.call(currentConfiguration, key)
      );

      // Crear un nuevo objeto con las claves filtradas
      const auxObject: any = {};
      filteredKeys.forEach((key) => {
        auxObject[key] = params[key];
      });
      editStore(currentStore.id, auxObject);
    },
    [currentStore, editStore, currentConfiguration]
  );
  const getConfiguration = useCallback(() => {
    axios
      .get(API_ENDPOINTS.storeConfiguration.list(currentStore?.id))
      .then(({ data }) => {
        setCurrentConfiguration(data);
        setCurrentMenu(data?.menu || []);
      })
      .catch((error) => {});
  }, [currentStore]);
  const getBotMenu = useCallback(() => {
    axios
      .get(API_ENDPOINTS.store.pdfBase64(currentStore?.id, '1', '1'))
      .then(({ data }) => {
        setbotPDF(`data:application/pdf;base64,${data}`);
      })
      .catch((error) => {});
  }, [currentStore]);
  useEffect(() => {
    if (currentStore?.id) {
      getConfiguration();
      getBotMenu();
    }
  }, [currentStore, getConfiguration, getBotMenu]);

  const resetMenu = () => {
    setIsGeneratinBotPDF(true);
    const DATA = { menu: null };
    axios
      .put(API_ENDPOINTS.storeConfiguration.edit(currentStore.id), DATA)
      .then((val) => {
        setIsGeneratinBotPDF(false);
        enqueueSnackbar('¡Nuestro asistente generará un menú digital por ti!');
        getConfiguration();
        getBotMenu();
      })
      .catch((err) => {
        setIsGeneratinBotPDF(false);
        enqueueSnackbar('Hubo un error al intentar cargar el menú', { variant: 'error' });
      });
  };
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = values.images || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setValue('images', [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.images]
  );

  const handleRemoveFile = useCallback(
    (inputFile: File | string) => {
      const filtered =
        values.images && values.images?.filter((file: string | File) => file !== inputFile);
      setValue('images', filtered);
    },
    [setValue, values.images]
  );

  const handleRemoveAllFiles = useCallback(() => {
    setValue('images', []);
  }, [setValue]);
  const uploadFiles = useCallback(() => {
    console.log(values.images);
    const { images } = values;
    const promises: Promise<string>[] = [];

    const processFile = (file: File): Promise<string> =>
      new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent: any) {
          const base64 = fileLoadedEvent.target.result;
          resolve(base64);
        };
        // Convert data to base64
        fileReader.readAsDataURL(file);
      });

    images.forEach((file: File) => {
      promises.push(processFile(file));
    });
    setLoading(true);
    Promise.all(promises)
      .then((BASE64_FILES) => {
        console.log(BASE64_FILES);
        const DATA = { menu: BASE64_FILES };
        axios
          .put(API_ENDPOINTS.storeConfiguration.edit(currentStore.id), DATA)
          .then((val) => {
            setLoading(false);
            setbotPDF('');
            enqueueSnackbar('Menú digital actualizado');
            getConfiguration();
          })
          .catch((err) => {
            setLoading(false);
            enqueueSnackbar('Hubo un error al intentar cargar el menú', { variant: 'error' });
          });
      })
      .catch((error) => {
        console.error('Error al procesar archivos:', error);
      });
  }, [values, enqueueSnackbar, getConfiguration, currentStore?.id]);
  const FileViewer = (fileBase64: string) => {
    const getFileType = (base64: string) => {
      const parts = base64.split('/');

      // Tomar la primera parte que contiene "data:"
      const dataTypePart = parts[0];

      console.log(dataTypePart);
      return dataTypePart;
    };

    const fileType = getFileType(fileBase64);

    if (fileType === 'data:image') {
      return <Image src={fileBase64} />;
    }
    if (fileType === 'data:application') {
      return (
        <iframe
          title="PDF Viewer"
          width="100%"
          height="600"
          src={fileBase64}
          frameBorder="0"
          allowFullScreen
        />
      );
    }
    return <p>Archivo no compatible</p>;
  };

  const renderCurrentMenu = (
    <>
      <Card>
        <CardHeader title="Vista previa" />
        <Stack spacing={1} sx={{ p: 3 }}>
          <Typography variant="subtitle2">
            Aquí podrás visualizar el menú que recibirán tus clientes
          </Typography>
          <Stack
            sx={{
              width: '100%',
            }}
            spacing={1.5}
          >
            {currentMenu.length > 0 && (
              <>
                <Box
                  sx={{
                    mb: 3,
                    borderRadius: 2,
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  <Carousel
                    {...carouselLarge.carouselSettings}
                    asNavFor={carouselLarge.nav}
                    ref={carouselLarge.carouselRef}
                  >
                    {currentMenu?.map((menu, index) => (
                      <React.Fragment key={`menu-${index}`}>{FileViewer(menu)}</React.Fragment>
                    ))}
                  </Carousel>
                  <CarouselArrowIndex
                    index={carouselLarge.currentIndex}
                    total={currentMenu?.length}
                    onNext={carouselLarge.onNext}
                    onPrev={carouselLarge.onPrev}
                  />
                </Box>
                <Typography variant="caption">
                  Última actualización:{' '}
                  {fDate(currentConfiguration.menu_updated_at, 'HH:mm dd/MM/yyyy')}
                </Typography>
              </>
            )}
            {currentMenu.length === 0 && (
              <>
                <Box
                  sx={{
                    mb: 3,
                    borderRadius: 2,
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  <iframe
                    title="PDF Viewer"
                    width="100%"
                    height="600"
                    src={`${botPDF}#zoom=50`}
                    frameBorder="0"
                    allowFullScreen
                    style={{ aspectRatio: '1/1' }}
                  />
                </Box>
                <Typography variant="caption">* Menú generado por el asistente virutal</Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Card>
    </>
  );
  const renderDetails = (
    <>
      <Card>
        <CardHeader title="Cargar Menú digital" />
        <Stack spacing={2.5} sx={{ p: 3 }}>
          <Typography variant="subtitle2">
            En esta sección crearás el menú digital que será enviado a tus clientes. Puedes adjuntar
            uno o más archivos en formato pdf, png o jpg.
          </Typography>

          <Stack spacing={1.5}>
            <RHFUpload
              disabled={!sendPDF}
              multiple
              thumbnail
              accept={{ 'image/*': [], 'application/pdf': [] }}
              name="images"
              maxSize={3145728}
              onDrop={handleDrop}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              // onUpload={uploadFiles}
            />
            {watch().images.length > 0 && (
              <LoadingButton
                variant="contained"
                color="primary"
                component="span"
                onClick={uploadFiles}
                loading={isLoading}
              >
                Generar Menú
              </LoadingButton>
            )}
          </Stack>
          {currentMenu && currentMenu.length > 0 && (
            <>
              <Divider sx={{ borderStyle: 'dashed' }} />
              <Typography variant="subtitle1">Generación Automática de Menú</Typography>
              <Typography variant="subtitle2">
                Deja que nuestro asistente inteligente cree un menú digital por ti, aprovechando la
                lista de productos que has cargado anteriormente.{' '}
              </Typography>
              <LoadingButton
                variant="contained"
                component="span"
                onClick={resetMenu}
                loading={isGeneratinBotPDF}
              >
                Generar Automáticamente
              </LoadingButton>
            </>
          )}
        </Stack>
      </Card>
    </>
  );

  const updateSendPDF = async () => {
    setSendPDF((prev) => !prev);
    console.log(sendPDF);
    axios
      .patch(API_ENDPOINTS.store.send_pdf(currentStore?.id))
      .then((val) => {
        Mixpanel.track('Store Customization', { id: currentStore.id, fields: 'send_pdf' });
        enqueueSnackbar('Configuración del bot actualizada');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {isLoading && (
        <LoadingScreen
          sx={{
            borderRadius: 1.5,
            bgcolor: 'background.default',
          }}
        />
      )}
      <Card sx={{ mb: 2 }}>
        <Stack direction="row" p={2} spacing={1}>
          <Checkbox size="medium" checked={sendPDF} onClick={() => updateSendPDF()} />
          <Typography variant="body1" alignSelf="center">
            Enviar el menú en formato PDF
          </Typography>
        </Stack>
      </Card>
      <Grid container spacing={2}>
        <Grid xs={12} md={6}>
          {renderDetails}
        </Grid>
        <Grid xs={12} md={6}>
          {renderCurrentMenu}
        </Grid>
        {/* activeStep === 0 && renderDetails */}
        {/* activeStep === 1 && renderStateMessages */}
        {/* false && renderActions */}
      </Grid>
      {/* 
      <Box sx={{ display: 'flex', mt: 3 }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={() => setActiveStep(activeStep - 1)}
          sx={{ mr: 1 }}
        >
          Anterior
        </Button>
        <Box sx={{ flexGrow: 1 }} />

        {activeStep !== steps.length - 1 && (
          <Button
            variant="contained"
            size="large"
            onClick={async () => {
              setActiveStep(activeStep + 1);
            }}
          >
            Siguiente
          </Button>
        )}
        {activeStep === steps.length - 1 && (
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            loading={isSubmitting}
            sx={{ ml: 2 }}
          >
            {!currentStore ? 'Crear Tienda' : 'Guardar cambios'}
          </LoadingButton>
        )}
      </Box>
     */}
    </FormProvider>
  );
}
