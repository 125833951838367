// @mui
// types
// components
import { LoadingButton } from '@mui/lab';
import {
  Badge,
  Box,
  Button,
  Container,
  IconButton,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material';
import ConversationQualification from 'src/components/conversation-qualification/conversation-qualification';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import Avatar from '@mui/material/Avatar';
import { useSettingsContext } from 'src/components/settings';
import useHasRoles from 'src/hooks/use-has-roles';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import ConversationAvatar from './conversation-avatar';
import { ChatNavItemSkeleton } from '../chat/chat-skeleton';

// ----------------------------------------------------------------------

type Props = {
  currentConversation: any;
  loadingState: boolean;
  onChangeQualification: VoidFunction;
  onChangePausedUser: VoidFunction;
  onViewOrder?: VoidFunction;
  onParentEvent?: VoidFunction;
  pausedUser: any;
};

export default function ConversationHeaderCompose({
  currentConversation,
  loadingState,
  onChangeQualification,
  onChangePausedUser,
  pausedUser,
  onViewOrder,
  onParentEvent
}: Props) {
  const isAdmin = useHasRoles(['ROLE_ADMIN']);

  const [width, setWidth] = useState<number>(window.innerWidth);

  const isMobile = width <= 768;

  return (
    <>
      {currentConversation ?  (
        <>
          <Stack direction="row" spacing={1.2} justifyContent="space-between" alignItems="center">
            {onParentEvent && (
              <IconButton onClick={onParentEvent}>
                <Iconify icon="ion:chevron-back-sharp" />
              </IconButton>
            )}
            <ConversationAvatar
              name={currentConversation?.name}
              imageUrl={currentConversation?.avatar}
              height={40}
              width={40}
            />
            <ListItemText
              primary={
                <Typography variant="body1" noWrap style={{ width: '100%' }}>
                  {currentConversation?.name}
                </Typography>
              }
              secondary={currentConversation?.username}
            />

            <Stack direction="row" spacing={2} sx={{ width: '30%' }}>
              {!!currentConversation && isAdmin && (
                <ConversationQualification
                  conversationId={currentConversation?.id}
                  qualification={currentConversation?.qualification}
                  onChangeQualification={onChangeQualification}
                />
              )}
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
              {!currentConversation.order && (
                <Button
                  variant="contained"
                  color={pausedUser ? 'success' : 'error'}
                  onClick={onChangePausedUser}
                >
                  {pausedUser ? (
                    <Typography variant="caption" component="span" color="white">
                      Activar Bot en este chat
                    </Typography>
                  ) : (
                    <>
                      <Typography variant="caption" component="span" color="white">
                        Pausar Bot en este chat
                      </Typography>
                    </>
                  )}
                </Button>
              )}
              {currentConversation.order && (
                <Button variant="contained" color="primary" onClick={onViewOrder}>
                  <Typography variant="caption" color="white">
                    Ver pedido
                  </Typography>
                </Button>
              )}
            </Stack>
          </Stack>
        </>
      ) : (
        <ChatNavItemSkeleton />
      )}
    </>
  );
}
