import { Helmet } from 'react-helmet-async';
// sections
import ProductAddonsListView from 'src/sections/product-addons/view/product-addons-list-view';


// ----------------------------------------------------------------------

export default function ProductAddonsListPage() {
  return (
    <>
      <Helmet>
        <title>Variantes</title>
      </Helmet>

      <ProductAddonsListView />
    </>
  );
}
