import React, { useEffect, useMemo, useState } from 'react'
import { IStoreItem } from 'src/types/store';
import { IUserRole } from 'src/types/user';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { useAuthContext } from 'src/auth/hooks';
import useHasRoles from './use-has-roles';

type Props ={
  light?: boolean
}
const useStores = ({light}: Props = {}) => {
  const isAdmin = useHasRoles(['ROLE_ADMIN']);
  const { user } = useAuthContext();
  
    const [stores, setStores] = useState<IStoreItem[]>([]);
    useEffect(() => {
      if (user){        
        const URL = light ? API_ENDPOINTS.store.light :  API_ENDPOINTS.store.list; 
         axios
         .get(URL)
         .then(({data}) => {
           if (data?.rows){
             setStores(data.rows);
           } else {
             setStores(data);
           }
         })
         .catch((error) => {
           
         })
      }
    }, [isAdmin, light, user])
    const memoizedStores = useMemo(() => stores, [stores]);

  return [memoizedStores];
  
}
export default useStores;