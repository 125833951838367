import Container from '@mui/material/Container';
import { useSettingsContext } from 'src/components/settings';
import CategoryNewEditForm from '../category-new-edit-form';


export default function StoreCategoryCreateView () {
    const settings = useSettingsContext();

    return (
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CategoryNewEditForm />
      </Container>
    );
}
