import { Helmet } from 'react-helmet-async';
// sections
import ProductAddonsCreateView from 'src/sections/product-addons/view/product-addons-create-view';


// ----------------------------------------------------------------------

export default function ProductAddonsCreatePage() {
  return (
    <>
      <Helmet>
        <title> Nueva variante</title>
      </Helmet>

      <ProductAddonsCreateView />
    </>
  );
}
