import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Iconify from 'src/components/iconify';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useLocales from 'src/locales/use-locales';
import { IChannel } from '../../types/conversation';


type Props = {
    channelsList: IChannel[]
  };

  export default function ChannelCarousel({ channelsList }: Props) {
  const { t } = useLocales();
  const [currentIndex, setCurrentIndex] = useState(0);
  const showArrows = channelsList?.length > 1;

  const sortedChannelsList = [...channelsList].sort((a, b) => {
    if (a.whatsapp_status === 'Connected' && a.bot_status === 'Active') {
        return -1;
    }
    if (b.whatsapp_status === 'Connected' && b.bot_status === 'Active') {
        return 1;
    }
    return 0;
});



  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % sortedChannelsList.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + sortedChannelsList.length) % sortedChannelsList.length);
  };

  if (!channelsList || channelsList.length === 0) {
    return (
        <Box rowGap={1.5} display="grid"  sx={{ p: 1 }}>
         <Stack
        flexShrink={0}
        spacing={0.5}
        direction="row"
        alignItems="center"
        sx={{
          color: 'secondary.main',
          typography: 'caption',
        }}
      >
        <Iconify width={16} icon="ic:baseline-whatsapp" />
        {t('Without Instances')}
      </Stack>
      </Box>
     
    );
  }
  

  const currentChannel = sortedChannelsList[currentIndex];
  const columnTemplate = showArrows ? "0.5fr 1.5fr 0.5fr 1.5fr" : "1.5fr 0.5fr 1fr";


  return (
    <Box columnGap="0.1rem" display="grid" gridTemplateColumns={columnTemplate} sx={{ py: 1, px: showArrows ? 0 : 1 }}>
        {showArrows && (
            <Stack
            flexShrink={0}
            direction="row"
            alignItems="flex-start"
          >
           <IconButton sx={{p:0,  alignSelf: 'flex-start' }} onClick={handlePrev}>
             <NavigateBeforeIcon />
           </IconButton>
          </Stack>
        )}

      <Stack
        direction="row"
        alignItems="flex-start"
        flexShrink={0}
        spacing={0.5}
        sx={{
          color: currentChannel.whatsapp_status === 'Connected' ? 'success.main' : 'error.main',
          typography: 'caption',
        }}
      >
        <Iconify width={16} icon="ic:baseline-phone" />
        {currentChannel.username}
      </Stack>
      <Stack
        direction="row"
        alignItems="flex-start"
        sx={{
          typography: 'caption',
        }}
      >
        <Tooltip title={`Whatsapp ${t(currentChannel.whatsapp_status)}`}>
          <Iconify width={16} icon="ic:baseline-whatsapp" color={currentChannel.whatsapp_status === 'Connected' ? 'success.main' : 'error.main'} />
        </Tooltip>
        
        <Tooltip title={t(`Bot ${currentChannel.bot_status}`)}>
            <span>
              <Iconify width={16} icon="fluent:bot-20-filled"  color={currentChannel.bot_status === 'Active' ? 'success.main' : 'error.main'}/>
            </span>
          </Tooltip>
        
      </Stack>

      {showArrows && (
        <Stack
          flexShrink={0}
          direction="row"
          alignItems="flex-end"
        >
        <IconButton sx={{p:0,  alignSelf: 'flex-start' }}  onClick={handleNext}>
          <NavigateNextIcon />
        </IconButton>
        </Stack>
      )}
    </Box>
  );
};
