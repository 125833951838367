import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { useState, useCallback, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, ButtonBase, Stack, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// _mock
import { _orders, ORDER_STATUS_OPTIONS } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useSelector } from 'react-redux/es/hooks/useSelector';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
import { useLocales } from 'src/locales';

//
import useStores from 'src/hooks/use-stores';
import useChannelStates from 'src/hooks/use-channel-states';
import { useSnackbar } from 'src/components/snackbar';
import CategoryTableRow from '../channel-table-row';
import ChannelTableToolbar from '../channel-table-toolbar';
import ChannelTableFiltersResult from '../channel-table-filters-result';
import QRInstance from '../qr-instance';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Canal', width: 280 },
  { id: 'username', label: 'Nombre de usuario', width: 280 },
  { id: 'store', label: 'Tienda', width: 280 },
  { id: 'state', label: 'BOT', width: 280 },
  { id: 'status', label: 'Conexión WhatsApp', width: 280 },
  { id: 'billingPlan', label: 'Plan de pago', width: 280 },
  { id: 'qr', width: 100 },
  { id: 'On/Off', width: 100 },
  { id: '', width: 88 },
];


const defaultFilters: {
  store: string;
  status: string;
  channelName: string;
  billingPlan: string;
} = {
  store: '',
  status: 'all',
  channelName: '',
  billingPlan: ''
};
// ----------------------------------------------------------------------

export default function InstanceListView() {
  const table = useTable({ defaultOrderBy: 'id' });

  const settings = useSettingsContext();

  const { enqueueSnackbar } = useSnackbar();
  const instanceState = useSelector((state: any) => state.instanceState);

  const router = useRouter();

  const confirm = useBoolean();
  const { t } = useLocales();
  const showLoader= useBoolean();

  const [tableData, setTableData] = useState<any[]>([]);
  const [stores] = useStores({light: true});
  const [filters, setFilters] = useState(defaultFilters);
  
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset =
    !!filters.store;

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(

    (name: string, value: any) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );
  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );
  const [states] = useChannelStates();
  const STATUS_OPTIONS = [
    { value: 'all', label: 'Todos' },
    ...states.map((state: any) => ({
      label: state.name,
      value: state.name,
      id: state.stateId,
    })),
  ];
  const handleDeleteRow = useCallback(
    (basePath: string, id: string) => {
      axios.delete(API_ENDPOINTS.instance.delete(basePath, id)).then((val) => {
        enqueueSnackbar('Instancia eliminada con éxito');
        const deleteRow = tableData.filter((row) => row.id !== id);
        setTableData(deleteRow);
        table.onUpdatePageDeleteRow(dataInPage.length);
      }).catch((err)=>{
        
      })
    },
    [dataInPage.length, table, tableData, enqueueSnackbar]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter((row) => table.selected.includes(row.id));
    
    deleteRows.forEach((row) =>{
      handleDeleteRow(row.basePath, row.id);
    })
    /*
    setTableData(deleteRows);
    ssss
    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    }); */
  }, [table, tableData, handleDeleteRow]);

  const handleViewRow = useCallback(
    (id: string) => {
       router.push(paths.dashboard.order.details(id));
    },
    [router]
  );
  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  
  const notification = useSelector((state: any) => state.notification);

  useEffect(() => {
    if (notification.instanceState){
      const {event_name} = notification.instanceState;
      getChannelList(filters?.store, filters?.channelName, filters?.billingPlan);
      }
  }, [notification, filters])
  
  useEffect(() => {    
    getChannelList(filters?.store, filters?.channelName, filters?.billingPlan);
  }, [filters?.store, filters?.channelName, filters?.billingPlan])
  
    const getChannelList = (storeId?: string, channelName?: string, billingPlan?:string) => {
      const URL = storeId
      ? API_ENDPOINTS.channel.storeQuery(storeId,`?type=${channelName}`)
      : API_ENDPOINTS.channel.list(`?type=${channelName}&billingPlan=${billingPlan}`);
      axios
      .get(URL)
      .then(({data}) => {
        setTableData(data);
      })
      .catch((error) => {
        
      })
    }
  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      
        <Stack
      direction="column"
      alignItems="start"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
      Listado de instancias
      </Typography>
    </Stack>
    
        <Card>
        <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                    }
                    color={
                      (tab.value === 'Active' && 'success') ||
                      (tab.value === 'Paused' && 'warning') ||
                      (tab.value === 'Dissabled' && 'error') ||
                      'default'
                    }
                  >
                    {tab.value === 'all' && tableData.length}
                    {tab.value === 'Active' &&
                      tableData.filter((instance) => instance.state?.name === 'Active').length}

                    {tab.value === 'Paused' &&
                      tableData.filter((instance) => instance.state?.id === 'Change Password').length}
                    {tab.value === 'Dissabled' &&
                      tableData.filter((instance) => instance.state?.name === 'Dissabled').length}
                    {tab.value === 'Created' &&
                      tableData.filter((instance) => instance.state?.name === 'Created').length}
                  </Label>
                }
              />
            ))}
          </Tabs>
        <ChannelTableToolbar
            filters={filters}
            onFilters={handleFilters}
            storeOptions={stores}
            
          />
          {canReset && (
            <ChannelTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={dataFiltered.length}
              storeOptions={stores}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
           

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />

                <TableBody>
                  {dataFiltered
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row) => (
                      <CategoryTableRow
                        key={row.id}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.basePath, row.id)}
                        onViewRow={() => handleViewRow(row.id)}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Eliminar selección"
        content={
          <>
            Estás seguro que deseas eliminar <strong> {table.selected.length} </strong> instancias?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Eliminar
          </Button>
        }
      />
      <QRInstance showQrModal/>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters
}: {
  inputData: any[];
  comparator: (a: any, b: any) => number;
  filters: any;
}) {
  const { store, status } = filters;
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  inputData = stabilizedThis.map((el) => el[0]);

  if (status !== 'all') {
    inputData = inputData.filter((instance) => instance.state?.name === status);
  }

 

  
  return inputData;
}
