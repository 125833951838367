import { useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
import Label from 'src/components/label';
import { showSpinner } from 'src/redux/slices/spinner';
import { useDispatch } from 'react-redux';
import { useLocales } from 'src/locales';
import { Switch } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  // row: IOrderItem;
  row: any;
  selected: boolean;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  onEditRow: Function;
  OnHandleDelivery: VoidFunction;
  OnHandleTakeaway: VoidFunction;
  OnHandleStatus: VoidFunction;
};

export default function MethodsTableRow({
  row,
  selected,
  onSelectRow,
  onEditRow,
  onDeleteRow,
  OnHandleDelivery,
  OnHandleTakeaway,
  OnHandleStatus
}: Props) {
  const { id, active, enterprise, link, name, cbu, description, delivery, takeaway, } = row;
  const { enqueueSnackbar } = useSnackbar();

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  const [dataUser, setData] = useState([]);

  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useLocales();

  const toogleDelivery = () => {
    row.delivery = !row.delivery;
    OnHandleDelivery();
  }

  const toogleTakeaway = () => {
    row.takeaway = !row.takeaway;
    OnHandleTakeaway();
  }

  const toogleActive = () => {
    row.active = !row.active;
    OnHandleStatus();
  }



  

  return (
    <>
      <TableRow hover selected={selected} key={`i-${id}`}>
        <TableCell>
          <Box>{id}</Box>
        </TableCell>
        <TableCell>
          <Box>{t(`paymentMethod.${name}`)}</Box>
        </TableCell>
        <TableCell>
          <Box>{description}</Box>
        </TableCell>
        <TableCell>
          <Box>{enterprise}</Box>
        </TableCell>
        <TableCell>
          <Box>{link || cbu}</Box>
        </TableCell>
        {/*
        <TableCell sx={{ typography: 'caption', color: 'text.secondary' }}>
          <Switch color="primary"
            checked={delivery}
            onChange={toogleDelivery}
          />
        </TableCell>
        <TableCell sx={{ typography: 'caption', color: 'text.secondary' }}>
          <Switch color="primary"
            checked={takeaway}
            onChange={toogleTakeaway}
          />
        </TableCell>
        */}
          <TableCell sx={{ typography: 'caption', color: 'text.secondary' }}>
            <Switch color="primary"
              checked={active}
              onChange={toogleActive}
            />
        </TableCell>
        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {
            name !== 'Cash2' && (
<IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
            )
          }
          
        </TableCell>
      </TableRow>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            onEditRow();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Editar
        </MenuItem>
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Eliminar
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Eliminar"
        content="Estás seguro que deseas eliminar este metodo de pago?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Eliminar
          </Button>
        }
      />
    </>
  );
}
