import Container from '@mui/material/Container';
import React from 'react'
import { useSettingsContext } from 'src/components/settings';

import StoreNewEditSimpleForm from '../store-new-edit-simple-form';

export default function StoreCreateView () {
    const settings = useSettingsContext();

    return (
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <StoreNewEditSimpleForm />
      </Container>
    );
}
