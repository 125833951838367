import * as Yup from 'yup';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// routes
// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// types
import { IUserItem } from 'src/types/user';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { CustomFile } from 'src/components/upload';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
  RHFMultiSelect,
} from 'src/components/hook-form';
import { IStoreType } from 'src/types/store';
import useStores from 'src/hooks/use-stores';
import useRoles from 'src/hooks/use-roles';
import useUserStates from 'src/hooks/use-user-states';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { InputAdornment, Stack } from '@mui/material';
import { useRouter } from 'src/routes/hook/use-router';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  channel: string;
  basePath: string;
  usernameType: string;
};

export default function ChannelQuickInstanceForm({
  channel,
  basePath,
  usernameType,
  open,
  onClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [stores] = useStores();
  const NewInstanceSchema = Yup.object().shape({
    code: Yup.string().required('El código de país es requerido'), // .when(usernameType,{is: true, otherwise: Yup.string().notRequired}),
    username: Yup.string().required('El usuario es requerido'),
    store: Yup.string().required('La tienda es requerida'),
    virtualAssistant: Yup.string().required('El asistente virtual es requerido'),
  });

  const defaultValues = useMemo(
    () => ({
      code: '54',
      username: '',
      store: '',
      virtualAssistant: 1,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm<any>({
    resolver: yupResolver(NewInstanceSchema),
    defaultValues,
    mode: 'onBlur',
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const router = useRouter();

  const onSubmit = useCallback(
    async (params: any) => {
      const { username, store, virtualAssistant, code } = params;
      const username_aux = usernameType === 'phone' ? code + username : username;
      const aux = {
        username: username_aux,
        store: parseInt(store, 10),
        virtualAssistant: parseInt(virtualAssistant, 10),
      };
      axios
        .post(basePath, aux)
        .then(({ data }) => {
          enqueueSnackbar('Instancia creada con éxito');
          reset();
          onClose();
          router.reload();
        })
        .catch((error) => {
          
        });
    },
    [enqueueSnackbar, onClose, reset, basePath, usernameType, router]
  );
  const renderUsernameField = () => {
    switch (usernameType) {
      case 'phone':
        return (
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          
              <RHFSelect label="Código país" name="code"  sx={{
                  maxWidth: { md: 160 }
                }}>
                {countries.map((country: any) => (
                  <MenuItem key={country?.code} value={country?.phone}>
                    <Iconify
                      key={country?.label}
                      icon={`circle-flags:${country?.code.toLowerCase()}`}
                      width={28}
                      sx={{ mr: 1 }}
                    />
                    ({country?.code}) +{country?.phone}
                  </MenuItem>
                ))}
              </RHFSelect>
            
            <RHFTextField name="username" label="Usuario"/>
          </Stack>
        );
      case 'social':
        return (
          <RHFTextField
            name="username"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="lucide:at-sign" />
                </InputAdornment>
              ),
            }}
          />
        );

      default:
        return <RHFTextField name="username" label="Usuario" />;
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Crear instancia de {channel}</DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)',
            }}
          >
            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />

            {renderUsernameField()}
            <RHFSelect
              label="Tienda"
              name="store"
              placeholder="Seleccione una tienda"
              InputLabelProps={{ shrink: true }}
              defaultValue=""
            >
              <MenuItem key="default-c" value="">
                Seleccione una categoría
              </MenuItem>
              {stores.map((store: IStoreType) => (
                <MenuItem key={store?.id} value={store?.id}>
                  {store?.name}
                </MenuItem>
              ))}
            </RHFSelect>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Crear
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
