import { useState, useCallback, useEffect } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
// _mock
import { _jobs, JOB_PUBLISH_OPTIONS, JOB_DETAILS_TABS } from 'src/_mock';
// components
import Label from 'src/components/label';
import { useSettingsContext } from 'src/components/settings';
//
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import StoreDetailsContent from '../store-details-content';

// ----------------------------------------------------------------------

export default function StoreDetailsView() {
  const settings = useSettingsContext();

  const  [currentStore, setcurrentStore] = useState();
  
  const params = useParams();

  const { id } = params;

  useEffect(() => {
    axios.get(API_ENDPOINTS.store.details(id || '')).then(({data}) => {
      
      setcurrentStore(data);
    })
  }, [id])
  
  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
  
    <StoreDetailsContent currentStore={currentStore || {}} />
    
    </Container>
  );
}
