import { Helmet } from 'react-helmet-async';
// sections
import { DenyReasonCreateView } from 'src/sections/deny-reason/view';
// ----------------------------------------------------------------------

export default function DenyReasonCreatePage() {
  return (
    <>
      <Helmet>
        <title> Nuevo motivo de rechazo </title>
      </Helmet>

      <DenyReasonCreateView />
    </>
  );
}
