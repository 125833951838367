import { Helmet } from 'react-helmet-async';
import ProductsComboEditView from 'src/sections/product-combos/view/product-combo-edit-view';

// ----------------------------------------------------------------------

export default function ProductsComboEditPage() {
  return (
    <>
      <Helmet>
        <title> Editar Combo </title>
      </Helmet>

      <ProductsComboEditView />
    </>
  );
}
