import * as Yup from 'yup';
import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { IPromptItem } from 'src/types/prompt';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'src/routes/hook';
import { useResponsive } from 'src/hooks/use-responsive';
import { paths } from 'src/routes/paths';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFCheckbox,
  RHFMultiCheckbox,
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/system/Stack';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { ICurrencyItem } from 'src/types/currency';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import { ICityItem, ICountryItem, IProvinceItem } from 'src/types/location';
import { IStoreItem } from 'src/types/store';
import { Mixpanel } from 'src/Mixpanel';
import useStoreCategories from 'src/hooks/use-stores copy';

// Table Simple
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Scrollbar from 'src/components/scrollbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/iconify';
import useLocales from 'src/locales/use-locales';
import PaymentMethodsView from '../payment-methods/view/payment-methods-view';
import DenyReasonView from '../deny-reason/view/deny-reason-list-view';


type Props = {
  currentStore?: IStoreItem;
};
type FormValuesProps = any;

export default function StoreNewEditForm({ currentStore }: any) {
  const { t } = useLocales();
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');
  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const steps = ['Datos del comercio', 'Ubicación', 'Métodos de entrega', 'Preguntas frecuentes'];
  const [activeStep, setActiveStep] = useState(0);

  const [faqsStore, setFaqs] = useState([]); // listar las preguntas y respuestas de la tienda
  // const [stackCountFaqs, setStackCountFAQ] = useState(1); // manejar el numero de stack para preguntas y respuestas
  const [faqsStacks, setFAQs] = useState([{ answer: '', question: '' }]);

  const NewStoreSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    schedule_text: Yup.string().required('Los horarios de atención son requeridos'),
    waiting_time: Yup.string().required('El tiempo de espera es requerido'),
    // delivery_fee2:  Yup.number().min(0, 'El costo de envío no puede ser menor a 0').required('El costo de envío es requerido'),
    delivery_fee: Yup.number().when(['delivery'], ([delivery]) =>
      delivery
        ? Yup.number()
          .min(0, 'El costo de envío no puede ser menor a 0')
          .required('El costo de envío es requerido')
        : Yup.number()
    ),
    delivery: Yup.boolean(),
    delivery_zone: Yup.string().when(['delivery'], ([delivery]) =>
      delivery ? Yup.string().required('Las zonas de entrega son requeridas') : Yup.string()
    ),
    city_id: Yup.string().required('La ciudad es requerida'),
    country_id: Yup.string().required('El país es requerido'),
    province_id: Yup.string().required('La provincia es requerida'),
    street: Yup.string().required('La calle es requerida'),
    // latitude: Yup.string().required('La latitud es requerida'),
    // longitude: Yup.string().required('La longitud es requerida'),
    house_number: Yup.string().required('El número es requerido'),
    currency_id: Yup.string().required('El tipo de moneda es requerido'),
    // prompt_id: Yup.string().required('El Prompt es requerido'),
    store_categories: Yup.array().min(
      1,
      'Se debe seleccionar al menos una categoría para la tienda'
    ),
    language: Yup.string().required('El idioma es requerido'),
    order_state_workflow: Yup.string().required('El flujo de comunicación es requerido'),
    welcome_message_check: Yup.boolean(),
    welcome_message: Yup.string().when(['welcome_message_check'], ([welcome_message_check]) =>
      welcome_message_check
        ? Yup.string().required('El mensaje de bienvenida es requerido')
        : Yup.string()
    ),
    closed_message: Yup.string().when(['closed_message_check'], ([closed_message_check]) =>
      closed_message_check
        ? Yup.string().required('El mensaje de cierre es requerido')
        : Yup.string()
    ),
    closed_paused_message_check: Yup.boolean(),
    closed_paused_message: Yup.string().when(['closed_paused_message_check'], ([closed_paused_message_check]) =>
      closed_paused_message_check
        ? Yup.string().required('El mensaje de cierre temporal es requerido')
        : Yup.string()
    ),
/*    alternative_currency: Yup.string().notRequired(),
    exchange_rate: Yup.number().when(['alternative_currency'], ([alternative_currency]) =>
      alternative_currency !== ''
        ? Yup.number().required('El tipo de cambio es requerido')
        : Yup.number().notRequired()
    ), */
    closed_message_check: Yup.boolean(),
    ask_min_amount_delivery: Yup.boolean()
  });
  const defaultValues: any = useMemo(
    () => ({
      name: currentStore?.name || '',
      schedule_text: currentStore?.schedules_text || '',
      waiting_time: currentStore?.waiting_time || '',
      delivery_fee: currentStore?.delivery_fee || 0,
      delivery_zone: currentStore?.delivery_zone || '',
      delivery: currentStore?.delivery || false,
      takeaway: currentStore?.takeaway || false,
      street: currentStore?.address?.street || '',
      latitude: currentStore?.address?.latitude || '-212345', // TODO ver si la api envia estos datos
      longitude: currentStore?.address?.longitude || '-45456',
      house_number: currentStore?.address?.houseNumber || '',
      currency_id: currentStore?.currency?.id || '',
      prompt_id: currentStore?.prompt?.id || '',
      country_id: currentStore?.address?.city?.province?.country?.id || '',
      province_id: currentStore?.address?.city?.province?.id || '',
      city_id: currentStore?.address?.city?.id || '',
      language: currentStore?.language?.id || '',
      order_state_workflow: currentStore?.orderStateWorkflow?.id || '',
      store_categories: currentStore?.store_categories.map((category: any) => category.id) || [],
      notifications: currentStore?.notifications || false,
      send_pdf: currentStore?.send_pdf || false,
      welcome_message: currentStore?.welcome_message || '',
      welcome_message_check: !!currentStore?.welcome_message || false,
      closed_message_check: !!currentStore?.closed_message || false,
      closed_message: currentStore?.closed_message || '',
      closed_paused_message_check: !!currentStore?.closed_paused_message || false,
      closed_paused_message: currentStore?.closed_paused_message || '',
      resume_observation: currentStore?.resume_observation || '',
//      min_amount_delivery: currentStore?.min_amount_delivery || '',
  //    ask_min_amount_delivery: currentStore?.ask_min_amount_delivery || false,
      close_orders_automatically: currentStore?.close_orders_automatically || false,
      // alternative_currency: currentStore?.alternative_currency?.id || '',
      // exchange_rate: currentStore?.exchange_rate || undefined,     
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStore]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewStoreSchema),
    defaultValues,
    mode: 'onBlur',
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    watch,
    trigger,
  } = methods;

  useEffect(() => {
    if (currentStore) {
      reset(defaultValues);
      axios
        .get(API_ENDPOINTS.store.faqsStore(currentStore.id))
        .then(({ data }) => {
          console.log(data);
          setFaqs(data);
        })
        .catch((error) => {
          // Handle error
        });
    }
  }, [currentStore, defaultValues, reset]);
  const getModifiedFields = useCallback(() => {
    const watchedFields = watch(); // Obtener todos los valores observados

    const modifiedFields = Object.keys(defaultValues).filter(
      (fieldName) => defaultValues[fieldName] !== watchedFields[fieldName]
    );

    return modifiedFields;
  }, [defaultValues, watch]);
  const editStore = useCallback(
    async (id: string, params: FormValuesProps) => {
      const {
        name,
        schedule_text,
        street,
        house_number,
        waiting_time,
        delivery_fee,
        delivery_zone,
        city_id,
        latitude,
        longitude,
        prompt_id,
        currency_id,
        delivery,
        takeaway,
        store_categories,
        language,
        order_state_workflow,
        notifications,
        welcome_message,
        welcome_message_check,
        closed_message,
        closed_message_check,
        closed_paused_message,
        closed_paused_message_check,
        send_pdf,
        close_orders_automatically,
        faqs,
        resume_observation,
        alternative_currency,
       min_amount_delivery,
        exchange_rate,
        ask_min_amount_delivery
      } = params;
      const DATA = {
        name,
        schedule_text,
        waiting_time,
        delivery_fee,
        delivery_zone,
        delivery,
        takeaway,
        city_id: parseInt(city_id, 10),
        street,
        latitude,
        longitude,
        house_number,
        currency_id: parseInt(currency_id, 10),
        prompt_id: parseInt(prompt_id, 10),
        store_categories,
        language: parseInt(language, 10),
        order_state_workflow: parseInt(order_state_workflow, 10),
        notifications,
        send_pdf,
        resume_observation,
        welcome_message: welcome_message_check === true ? welcome_message : '',
        closed_message: closed_message_check === true ? closed_message : '',
        closed_paused_message: closed_paused_message_check === true ? closed_paused_message : '',
        faqs: faqsStacks[0].question === '' && faqsStacks[0].answer === '' ? [] : faqsStacks,
        // ask_min_amount_delivery,
        close_orders_automatically,
      //  alternative_currency: parseInt(alternative_currency, 10) || 0,
       // min_amount_delivery: parseFloat(min_amount_delivery) || 0,
       // exchange_rate: parseFloat(exchange_rate) || 0,
      };
      const modifiedFields = getModifiedFields();
      if (faqsStacks[0].question === '' && faqsStacks[0].answer === '') {
        axios
          .put(API_ENDPOINTS.store.edit(id), DATA)
          .then(({ data }) => {
            enqueueSnackbar('Tienda editada con éxito');
            if (currentStore?.send_pdf !== send_pdf) {
              const EVENT = send_pdf ? 'PDF Activated' : 'PDF Deactivated';
              Mixpanel.track(EVENT, { id });
            }
            if (modifiedFields.length > 0) {
              Mixpanel.track('Store Customization', { id, fields: modifiedFields });
            }
            reset();
            router.push(paths.dashboard.store.details(id));
          })
          .catch((error) => { });
      } else {
        const axiosRequests = [];

        for (let i = 0; i < faqsStacks.length; i += 1) {
          axiosRequests.push(
            axios.post(API_ENDPOINTS.store.storefaqcreate, {
              question: faqsStacks[i].question,
              answer: faqsStacks[i].answer,
              store: id,
            })
          );
        }

        Promise.all(axiosRequests)
          .then(() => axios.put(API_ENDPOINTS.store.edit(id), DATA))
          .then(({ data }) => {
            enqueueSnackbar('Tienda editada con éxito');
            if (currentStore?.send_pdf !== send_pdf) {
              const EVENT = send_pdf ? 'PDF Activated' : 'PDF Deactivated';
              Mixpanel.track(EVENT, { id });
            }
            if (modifiedFields.length > 0) {
              Mixpanel.track('Store Customization', { id, fields: modifiedFields });
            }
            reset();
            router.push(paths.dashboard.store.details(id));
          })

          .catch((error) => {
            // Handle errors
          });
      }
    },
    [enqueueSnackbar, reset, router, faqsStacks, currentStore?.send_pdf, getModifiedFields]
  );
  const createStore = useCallback(
    async (params: FormValuesProps) => {
      const {
        name,
        schedule_text,
        street,
        house_number,
        waiting_time,
        delivery_fee,
        delivery_zone,
        city_id,
        latitude,
        longitude,
        prompt_id,
        currency_id,
        delivery,
        takeaway,
        store_categories,
        language,
        order_state_workflow,
        notifications,
        welcome_message,
        welcome_message_check,
        closed_message_check,
        closed_message,
        closed_paused_message_check,
        closed_paused_message,
        send_pdf,
        min_amount_delivery,        
        ask_min_amount_delivery,
        close_orders_automatically,
        resume_observation,
       // alternative_currency,
       // exchange_rate
      } = params;

      const DATA = {
        name,
        schedule_text,
        waiting_time,
        delivery_fee,
        delivery_zone: delivery_zone || 'El comercio no tiene delivery',
        delivery,
        takeaway,
        city_id: parseInt(city_id, 10),
        street,
        latitude,
        longitude,
        house_number,
        currency_id: parseInt(currency_id, 10),
        prompt_id: parseInt(prompt_id, 10),
        store_categories,
        language: parseInt(language, 10),
        order_state_workflow: parseInt(order_state_workflow, 10),
        notifications,
        send_pdf,
        welcome_message: welcome_message_check ? welcome_message : '',
        closed_message: closed_message_check === true ? closed_message : '',
        closed_paused_message: closed_paused_message_check === true ? closed_paused_message : '',
        faqs: faqsStacks.every((faq) => faq.question === '' && faq.answer === '') ? [] : faqsStacks,
     //   min_amount_delivery: parseFloat(min_amount_delivery) || 0,
       // ask_min_amount_delivery,
        close_orders_automatically,
        resume_observation,
       // alternative_currency: parseInt(alternative_currency, 10) || 0,
       // exchange_rate: parseFloat(exchange_rate) || 0,
      };
      axios
        .post(API_ENDPOINTS.store.create, DATA)
        .then(({ data }) => {
          Mixpanel.track('Store Creation', { name });
          enqueueSnackbar('Tienda creada con éxito');
          reset();
          router.push(paths.dashboard.store.root);
        })
        .catch((error) => { 
          enqueueSnackbar(t(error?.error),  {variant: 'error'} );
        });
    },
    [enqueueSnackbar, reset, router, faqsStacks, t]
  );

  const onSubmit = useCallback(
    async (params: FormValuesProps) => {
      if (currentStore) {
        editStore(currentStore.id, params);
      } else {
        createStore(params);
      }
    },
    [currentStore, editStore, createStore]
  );
  const [categories] = useStoreCategories();

  useEffect(() => {
    getCurrencies();
    getCountries();
    getAllProvinces();
    getAllCities();
    getLanguages();
    getWorkflow();
  }, []);

  const getCurrencies = () => {
    axios
      .get(API_ENDPOINTS.currency.list)
      .then(({ data }) => {
        setCurrencies(data);
      })
      .catch((error) => { });
  };
  const getCountries = () => {
    axios
      .get(API_ENDPOINTS.country.list)
      .then(({ data }) => {
        setCountries(data);
      })
      .catch((error) => { });
  };
  const getLanguages = () => {
    axios
      .get(API_ENDPOINTS.languages.list)
      .then(({ data }) => {
        setLanguages(data);
      })
      .catch((error) => { });
  };
  const getWorkflow = () => {
    axios
      .get(API_ENDPOINTS.workflow.list)
      .then(({ data }) => {
        setWorkflows(data);
      })
      .catch((error) => { });
  };
  // TODO reiniciar la seleccion de provincias y ciudades
  const getProvince = (ev: any) => {
    const countryId = ev.target.value;
    setValue('country_id', countryId);
    setValue('province_id', '');
    setValue('city_id', '');
    axios
      .get(API_ENDPOINTS.province.list, { params: { country: countryId } })
      .then(({ data }) => {
        setProvinces(data);
      })
      .catch((error) => { });
  };
  const getAllProvinces = () => {
    axios
      .get(API_ENDPOINTS.province.list)
      .then(({ data }) => {
        setProvinces(data);
      })
      .catch((error) => { });
  };
  const getAllCities = () => {
    axios
      .get(API_ENDPOINTS.city.light)
      .then(({ data }) => {
        setCities(data);
      })
      .catch((error) => { });
  };
  // TODO reiniciar la seleccion de provincias y ciudades
  const getCities = (ev: any) => {
    const provinceId = ev.target.value;
    setValue('province_id', provinceId);
    setValue('city_id', '');
    axios
      .get(API_ENDPOINTS.city.light, { params: { province: provinceId } })
      .then(({ data }) => {
        setCities(data);
      })
      .catch((error) => { });
  };

  const addFAQ = () => {
    setFAQs([...faqsStacks, { answer: '', question: '' }]);
  };

  const handleDeleteStack = (index: any) => {
    const newFAQs = [...faqsStacks];
    if (newFAQs.length > 1) {
      newFAQs.splice(index, 1);
      setFAQs(newFAQs);
    }
  };

  const handleQuestionChange = (index: any, value: any) => {
    const newFAQs = [...faqsStacks];
    newFAQs[index].question = value;
    setFAQs(newFAQs);
  };

  const handleAnswerChange = (index: any, value: any) => {
    const newFAQs = [...faqsStacks];
    newFAQs[index].answer = value;
    setFAQs(newFAQs);
  };

  const renderDetails = (
    <>
      <Grid xs={12}>
        <Card>
          {!mdUp && <CardHeader title="Detalle" />}

          <Stack spacing={2.5} sx={{ p: 3 }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <Stack
                spacing={1.5}
                sx={{
                  width: '100%',
                }}
              >
                <Typography variant="subtitle2">Nombre</Typography>
                <RHFTextField name="name" placeholder="Ingrese el nombre del comercio" />
              </Stack>
              <Stack
                spacing={1.5}
                sx={{
                  width: '100%',
                }}
              >
                <Typography variant="subtitle2">Tipo de comercio</Typography>

                <RHFMultiSelect
                  chip
                  name="store_categories"
                  placeholder="Seleccione una o más categorias"
                  options={categories.map((category: any) => ({
                    value: category.id,
                    label: category.name,
                  }))}
                />
              </Stack>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <Stack
                sx={{
                  width: '100%',
                }}
                spacing={1.5}
              >
                <Typography variant="subtitle2">Pasos en cada pedido</Typography>
                <RHFSelect
                  label="Seleccione una opcion"
                  InputLabelProps={{ shrink: watch().order_state_workflow.value }}
                  name="order_state_workflow"
                >
                  {workflows.map((workflow: any) => (
                    <MenuItem key={workflow?.id} value={workflow?.id}>
                      {workflow?.description}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
             
             {/* 
             <Stack
                sx={{
                  width: '100%',
                }}
                spacing={1.5}
              >
                <Typography variant="subtitle2">Monto mínimo por pedido</Typography>
                <RHFTextField
                  name="min_amount_delivery"
                  type="number"
                  placeholder="Ingrese el monto mínimo por pedido"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box component="span" sx={{ color: 'text.disabled' }}>
                          $
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
             */} 
            
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <Stack
                sx={{
                  width: '100%',
                }}
                spacing={1.5}
              >
                <Typography variant="subtitle2">Idioma</Typography>
                <RHFSelect
                  name="language"
                  placeholder="Seleccione un idioma"
                  label="Seleccione un idioma"
                  InputLabelProps={{ shrink: watch().language.value }}
                >
                  {languages.map((language: any) => (
                    <MenuItem key={language?.id} value={language?.id}>
                      {language?.name} ({language?.code})
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
              <Stack
                sx={{
                  width: '100%',
                  alignSelf: 'center',
                }}
              >
                <RHFCheckbox
                  name="notifications"
                  label="Notificar al cliente ante un cambio de estado en el pedido"
                />
                <RHFCheckbox name="send_pdf" label="Enviar el menú en formato PDF" />
                {/*
                <RHFCheckbox name="ask_min_amount_delivery" label="Consultar monto de pago en efectivo" />
                 */}

                <RHFCheckbox name="close_orders_automatically" label="Cierre automático de pedidos" />
              </Stack>
            </Stack>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <Stack
                sx={{
                  width: '100%',
                }}
                spacing={1.5}
              >
                <Typography variant="subtitle2">Moneda</Typography>
                <RHFSelect
                  name="currency_id"
                  label="Seleccione una moneda"
                  InputLabelProps={{ shrink: watch().currency_id.value }}
                  defaultValue=""
                >
                  {currencies.map((currency: ICurrencyItem) => (
                    <MenuItem key={currency?.id} value={currency?.id}>
                      {currency?.name}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
{/*           
              <Stack
                sx={{
                  width: '100%',
                }}
                spacing={1.5}
              >
                <Typography variant="subtitle2">Moneda alternativa</Typography>
                <RHFSelect
                  name="alternative_currency"
                  label="Seleccione una moneda alternativa"
                  InputLabelProps={{ shrink: watch().alternative_currency.value }}
                  defaultValue=""
                >
                  <MenuItem key="alternative-default" value="">
                    Sin moneda
                  </MenuItem>
                  {currencies.map((currency: ICurrencyItem) => (
                    <MenuItem key={currency?.id} value={currency?.id}>
                      {currency?.name}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
              {watch().alternative_currency && (
                <>
                  <Stack
                    sx={{
                      width: '100%',
                    }}
                    spacing={1.5}
                  >
                    <Typography variant="subtitle2">Tipo de cambio</Typography>
                    <RHFTextField
                      name="exchange_rate"
                      type="number"
                      placeholder="Ingrese el tipo de cambio"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box component="span" sx={{ color: 'text.disabled' }}>
                              $
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </>)}
            
                    */}
            </Stack>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack spacing={1.5}>
              <RHFCheckbox name="welcome_message_check" label="Crear mensaje de bienvenida" />
              {watch().welcome_message_check && (
                <>
                  <Typography variant="subtitle2">Mensaje de bienvenida</Typography>
                  <RHFTextField
                    name="welcome_message"
                    placeholder="Ingrese un mensaje de bienvenida"
                    multiline
                    rows={4}
                  />
                </>
              )}
            </Stack>

            <Stack spacing={1.5}>
              <RHFCheckbox name="closed_message_check" label="Crear mensaje de cierre" />
              {watch().closed_message_check && (
                <>
                  <Typography variant="subtitle2">Mensaje de cierre</Typography>
                  <RHFTextField
                    name="closed_message"
                    placeholder="Ingrese un mensaje de cierre"
                    multiline
                    rows={4}
                  />
                </>
              )}
            </Stack>

            <Stack spacing={1.5}>
              <RHFCheckbox name="closed_paused_message_check" label="Crear mensaje de cierre temporal" />
              {watch().closed_paused_message_check && (
                <>
                  <Typography variant="subtitle2">Mensaje de cierre Temporal</Typography>
                  <RHFTextField
                    name="closed_paused_message"
                    placeholder="Ingrese un mensaje de cierre Temporal"
                    multiline
                    rows={4}
                  />
                </>
              )}
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Tiempo de preparación aproximado</Typography>
              <RHFSelect
                name="waiting_time"
                label="Tiempo de preparación"
                placeholder="Selecciona un tiempo de preparación"
                fullWidth
                required
              >
                <MenuItem value="15">15 minutos</MenuItem>
                <MenuItem value="30">30 minutos</MenuItem>
                <MenuItem value="45">45 minutos</MenuItem>
                <MenuItem value="60">60 minutos</MenuItem>
              </RHFSelect>
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Horarios de atención</Typography>
              <RHFTextField
                name="schedule_text"
                placeholder="Ingrese los horarios de atención"
                multiline
                rows={6}
              />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Mensaje de orden confirmada</Typography>
              <RHFTextField
                name="resume_observation"
                placeholder="Ingrese un mensaje de orden confirmada"
                multiline
                rows={4}
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderLocation = (
    <>
      <Grid xs={12}>
        <Card>
          {!mdUp && <CardHeader title="Ubicación" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">País</Typography>
              <RHFSelect
                name="country_id"
                label="Seleccione un país"
                InputLabelProps={{ shrink: watch().country_id.value }}
                onChange={getProvince}
                defaultValue=""
              >
                {countries.map((country: ICountryItem) => (
                  <MenuItem key={country?.id} value={country?.id}>
                    {country?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Provincia</Typography>
              <RHFSelect
                name="province_id"
                placeholder="Seleccione una provincia"
                label="Seleccione una provincia"
                InputLabelProps={{ shrink: watch().province_id.value }}
                onChange={getCities}
                defaultValue=""
              >
                {provinces.map((province: IProvinceItem) => (
                  <MenuItem key={province?.id} value={province?.id}>
                    {province?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Ciudad</Typography>
              <RHFSelect
                name="city_id"
                placeholder="Seleccione una ciudad"
                label="Seleccione una ciudad"
                InputLabelProps={{ shrink: watch().city_id.value }}
                defaultValue=""
              >
                {Array.isArray(cities) && cities.map((city: ICityItem) => (
                  <MenuItem key={city?.id} value={city?.id}>
                    {city?.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>
            <Stack spacing={1.5} direction={{ xs: 'column', sm: 'row' }}>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Typography variant="subtitle2">Calle</Typography>
                <RHFTextField name="street" placeholder="Ingrese la calle" />
              </Box>

              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Typography variant="subtitle2">Número</Typography>
                <RHFTextField name="house_number" placeholder="Ingrese el número de calle" />
              </Box>
            </Stack>

            {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.5}>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Typography variant="subtitle2">Latitud</Typography>
                <RHFTextField name="latitude" placeholder="Ingrese la latitud de la tienda" />
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Typography variant="subtitle2">Longitud</Typography>
                <RHFTextField name="longitude" placeholder="Ingrese la longitud de la tienda" />
              </Box>
              </Stack> */}
          </Stack>
        </Card>
      </Grid>
    </>
  );
  const renderDelivery = (
    <>
      <Grid xs={12}>
        <Card>
          {!mdUp && <CardHeader title="Métodos de entrega" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Métodos de entrega</Typography>
              <Stack spacing={1.5} direction={{ xs: 'column', sm: 'row' }}>
                <RHFCheckbox name="delivery" label="Delivery" />
                <RHFCheckbox name="takeaway" label="Retiro en el local" />
              </Stack>
            </Stack>
            {watch().delivery && (
              <>
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">Zonas de entrega</Typography>
                  <RHFTextField
                    name="delivery_zone"
                    placeholder="Ingrese las zonas de entrega"
                    multiline
                    rows={6}
                  />
                </Stack>
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">Costo de envío</Typography>
                  <RHFTextField
                    name="delivery_fee"
                    placeholder="0.00"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box component="span" sx={{ color: 'text.disabled' }}>
                            $
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </>
            )}
          </Stack>
        </Card>
      </Grid>
    </>
  );
  const renderQuestions = (
    <>
      <Grid xs={12}>
        <Card>
          {!mdUp && <CardHeader title="Métodos de entrega" />}

          <div>
            <Stack component={Card} spacing={3} sx={{ p: 3, mt: 3 }}>
              <Typography variant="h4">
                Preguntas Frecuentes{' '}
                <Button variant="outlined" onClick={addFAQ}>
                  <Iconify icon="material-symbols:add" /> Agregar
                </Button>
              </Typography>
              {faqsStacks.map((faq, index) => (
                <Stack key={index} direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                  <Stack
                    spacing={1.5}
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Typography variant="subtitle2">Pregunta</Typography>
                    <RHFTextField
                      name={faq.question}
                      placeholder="Ingrese pregunta"
                      onChange={(e) => handleQuestionChange(index, e.target.value)}
                    />
                  </Stack>
                  <Stack
                    sx={{
                      width: '100%',
                    }}
                    spacing={1.5}
                  >
                    <Typography variant="subtitle2">Respuesta</Typography>

                    <RHFTextField
                      name={faq.answer}
                      placeholder="Ingrese respuesta"
                      onChange={(e) => handleAnswerChange(index, e.target.value)}
                    />
                  </Stack>
                  <Stack>
                    <Button variant="outlined" onClick={handleDeleteStack}>
                      <Iconify icon="material-symbols:delete" /> Eliminar
                    </Button>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </div>

          <Stack component={Card} spacing={3} sx={{ p: 3, mt: 3 }}>
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Preguntas</TableCell>
                      <TableCell align="left">Respuestas</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {faqsStore?.map((faq: any) => (
                      <TableRow
                        key={faq.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left">{faq.question}</TableCell>
                        <TableCell align="left">{faq.answer}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderPaymentMethods = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Metodos de Pago
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Complete los siguientes datos...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          <Stack>
            <PaymentMethodsView />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderDenyReason = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Motivos de Rechazo
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Complete los siguientes datos...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          <Stack>
            <DenyReasonView />
          </Stack>
        </Card>
      </Grid>
    </>
  );
  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentStore ? 'Crear Tienda' : 'Guardar cambios'}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stepper activeStep={activeStep} sx={{ mt: 2, mb: 3, cursor: 'pointer' }} nonLinear>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps} onClick={() => setActiveStep(index)}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Grid container spacing={3}>
        {activeStep === 0 && renderDetails}
        {activeStep === 1 && renderLocation}
        {activeStep === 2 && renderDelivery}
        {activeStep === 3 && renderQuestions}
        {false && renderActions}
      </Grid>
      <Box sx={{ display: 'flex', mt: 3 }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={() => setActiveStep(activeStep - 1)}
          sx={{ mr: 1 }}
        >
          Anterior
        </Button>
        <Box sx={{ flexGrow: 1 }} />

        {activeStep !== steps.length - 1 && (
          <Button
            variant="contained"
            size="large"
            onClick={async () => {
              let validate = false;
              switch (activeStep) {
                case 0:
                  validate = await trigger([
                    'name',
                    'schedule_text',
                    'waiting_time',
                    'store_categories',
                    'language',
                    'currency_id',
                    'order_state_workflow',
                    'welcome_message',
                    'closed_message',
                    // 'exchange_rate'
                  ]);
                  break;
                case 1:
                  validate = await trigger([
                    'country_id',
                    'city_id',
                    'province_id',
                    'street',
                    'language',
                    'house_number',
                  ]);

                  break;
                case 2:
                  validate = await trigger(['delivery', 'delivery_fee', 'delivery_zone']);

                  break;
                default:
                  break;
              }
              if (validate) {
                setActiveStep(activeStep + 1);
              } else {
                enqueueSnackbar('Revisa que todos los campos requeridos estén completos', {
                  variant: 'error',
                });
              }
            }}
          >
            Siguiente
          </Button>
        )}
        {activeStep === steps.length - 1 && (
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            loading={isSubmitting}
            sx={{ ml: 2 }}
          >
            {!currentStore ? 'Crear Tienda' : 'Guardar cambios'}
          </LoadingButton>
        )}
      </Box>
    </FormProvider>
  );
}
