import { Helmet } from 'react-helmet-async';
import { ChannelListView } from 'src/sections/channel/view';
// sections

// ----------------------------------------------------------------------

export default function ChannelListPage() {
  return (
    <>
      <Helmet>
        <title>Canales de comunicación</title>
      </Helmet>

      <ChannelListView />
    </>
  );
}
