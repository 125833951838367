import axios from 'src/utils/axios';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { ListItemText, Switch } from '@mui/material';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Typography from '@mui/material/Typography';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

type Props = {
  // row: IOrderItem;
  row: any;
  selected: boolean;
  onViewRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  handleChangeAvailability: VoidFunction;
};

export default function CategoryTableRow({
  row,
  selected,
  onViewRow,
  onSelectRow,
  onDeleteRow,
  handleChangeAvailability
}: Props) {
  const { id, name,productsCount, store, addonsVisibles, active} = row;

  const confirm = useBoolean();

  const collapse = useBoolean();

  const popover = usePopover();

  const router = useRouter();

  const renderPrimary = (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell>
        <Box>{name}</Box>
      </TableCell>
      <TableCell>
        <Box>{store?.name}</Box>
      </TableCell>
      <TableCell>
        <Label color="success">{productsCount}</Label>
      </TableCell>   
      <TableCell>
        <Label color="info">{addonsVisibles.length}</Label>
      </TableCell>  
   

      <TableCell sx={{ typography: 'caption', color: 'text.secondary' }}>
        <Switch color="primary"
          checked={active}
          onChange={handleChangeAvailability}
        />
      </TableCell>    
    
     
   
      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <Button 
          onClick={collapse.onToggle}
          
          variant="contained"
          color="primary">
        <Typography variant='body2'> {collapse.value ? 'Ocultar variantes' : 'Mostrar variantes'}</Typography>
        </Button>             
      </TableCell>
      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
  const renderSecondary = (
    <TableRow>
      <TableCell sx={{ p: 0, border: 'none' }} colSpan={8}>
        <Collapse
          in={collapse.value}
          timeout="auto"
          unmountOnExit
          sx={{ bgcolor: 'background.neutral' }}
        >
          <Stack component={Paper} sx={{ m: 1.5 }}>
            {addonsVisibles.map((item: any) => (
              <Stack
                key={`addonsVisibles-${item.id}`}
                direction="row"
                alignItems="center"
                sx={{
                  p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
                  '&:not(:last-of-type)': {
                    borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}`,
                  },
                }}
              >
               
                <ListItemText
                  primary={item.name}
                  secondary={item.description}
                  primaryTypographyProps={{
                    typography: 'body2',
                  }}
                  secondaryTypographyProps={{
                    component: 'span',
                    color: 'text.disabled',
                    mt: 0.5,
                  }}
                />                
              </Stack>
            ))}
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  );
  const handleEdit = () => {
    router.push(paths.dashboard.category.edit(id));
  };
  return (
    <>
      {renderPrimary}
      {renderSecondary}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem onClick={handleEdit}>
          <Iconify icon="solar:pen-bold" />
          Editar
        </MenuItem>
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Eliminar
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Eliminar Categoría"
        content="Estas seguro que deseas eliminar esta categoría?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Eliminar
          </Button>
        }
      />
    </>
  );
}
