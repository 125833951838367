import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { useEffect, useState } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
// components
import { useParams } from 'src/routes/hook';
import { useSettingsContext } from 'src/components/settings';
import CategoryNewEditForm from '../category-new-edit-form';
//

// ----------------------------------------------------------------------

export default function CategoryEditView() {
  const settings = useSettingsContext();

  const  [currentCategory, setcurrentCategory] = useState(undefined);
  const params = useParams();

  const { id } = params;
  
  useEffect(() => {
    axios.get(API_ENDPOINTS.category.details(id || '')).then(({data}) => {
      setcurrentCategory(data);
    })
  }, [id])
  

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CategoryNewEditForm currentCategory={currentCategory} />
    </Container>
  );
}
