import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { useEffect, useState } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
// components
import { useParams } from 'src/routes/hook';
import { useSettingsContext } from 'src/components/settings';
import StoreSettingsForm from '../store-settings-form';
//

// ----------------------------------------------------------------------

export default function StoreSettingsView() {
  const settings = useSettingsContext();

  const  [currentStore, setcurrentStore] = useState();
  const params = useParams();

  const { id } = params;
  
  useEffect(() => {
    axios.get(API_ENDPOINTS.store.details(id || '')).then(({data}) => {      
      setcurrentStore(data);
    })
  }, [id])
  

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      {currentStore && <StoreSettingsForm currentStore={currentStore} />}
    </Container>
  );
}
