import { useEffect, useCallback, useState, useMemo } from 'react';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
// @mui
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import useStores from 'src/hooks/use-stores';
import { IStorePaymentWireTransfer, IStorePaymentLinkButton, IStoreType } from 'src/types/store';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'src/components/snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useRouter } from 'src/routes/hook/use-router';
// routes
import { paths } from 'src/routes/paths';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
  RHFMultiSelect,
} from 'src/components/hook-form';

import { useForm } from 'react-hook-form';

import Scrollbar from 'src/components/scrollbar';

// Components
import { useSettingsContext } from 'src/components/settings';

// button
import Button from '@mui/material/Button';
import Iconify from 'src/components/iconify';

import DialogContent from '@mui/material/DialogContent';

type Props = {
  currentPaymentWireStore?: IStorePaymentWireTransfer;
  currentPaymentLinkStore?: IStorePaymentLinkButton;
};

type FormValuesProps = any;

// ----------------------------------------------------------------------
export default function PaymentStoreEditForm({
  currentPaymentWireStore,
  currentPaymentLinkStore,
}: any) {
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const [stores] = useStores();

  const defaultValues = useMemo(() => {
    if (currentPaymentWireStore) {
      return {
        id: currentPaymentWireStore?.id || '',
        active: currentPaymentWireStore?.active || '',
        cbu: currentPaymentWireStore?.cbu || '',
        enterprise: currentPaymentWireStore?.enterprise || '',
        name: currentPaymentWireStore?.name || '',
        store: currentPaymentWireStore?.store?.id || '',
      };
    }
    return {
      id: currentPaymentLinkStore?.id || '',
      active: currentPaymentLinkStore?.active || '',
      link: currentPaymentLinkStore?.link || '',
      enterprise: currentPaymentLinkStore?.enterprise || '',
      name: currentPaymentLinkStore?.name || '',
      store: currentPaymentLinkStore?.store?.id || '',
    };
  }, [currentPaymentLinkStore, currentPaymentWireStore]);

  const methods = useForm<FormValuesProps>({
    defaultValues,
    mode: 'onBlur',
  });

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (currentPaymentLinkStore) {
      reset(defaultValues);
    }
    reset(defaultValues);
  }, [currentPaymentLinkStore, currentPaymentWireStore, defaultValues, reset]);

  const onSubmit = useCallback(
    async (params: any) => {
      console.log(params);

      if (currentPaymentLinkStore) {
        const aux = {
          link: params.link,
          enterprise: params.enterprise,
        };

        axios
          .put(API_ENDPOINTS.paymentMethods.update_payment_methods_link_button(params.id), aux)
          .then(({ data }) => {
            enqueueSnackbar('Metodo de pago actualizado con éxito');
            router.push(paths.dashboard.store.details(currentPaymentWireStore?.store?.id))
            // router.push(paths.dashboard.paymentmethod.root);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const aux = {
          cbu: params.cbu,
          enterprise: params.enterprise,
        };
        axios
          .put(API_ENDPOINTS.paymentMethods.update_payment_methods_wire_transfer(params.id), aux)
          .then(({ data }) => {
            enqueueSnackbar('Metodo de pago actualizado con éxito');
            router.push(paths.dashboard.store.details(currentPaymentWireStore?.store?.id))
            // router.push(paths.dashboard.paymentmethod.root);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    [enqueueSnackbar, router, currentPaymentLinkStore, currentPaymentWireStore]
  );

  

  if (currentPaymentWireStore) {
    return (
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Editar Metodo de Pago</DialogTitle>
        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)',
            }}
          >
            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFTextField name="cbu" label="Digite su número de cuenta" />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFTextField name="enterprise" label="Nombre de la empresa" />
            </Stack>
            <RHFSelect
              label="Tienda"
              name="store"
              placeholder="Seleccione una tienda"
              InputLabelProps={{ shrink: true }}
              defaultValue=""
            >
              <MenuItem key="default-c" value="">
                Seleccione una Tienda
              </MenuItem>
              {stores.map((store: IStoreType) => (
                <MenuItem key={store?.id} value={store?.id}>
                  {store?.name}
                </MenuItem>
              ))}
            </RHFSelect>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined">Cancelar</Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Actualizar
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    );
  }
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Editar Metodo de Pago</DialogTitle>
      <DialogContent>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)',
          }}
        >
          <Box sx={{ display: { xs: 'none', sm: 'block' } }} />

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <RHFTextField name="link" label="Digite su número de cuenta" />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <RHFTextField name="enterprise" label="Nombre de la empresa" />
          </Stack>
          <RHFSelect
            label="Tienda"
            name="store"
            placeholder="Seleccione una tienda"
            InputLabelProps={{ shrink: true }}
            defaultValue=""
          >
            <MenuItem key="default-c" value="">
              Seleccione una Tienda
            </MenuItem>
            {stores.map((store: IStoreType) => (
              <MenuItem key={store?.id} value={store?.id}>
                {store?.name}
              </MenuItem>
            ))}
          </RHFSelect>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined">Cancelar</Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Actualizar
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
