import axios from 'src/utils/axios';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Typography from '@mui/material/Typography';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import { ButtonBase } from '@mui/material';
import ChannelQuickInstanceForm from './channel-quick-instance-form';

// ----------------------------------------------------------------------

type Props = {
  // row: IOrderItem;
  row: any;
  onCreateInstance: VoidFunction;
};

export default function CategoryTableRow({
  row,
  onCreateInstance
}: Props) {
  const {name, basePath } = row;

  const confirm = useBoolean();

  const collapse = useBoolean();

  const popover = usePopover();

  const router = useRouter();
  const quickEdit = useBoolean();

  const renderPrimary = (
    <TableRow hover>
      <TableCell>
        <Box>{name}</Box>
      </TableCell>
      <TableCell>
      <Button variant="contained" onClick={quickEdit.onTrue}>
            Crear nueva instancia
          </Button>
      </TableCell>
    </TableRow>
  );
  return (
    <>
      {renderPrimary}
      <ChannelQuickInstanceForm channel={row.name} open={quickEdit.value} usernameType={row.username_type} onClose={quickEdit.onFalse} basePath={row.base_path} />

    </>
  );
}
