import { Helmet } from 'react-helmet-async';
// sections
import { StoreCategoryEditView } from 'src/sections/store-category/view';

// ----------------------------------------------------------------------

export default function StoreCategoryEditPage() {
  return (
    <>
      <Helmet>
        <title> Editar Tipo de tienda </title>
      </Helmet>

      <StoreCategoryEditView />
    </>
  );
}
