import { Helmet } from 'react-helmet-async';
import { InstanceListView } from 'src/sections/instance/view';
// sections

// ----------------------------------------------------------------------

export default function InstanceListPage() {
  return (
    <>
      <Helmet>
        <title>Instancias</title>
      </Helmet>

      <InstanceListView />
    </>
  );
}
