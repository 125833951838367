import { alpha, useTheme } from '@mui/material/styles';
import { Box, Link, Stack, Tooltip, Typography, Container } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
import { useResponsive } from 'src/hooks/use-responsive';
import Logo from 'src/components/logo';

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children, image, title }: Props) {
  const { method } = useAuthContext();
  const theme = useTheme();
  const upMd = useResponsive('up', 'md');
  
  return (
    <Container maxWidth={false} sx={{ display: 'flex', minHeight: '100vh', backgroundColor: 'whitesmoke' }}>
      <Stack
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: { xs: 3, md: 5 },
          width: upMd ? '50%' : '100%',
        }}
      >
        <Logo sx={{ width: 240, height: 'auto', mb: 5 }} />
        <Stack sx={{ width: 1, mx: 'auto', maxWidth: 480 }}>
          {children}
        </Stack>
      </Stack>
    </Container>
  );
}
