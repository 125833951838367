import React, { useEffect, useState } from 'react'
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const useChannelStates = () => {
    const [states, setStates] = useState([]);
    useEffect(() => {
        axios
        .get(API_ENDPOINTS.channel.states)
        .then(({data}) => {
            
            setStates(data);
        })
        .catch((error) => {
          
        })
    }, [])
    return [states];
}
export default useChannelStates;