import { Helmet } from 'react-helmet-async';
import OrderListAllView from 'src/sections/order/order-list-all-view';

// ----------------------------------------------------------------------

export default function OrderListAllPage() {
  return (
    <>
      <Helmet>
        <title> Todos tus pedidos </title>
      </Helmet>

      <OrderListAllView />
    </>
  );
}
