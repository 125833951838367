import { useCallback, useEffect, useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
// types
import { IProductTableFilterValue } from 'src/types/product';
// components
import { Button } from '@mui/material';
import { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

type Props = {
  filters: any;
  onFilters: (name: string, value: IProductTableFilterValue) => void;
  storeOptions: any[];
  onAddNewPaymentMethod: VoidFunction;
};

export default function MethodsTableToolbar({
  filters,
  onFilters,
  storeOptions,
  onAddNewPaymentMethod,
}: Props) {
  const popover = usePopover();
  const [methods, setMethods] = useState<any[]>([]);
  const [selectedMethods, setselectedMethods] = useState<any>({ id: '', name: '', base_path: '' });
  useEffect(() => {
    getMethodsList();
  }, []);
  useEffect(() => {
    if (methods && methods.length === 1) {
      setselectedMethods(methods[0]);
    }
  }, [methods]);
  const getMethodsList = () => {
    axios
      .get(API_ENDPOINTS.paymentMethods.list_payment_methods)
      .then(({ data }) => {
        setMethods(data);
      })
      .catch((error) => {});
  };
  const handleFilterStore = useCallback(
    (event: any) => {
      onFilters(
        'store',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );
  const changeMethods = (event: any) => {
    const NAME = event.target.value;
    const METHODS = methods.find((item) => item.name === NAME);
    setselectedMethods(METHODS);
  };

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
      >
        <Stack
          spacing={2}
          direction="column"
          alignItems="start"
          justifyContent="space-between"
          sx={{ mb: 3 }}
        >
          <Button
            variant="contained"
            onClick={onAddNewPaymentMethod}
            endIcon={<Iconify icon="material-symbols:add" />}
          >
            Agregar método de pago
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
