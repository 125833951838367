import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// routes
// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// types
import { IUserItem } from 'src/types/user';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { CustomFile } from 'src/components/upload';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
  RHFMultiSelect,
} from 'src/components/hook-form';
import { IStoreItem, IStoreType } from 'src/types/store';
import useStores from 'src/hooks/use-stores';
import useRoles from 'src/hooks/use-roles';
import useUserStates from 'src/hooks/use-user-states';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { Checkbox, InputAdornment, Stack, Typography } from '@mui/material';
import { useRouter } from 'src/routes/hook/use-router';
import { paths } from 'src/routes/paths';
import useLocales from 'src/locales/use-locales';
import { useResponsive } from 'src/hooks/use-responsive';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  store: IStoreItem;
  currentDenyReason: any;
};

export default function DenyReasonQuickInstanceForm({
  open,
  onClose,
  store,
  currentDenyReason
}: Props) {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');
  
  const { enqueueSnackbar } = useSnackbar();
  
  const NewDenyReasonSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
  });
  const defaultValues = useMemo(
    () => ({
      name: currentDenyReason?.name || '',
      store: store.id,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDenyReason]
  );
  const methods = useForm<any>({
    resolver: yupResolver(NewDenyReasonSchema),
    defaultValues,
    mode: 'onBlur',
  });
  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  useEffect(() => {
    if (currentDenyReason) {
      reset(defaultValues);
    }
  }, [currentDenyReason, defaultValues, reset]);

  const editDenyReason = useCallback(
    async (id: string, params: any) => {
      const {name} = params;
      const DATA = {
        name,
        store: parseInt(store.id, 10),
      }
      axios
    .put(API_ENDPOINTS.denyReason.edit(id), DATA)
    .then(({data}) => {
      enqueueSnackbar('Motivo de rechazo editado con éxito');
      reset();
      onClose();
      // router.push(paths.dashboard.store.details(store.id))
      // router.push(paths.dashboard.denyReason.root);
    })
    .catch((error) => {
      
    })
    },
    [enqueueSnackbar, reset, store, onClose],
  ); 
  const createDenyReason= useCallback(
    async (params: any) => {
      const {name} = params;
      const DATA = {
        name,
        store: parseInt(store.id, 10),
      }
      axios
      .post(API_ENDPOINTS.denyReason.create, DATA)
      .then(({data}) => {
        
        enqueueSnackbar('Motivo de rechazo creado con éxito');
        reset();
        onClose();
        // router.push(paths.dashboard.store.details(store))
        // router.push(paths.dashboard.denyReason.root);
      })
      .catch((error) => {
        
      })
    },
    [enqueueSnackbar, reset, store, onClose],
  );

  const onSubmit = useCallback(
    async (params: any) => {
      if (currentDenyReason) {
        editDenyReason(currentDenyReason.id, params) 
      } else {
        createDenyReason(params);
      }
    },
    [currentDenyReason, editDenyReason, createDenyReason]
  );
  
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{currentDenyReason ? 'Editar motivo de rechazo' : 'Agregar motivo de rechazo'}</DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)',
            }}
          >
            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Motivo de rechazo</Typography>
              <RHFTextField name="name" placeholder="Ingrese un motivo de rechazo" />
            </Stack>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => {
            onClose()
            }}>
            Cancelar
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {currentDenyReason ? 'Guardar cambios': 'Agregar'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
