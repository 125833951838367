import Container from '@mui/material/Container';

import { Helmet } from 'react-helmet-async';

// ----------------------------------------------------------------------

export default function PolicyPage() {
  return (
    <>
      <Helmet>
        <title> Políticas de privacidad </title>
      </Helmet>
      <Container
      sx={{
        m: 0,
        pt: 10,
        height: '100%',
        width: '100%',
      }}
    >
      
    <iframe src='./assets/pdf/Políticas de privacidad VICI.pdf' title='Politicas de privacidad' width='100%' height='100%' style={{'margin' : 'auto'}}/>
      </Container>
    </>
  );
}
