import { Helmet } from 'react-helmet-async';
// sections

import { StoreSettingsView } from 'src/sections/store/view';

// ----------------------------------------------------------------------

export default function StoreSettingsPage() {
  return (
    <>
      <Helmet>
        <title> Configuración de tienda </title>
      </Helmet>

      <StoreSettingsView />
    </>
  );
}
