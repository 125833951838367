import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/system/Stack';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { useResponsive } from 'src/hooks/use-responsive';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import { ICategoryItem } from 'src/types/category';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import * as Yup from 'yup';

type Props = {
    currentCategory?: ICategoryItem;
  };
type FormValuesProps = any;

export default function CategoryNewEditForm({ currentCategory }: Props) {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');
  
  const { enqueueSnackbar } = useSnackbar();
  
  const NewCategorySchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    description: Yup.string().required('La descripción es requerida'),
  });
  const defaultValues = useMemo(
    () => ({
      name: currentCategory?.name || '',
      description: currentCategory?.description || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCategory]
  );
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewCategorySchema),
    defaultValues,
    mode: 'onBlur',
  });
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;
  
  useEffect(() => {
    if (currentCategory) {
      reset(defaultValues);
    }
  }, [currentCategory, defaultValues, reset]);

  const editCategory = useCallback(
    async (id: string, params: FormValuesProps) => {
      const {name, description} = params;
      const DATA = {
        name, description
      }
      axios
    .put(API_ENDPOINTS.storeCategory.edit(id), DATA)
    .then(({data}) => {
      
      enqueueSnackbar('Categoría de comercio editada con éxito');
      reset();
      router.push(paths.dashboard.storeCategory.root);
    })
    .catch((error) => {
      
    })
    },
    [enqueueSnackbar, reset, router],
  ); 
  const createCategory = useCallback(
    async (params: FormValuesProps) => {
      const {name, description} = params;
      const DATA = {
        name, description
      }
      axios
      .post(API_ENDPOINTS.storeCategory.create, DATA)
      .then(({data}) => {
        
        enqueueSnackbar('Categoría de comercio creada con éxito');
        reset();
        router.push(paths.dashboard.storeCategory.root);
      })
      .catch((error) => {
        
      })
    },
    [enqueueSnackbar, reset, router],
  );

  const onSubmit = useCallback(
    async (params: FormValuesProps) => {
      if (currentCategory) {
        editCategory(currentCategory.id, params) 
      } else {
        createCategory(params);
      }
    },
    [currentCategory, editCategory, createCategory]
  );
 
  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Tipo de tienda
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
           Complete los siguientes datos...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Detalle" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Nombre</Typography>
              <RHFTextField name="name" placeholder="Ingrese el nombre de la categoría del comercio" />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Descripción</Typography>
              <RHFTextField
                name="description"
                placeholder="Ingrese una descripción de la categoría del comercio"
                multiline
                rows={4}
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
      
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentCategory ? 'Crear Categoría de comercio' : 'Guardar cambios'}
        </LoadingButton>
      </Grid>
    </>
  );
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
