import { useEffect, useState } from 'react';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import useHasRoles from './use-has-roles';

const useConversationStatus = () => {
  
    const [conversationStatus, setConversationStatus] = useState<any[]>([]);
    const isAdmin = useHasRoles(['ROLE_ADMIN']);

    useEffect(() => {
      if (isAdmin){
        axios
        .get(API_ENDPOINTS.conversation.qualifications)
        .then(({data}) => {
          setConversationStatus(data);
        })
        .catch((error) => {
          
        })
      }
    }, [isAdmin])
    return [conversationStatus];
}
export default useConversationStatus;