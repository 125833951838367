import React, { useEffect, useRef } from 'react';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Iconify from 'src/components/iconify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Importa el ícono de éxito
import { Backdrop, Card } from '@mui/material';
import Label from 'src/components/label';
import Typography from 'src/theme/overrides/components/typography';
import { useSettingsContext } from 'src/components/settings/context';
import { format } from 'date-fns';
import OrderItem from './order-item';
import OrderItemModal from './order-item-modal';


type Props = {
  order: any;
  closeModal: () => void;
};

export default function OrderNewModal({ order, closeModal }: Props) {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const settings = useSettingsContext();

  useEffect(() => {
    const currentAudioRef = audioRef.current;
    if (settings.notifications === 'on'){
      if (currentAudioRef) {
        currentAudioRef.src = '/sounds/new_order_sound.mp3';
        currentAudioRef.play().then((val) => {
          console.log(val);
        }).catch((err) => {console.log(err)})
      }
    }
 
    return () => {
      if (currentAudioRef) {
        currentAudioRef.pause();
        currentAudioRef.currentTime = 0;
      }
    };
  }, [audioRef, settings.notifications]);

  const handleClose = () => {
    if (audioRef.current) {
      audioRef.current.pause(); // Pausar la reproducción al cerrar el modal
      audioRef.current.currentTime = 0; // Reiniciar el tiempo de reproducción
    }
    closeModal(); // Llama a la función closeModal si está definida
  };

  const handleView = () => {
    if (audioRef.current) {
      audioRef.current.pause(); // Pausar la reproducción al cerrar el modal
      audioRef.current.currentTime = 0; // Reiniciar el tiempo de reproducción
    }
    closeModal(); // Llama a la función closeModal si está definida
  };
  

  return (
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open
    onClick={handleClose}
    >
      <Stack
        direction="column"
        spacing={1}
        p={3}
        sx={{
          backgroundColor: 'white',
          borderRadius: 2,
          boxShadow: 3,
          minWidth: 300,
          position: 'relative', 
        }}
      >
        <Stack
      direction="row"
      alignItems="end"
      textAlign="end"
       justifyContent="end"
    >
      <IconButton onClick={handleClose}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
     
      <OrderItemModal order={order} onView={handleView} onEdit={() => {}} onDelete={() => {}} onClose={handleClose} />
     

      </Stack>

     
       <audio ref={audioRef}>
        <track kind="captions" />
      </audio>
    </Backdrop>
    
  );
}
