import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// utils
import { fCurrency } from 'src/utils/format-number';
// types
import { IOrderItem } from 'src/types/order';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Typography from '@mui/material/Typography';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import { useSnackbar } from 'src/components/snackbar';
import { List, ListItem, Switch, Table, TableBody, TableContainer } from '@mui/material';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom } from 'src/components/table';
import { ComboRow } from './product-addons-new-edit-form';

// ----------------------------------------------------------------------

type Props = {
  // row: IOrderItem;
  row: any;
  selected: boolean;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  onEditAddon: Function;
  handleChangeAvailability: VoidFunction;
};

export default function ProductComboTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  onEditAddon,
  handleChangeAvailability
}: Props) {
  const {id, name, comboProducts, price, min, max,description, active} = row;

 
  const confirm = useBoolean();

  const collapse = useBoolean();

  const popover = usePopover();
  
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const deleteAddon  = (idAddon: string) => {
    axios.delete(API_ENDPOINTS.productAddon.delete(idAddon)).then((val) => {
      
      enqueueSnackbar('Variante eliminada con éxito');
      router.reload();
    }).catch((err)=>{
      
    })
  }
  const handleEdit = () =>{
    onEditAddon();
    popover.onClose();
    // router.push(paths.dashboard.productAddonCategory.edit(id));
  }
  const renderPrimary = (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell>
        <Box
          onClick={handleEdit}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          {name}
        </Box>
      </TableCell>

      <TableCell>
     
      <Typography
    sx={{
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 4,
    }}
    variant="body1">

 {fCurrency(price)}
</Typography>
      </TableCell>
    
     <TableCell sx={{ typography: 'caption', color: 'text.secondary' }}>
    <Switch color="primary"   
    checked={active}
    onChange={handleChangeAvailability}
 />
    </TableCell>
      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>

        <IconButton
          color={collapse.value ? 'inherit' : 'default'}
          onClick={collapse.onToggle}
          sx={{
            ...(collapse.value && {
              bgcolor: 'action.hover',
            }),
          }}
        >
          <Iconify icon="eva:arrow-ios-downward-fill" />
        </IconButton>


        <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
const renderComboTable = () => {
    const tableLabels = [
      {
        id: 'category',
        label: 'Categoría',
      },
      {
        id: 'products',
        label: 'Productos',
      },
      {
        id: 'quantity',
        label: 'Cantidad',
      }
    ];
    return (
      <>
      <TableContainer sx={{ overflow: 'scroll', maxHeight: 440 }}>
       
          <Scrollbar>
            <Table>
              <TableHeadCustom headLabel={tableLabels} />

              <TableBody>
                {comboProducts.map((combo: any, index: number) => (
                  <ComboRow
                    key={`combo-${index}`}
                    row={combo}
                    handleOnDeleteRow={null}
                  />
                ))}                
              </TableBody>
            </Table>
          </Scrollbar>
        
        </TableContainer>
      </>
    );
  };
  const renderSecondary = (
    <>
      <TableRow>
      <TableCell sx={{ p: 0, border: 'none' }} colSpan={8}>
        <Collapse
          in={collapse.value}
          timeout="auto"
          unmountOnExit
          sx={{ bgcolor: 'background.neutral' }}
        >
          
         {renderComboTable()}
          
        </Collapse>
      </TableCell>
    </TableRow>
   </>
  );

  return (
    <>
      {renderPrimary}
      {renderSecondary}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem onClick={handleEdit}>
          <Iconify icon="solar:pen-bold" />
          Editar
        </MenuItem>
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Eliminar
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Eliminar Combo"
        content="Estás seguro que deseas eliminar este combo?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Eliminar
          </Button>
        }
      />
    </>
  );
}
