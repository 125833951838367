import { Helmet } from 'react-helmet-async';
import ProductAddonsEditView from 'src/sections/product-addons/view/product-addons-edit-view';

// ----------------------------------------------------------------------

export default function ProductAddonsEditPage() {
  return (
    <>
      <Helmet>
        <title> Editar variante </title>
      </Helmet>

      <ProductAddonsEditView />
    </>
  );
}
