import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/system/Stack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import FormProvider, { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { useResponsive } from 'src/hooks/use-responsive';
import { RootState } from 'src/redux/store';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import { IProductAddonCategoryForm } from 'src/types/product';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import * as Yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import { IStoreItem } from 'src/types/store';
import useStores from 'src/hooks/use-stores';
import useHasRoles from 'src/hooks/use-has-roles';
import ProductSearchMultiple from 'src/components/product-search/product-search-multiple';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import CategorySearchMultiple from 'src/components/category-search/category-search-multiple';
import CategorySearch from 'src/components/category-search/category-search';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TableNoData } from 'src/components/table';
import { useForceUpdate } from 'framer-motion';
import Iconify from 'src/components/iconify';

type Props = {
  currentCombo?: any;
};
type FormValuesProps = any;

export default function ProductAddonsNewEditForm({ currentCombo }: Props) {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');
  const stateStore: any = useSelector((state: RootState) => state.store);
  const isAdmin = useHasRoles(['ROLE_ADMIN']);
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [allProducts, setAllProducts] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [comboQuantity, setComboQuantity] = useState(1);
  const [comboData, setComboData] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const NewPromptSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    price: Yup.string().required('El precio es requerido'),
  });
  const defaultValues = useMemo(
    () => ({
      name: currentCombo?.name || '',
      store: stateStore.id || '',
      price: currentCombo?.price || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCombo]
  );
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewPromptSchema),
    defaultValues,
    mode: 'onBlur',
  });
  const {
    reset,
    control,
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (currentCombo) {
      reset(defaultValues);
      setComboData(currentCombo?.comboProducts);
    }
  }, [currentCombo, defaultValues, reset]);
  useEffect(() => {
    setSelectedProducts([]);
    setSelectedStore(stateStore?.id);
  }, [stateStore]);

  const editCombo = useCallback(
    async (id: string, params: FormValuesProps) => {
      const { name, price } = params;
      
      const COMBO = comboData.map((combo: any) => (
        {
          category: combo.category.id,          
          quantity: parseInt(combo.quantity, 10),
          products: combo.products.map((product: any) => product.id),      
        }
      ));
      const PARAMS = {
        name,
        price,
        store: parseInt(isAdmin ? selectedStore : stateStore.id, 10),
        comboProducts: COMBO,
      };
      axios
        .put(API_ENDPOINTS.productCombo.edit(id), PARAMS)
        .then(({ data }) => {
          enqueueSnackbar('Combo editado con éxito');
          reset();
          router.push(paths.dashboard.productCombos.root);
        })
        .catch((error) => {});
    },
    [enqueueSnackbar, reset, router, isAdmin, stateStore.id, selectedStore, comboData]
  );
  const createCombo = useCallback(
    async (params: FormValuesProps) => {

      const { name, store, price } = params;
  
      const COMBO = comboData.map((combo: any) => (
        {
          category: combo.category.id,          
          quantity: parseInt(combo.quantity, 10),
          products: combo.products.map((product: any) => product.id),      
        }
      ));

      const PARAMS = {
        name,
        store,
        comboProducts: COMBO,
        price: parseFloat(price),
      };            
      axios
        .post(API_ENDPOINTS.productCombo.create, PARAMS)
        .then(({ data }) => {
          enqueueSnackbar('Combo de productos creado con éxito');
          reset();
          router.push(paths.dashboard.productCombos.root);
        })
        .catch((error) => {});
    },
    [enqueueSnackbar, reset, router, comboData]
  );

  const onSubmit = useCallback(
    async (params: FormValuesProps) => {
      if (comboData.length === 0) {
        enqueueSnackbar('Tienes que agregar productos al combo', { variant: 'error' });
        return;
      }
      if (currentCombo) {
        editCombo(currentCombo.id, params);
      } else {
        createCombo(params);
      }
    },
    [currentCombo, editCombo, createCombo, comboData, enqueueSnackbar]
  );
  const handleFilterProduct = useCallback((products: any) => {
    setSelectedProducts(products);
  }, []);
  const handleFilterCategory = useCallback((category: any) => {
    setSelectedCategory(category);
  }, []);
  // Función para manejar cambios en el checkbox
  const handleCheckboxChange = (event: any) => {
    if (event.target.checked !== undefined) {
      setAllProducts(event.target.checked); // Actualiza el estado cuando el checkbox cambia
    }
  };
  const handleQuantityChange = (event: any) => {
    setComboQuantity(event.target.value); // Actualiza el estado cuando la cantidad cambia
  };
  useEffect(() => {
    if (resetForm) {
      setComboQuantity(1);
      setAllProducts(false);
      setSelectedCategory(null);
      setSelectedProducts([]);
      setResetForm(false);
    }
  }, [resetForm]);

  const addComboRow = () => {   
    if (!allProducts && selectedProducts.length === 0) {
      enqueueSnackbar('Tienes que elegir al menos un producto', { variant: 'error' });
      return;
    }
    const combo = [
      {
        category: selectedCategory,
        products: selectedProducts,
        quantity: comboQuantity,
        allProducts,
      },
    ];

    setComboData((prevData) => [...prevData, ...combo]);
    setResetForm(true);
  };

  const renderComboForm = (
    <>
      <Card>
        <Stack spacing={3} sx={{ p: 3 }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Box sx={{ flexGrow: 1 }}>
              <CategorySearch
                storeId={selectedStore}
                onChange={handleFilterCategory}
                selectedCategory={selectedCategory}
              />
            </Box>
            {selectedCategory && (
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">
                  ¿Cuántos productos de esta categoría puede elegir el cliente?
                </Typography>
                <RHFTextField
                  type="tel"
                  value={comboQuantity} // Usa el estado local directamente como valor del campo
                  onChange={handleQuantityChange}
                  name="quantity"
                />
              </Stack>
            )}
          </Stack>
          {selectedCategory && (
            <Typography variant="subtitle2">
              {' '}
              <Checkbox size="medium" onChange={handleCheckboxChange} checked={allProducts} />
              {`Incluir todos los productos de la categoría ${selectedCategory?.name}`}
            </Typography>
          )}

          {!allProducts && selectedCategory && (
            <ProductSearchMultiple
              title="Seleccione los productos que incluye el combo"
              storeId={selectedStore}
              categoryId={selectedCategory?.id}
              onChange={handleFilterProduct}
            />
          )}
          <Button variant="contained" size="medium" onClick={addComboRow}>
            Añadir al combo
          </Button>
        </Stack>
      </Card>
    </>
  );
  const renderComboTable = () => {
    const tableLabels = [
      {
        id: 'category',
        label: 'Categoría',
      },
      {
        id: 'products',
        label: 'Productos',
      },
      {
        id: 'quantity',
        label: 'Cantidad',
      },
      {
        id: 'action',
        label: '',
      },
    ];
    const handleOnDeleteRow = (index: number) => {
      const newData = comboData.filter((_, i) => i !== index);
      setComboData(newData);
    };
    return (
      <Card>
        <CardHeader
          title="Combo"
          subheader="El combo está conformado de la siguiente manera"
          sx={{ mb: 3 }}
        />
        <TableContainer sx={{ overflow: 'scroll', maxHeight: 440 }}>
          <Scrollbar>
            <Table>
              <TableHeadCustom headLabel={tableLabels} />

              <TableBody>
                {comboData.map((row, index) => (
                  <ComboRow
                    key={`combo-${index}`}
                    row={row}
                    handleOnDeleteRow={() => handleOnDeleteRow(index)}
                  />
                ))}
                <TableNoData notFound={comboData.length === 0} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </Card>
    );
  };

  const renderDetails = (
    <>
      <Grid xs={12} md={7}>
        <Stack spacing={2}>
          <Card>
            {!mdUp && <CardHeader title="Detalle del combo" />}

            <Stack spacing={3} sx={{ p: 3 }}>
              <Box
                rowGap={1}
                columnGap={1}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">Nombre</Typography>
                  <RHFTextField name="name" placeholder="Ingrese el nombre del combo" />
                </Stack>
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">Precio</Typography>
                  <RHFTextField
                    name="price"
                    type="tel"
                    placeholder='0.00'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box component="span" sx={{ color: 'text.disabled' }}>
                            $
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Box>
            </Stack>
          </Card>
          {renderComboForm}
        </Stack>
      </Grid>
      <Grid xs={12} md={5}>
        {renderComboTable()}
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentCombo ? 'Crear combo' : 'Guardar cambios'}
        </LoadingButton>
      </Grid>
    </>
  );
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
export function ComboRow({ row, handleOnDeleteRow }: any) {
  const productList = row.products.map((product: any, i: any) => (
    <ListItem key={i} sx={{p:0}}>
      <ListItemText primary={product?.name} primaryTypographyProps={{ typography: 'subtitle' }} />
    </ListItem>
  ));
  const renderList = () => <List dense>{productList}</List>;
  return (
    <TableRow hover>
      <TableCell align="left">{row.category.name}</TableCell>

      <TableCell align="left">
        {' '}
        {(row.allProducts || row?.products?.length === 0) ? 'Incluye todos los productos de la categoría' : renderList()}
      </TableCell>
      <TableCell align="left">{row.quantity}</TableCell>
     {handleOnDeleteRow && 
      <TableCell align="left">
        <IconButton color="error" onClick={handleOnDeleteRow}>
          <Iconify icon="solar:trash-bin-trash-bold" />
        </IconButton>
      </TableCell>
     }     
    </TableRow>
  );
}
