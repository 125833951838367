// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  terms: '/terms',
  policy: '/policy',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id: string) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `/`,
      register: `${ROOTS.AUTH}/jwt/register`,
      forgotPassword: `/forgot-password`,
      newPassword: `/new-password`,
      confirmRestore : (success:boolean, title: string, message:string) => `/confirm-restore/${success}&&${title}&&${message}`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    }
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/order`,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    conversation: `${ROOTS.DASHBOARD}/conversation`,
    playground: `${ROOTS.DASHBOARD}/playground`,
    paymentmethod: {
      root: `${ROOTS.DASHBOARD}/paymentmethod`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/paymentmethod/${id}/edit`
    },
    bannedUsers: {
      root: `${ROOTS.DASHBOARD}/banned_users`,
      list: `${ROOTS.DASHBOARD}/banned_users`,
      new: `${ROOTS.DASHBOARD}/banned_users/new`,
    },
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      all: `${ROOTS.DASHBOARD}/order/all`,
      details: (id: string) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
    prompt: {
      root: `${ROOTS.DASHBOARD}/prompt`,
      new: `${ROOTS.DASHBOARD}/prompt/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/prompt/${id}/edit`
    },
    currency: {
      root: `${ROOTS.DASHBOARD}/currency`,
      new: `${ROOTS.DASHBOARD}/currency/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/currency/${id}/edit`
    },
    country: {
      root: `${ROOTS.DASHBOARD}/country`,
      new: `${ROOTS.DASHBOARD}/country/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/country/${id}/edit`
    },
    province: {
      root: `${ROOTS.DASHBOARD}/province`,
      new: `${ROOTS.DASHBOARD}/province/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/province/${id}/edit`
    },
    city: {
      root: `${ROOTS.DASHBOARD}/city`,
      new: `${ROOTS.DASHBOARD}/city/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/city/${id}/edit`
    },
    store: {
      root: `${ROOTS.DASHBOARD}/store`,
      new: `${ROOTS.DASHBOARD}/store/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/store/${id}/edit`,
      details: (id: string) => `${ROOTS.DASHBOARD}/store/${id}`,
      detailsTabs: (id: string, tab: string) => `${ROOTS.DASHBOARD}/store/${id}/${tab}`,
      settings: (id: string) => `${ROOTS.DASHBOARD}/store/${id}/settings`,
      flowMessages: (id: string) => `${ROOTS.DASHBOARD}/store/${id}/flow-messages`,
    },
    category: {
      root: `${ROOTS.DASHBOARD}/category`,
      new: `${ROOTS.DASHBOARD}/category/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/category/${id}/edit`
    },
    denyReason: {
      root: `${ROOTS.DASHBOARD}/denyReason`,
      new: `${ROOTS.DASHBOARD}/denyReason/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/denyReason/${id}/edit`
    },
    channel: {
      root: `${ROOTS.DASHBOARD}/channel`,
    },
    instance: {
      root: `${ROOTS.DASHBOARD}/instance`,
    },
    storeCategory: {
      root: `${ROOTS.DASHBOARD}/store_categories`,
      new: `${ROOTS.DASHBOARD}/store_categories/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/store_categories/${id}/edit`
    },
    productAddonCategory: {
      root: `${ROOTS.DASHBOARD}/product_addon_categories`,
      list: `${ROOTS.DASHBOARD}/product_addon_categories`,
      details: (id: string) => `${ROOTS.DASHBOARD}/product_addon_categories/${id}`,
      new: `${ROOTS.DASHBOARD}/product_addon_categories/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/product_addon_categories/${id}/edit`
    },
    productCombos: {
      root: `${ROOTS.DASHBOARD}/product_combos`,
      list: `${ROOTS.DASHBOARD}/product_combos`,
      new: `${ROOTS.DASHBOARD}/product_combos/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/product_combos/${id}/edit`
    },
    metrics: `${ROOTS.DASHBOARD}/metrics`,
    whatsappTest: {
      root: `${ROOTS.DASHBOARD}/whatsapp_test`,
      list: `${ROOTS.DASHBOARD}/whatsapp_test`,
      users: (id: string) => `${ROOTS.DASHBOARD}/whatsapp_test/users/${id}`,
    },
    pausedUsers: {
      root: `${ROOTS.DASHBOARD}/paused_users`,
      list: `${ROOTS.DASHBOARD}/paused_users`,
      new: `${ROOTS.DASHBOARD}/paused_users/new`,
    },
  },
};
