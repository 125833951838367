import { useEffect, useState } from 'react'
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const useStoreCategories = () => {
    const [categories, setCategories] = useState<any[]>([]);
    useEffect(() => {
        axios
        .get(API_ENDPOINTS.store.category)
        .then(({data}) => {
          setCategories(data);
        })
        .catch((error) => {
          
        })
    }, [])
    return [categories];
}
export default useStoreCategories;