import { useCallback, useEffect, useState } from 'react';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
// @mui
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useSnackbar } from 'src/components/snackbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  emptyRows,
  getComparator,
  useTable,
} from 'src/components/table';
import useStores from 'src/hooks/use-stores';

import Scrollbar from 'src/components/scrollbar';

// Components
import { useSettingsContext } from 'src/components/settings';
import { useBoolean } from 'src/hooks/use-boolean';
import useLocales from 'src/locales/use-locales';

import DeliveryZoneTableRow from '../delivery-zone-table-row';
import DeliveryZonesTableToolbar from '../delivery-zone-table-toolbar';
import QuickDeliveryZoneForm from '../quick-delivery-zone-form';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre', width: 280 },
  { id: 'price', label: 'Precio', width: 280 },
  { id: 'description', label: 'Descripción', width: 280 },
  { id: '', width: 88 },
];

const defaultFilters: {
  store: string;
  status: string;
} = {
  store: '',
  status: 'all',
};

export default function DeliveryZonesView({ store,deliveryMethod, delivery_zones, updateDeliveryZones }: any) {
  const table = useTable({ defaultOrderBy: 'id' });
  const { t } = useLocales();

  const [tableData, setTableData] = useState<any[]>([]);
  const [currentDeliveryMethod, setCurrentDeliveryMethod] = useState<number>();
  const [currentDeliveryZone, setcurrentDeliveryZone] = useState<any>();

  const [filters, setFilters] = useState(defaultFilters);
  const quickEdit = useBoolean();

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  useEffect(() => {
    setTableData(delivery_zones || []);
  }, [delivery_zones]);
  useEffect(() => {
    setCurrentDeliveryMethod(deliveryMethod?.id);
  }, [deliveryMethod]);

  const { enqueueSnackbar } = useSnackbar();

  const settings = useSettingsContext();

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !!filters.store;

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: any) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);
  const handleDeleteRow = useCallback((payment_store: any) => {
    
    const {id, basePath} = payment_store; 
    axios
    .delete(API_ENDPOINTS.deliveryZone.delete(id))
    .then((val) => {
      enqueueSnackbar('Zona de entrega eliminada con éxito');
      const deleteRow = tableData.filter((row) => row.id !== payment_store.id);
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage.length);     
    })
    .catch((err) => {
      enqueueSnackbar(err?.error, {variant:'error'});
    });   
  }, [dataInPage.length, table, tableData, enqueueSnackbar]);

  const getDeliveryZonesList = (storeId: string) => {
    axios
      .get(API_ENDPOINTS.deliveryZone.listStore(storeId))
      .then(({ data }) => {        
        setTableData(data.rows);
      })
      .catch((error) => {
        
      });
  };
  const getDeliveryMethod = useCallback(() => {
    axios
      .get(API_ENDPOINTS.store.deliveryMethod(store?.id))
      .then(({ data }) => {         
        setCurrentDeliveryMethod(data?.id);
      })
      .catch((error) => {
        
      });
  },[store?.id]);
    const handleAddRow = useCallback(
      (row: any) => {
        setTableData((prevData) => [...prevData, row]);        
        if (delivery_zones.length > 0) {          
          // agrego una nueva zona de entrega
          const PARAMS = {...row, variable: currentDeliveryMethod}
          axios
          .post(API_ENDPOINTS.deliveryZone.create, PARAMS)
          .then(({ data }: any) => {
            enqueueSnackbar('Configuración guardada con éxito');
            updateDeliveryZones(row);                        
          })
          .catch((error) => {
            enqueueSnackbar(t(error?.error), { variant: 'error' });
          });
        } else {
          // creo el metodo de envio con una zona de entrega
          axios
            .put(API_ENDPOINTS.store.deliveryVariable(store?.id), { delivery_zones: [row] })
            .then(({ data }: any) => {
              enqueueSnackbar('Configuración guardada con éxito');
               updateDeliveryZones(row);              
               getDeliveryMethod();
            })
            .catch((error) => {
              enqueueSnackbar(t(error?.error), { variant: 'error' });
            });
        }
      },
      [t, store?.id,enqueueSnackbar, delivery_zones.length, currentDeliveryMethod, getDeliveryMethod, updateDeliveryZones]
    );
  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {dataFiltered.length === 0 && <DeliveryZonesTableToolbar onAddNewDeliveryZone={quickEdit.onTrue} />}

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
              />

              <TableBody>
                {dataFiltered
                  .slice(
                    table.page * table.rowsPerPage,
                    table.page * table.rowsPerPage + table.rowsPerPage
                  )
                  .map((row, index) => (
                    <DeliveryZoneTableRow
                      key={index}
                      row={row}
                      selected={table.selected.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row)}
                      onEditRow={() => {
                        setcurrentDeliveryZone(row);
                        quickEdit.onTrue();
                      }}
                    />
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={dataFiltered.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />

        <QuickDeliveryZoneForm
          currentDeliveryZone={currentDeliveryZone}
          store={store}
          open={quickEdit.value}
          addDeliveryZone={(data: any) => {
            handleAddRow(data);
            quickEdit.onFalse();
          }}
          onClose={() => {
            quickEdit.onFalse();
            setcurrentDeliveryZone(null);
            getDeliveryZonesList(store.id);
          }}
        />
      </Container>
    </>
  );
}

// ---------------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: any[];
  comparator: (a: any, b: any) => number;
  filters: any;
}) {
  const stabilizedThis = inputData?.map((el, index) => [el, index] as const);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  inputData = stabilizedThis?.map((el) => el[0]);

  return inputData;
}
