// @mui
import { CardProps } from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
// theme
import { FormControl, Grid, InputAdornment, InputLabel, Select, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useCallback, useEffect, useRef, useState } from 'react';
import Iconify from 'src/components/iconify/iconify';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  action?: React.ReactNode;
  filters: any;
  onResetFilters: any;
  canReset: boolean;
  onFilters: (name: string, value: any) => void;
}

export default function StoreFilter({ action, onFilters, filters, onResetFilters,canReset, ...other }: Props) {

  const [whatsappStatus, setWhatsappStatus] = useState<boolean | null>(null);

  const [botStatus, setBotStatus] = useState<boolean | null>(null);

  const [storeName, setStoreName] = useState(filters.name);
  const timeoutIdRef = useRef<any>(undefined);

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target;
      setStoreName(value);
      clearTimeout(timeoutIdRef.current);
      if (value.length >= 3){
        timeoutIdRef.current = setTimeout(() => {
          // Aquí puedes realizar tu llamada a la API con el valor
          onFilters('name', value);
        }, 500);
      }
      if (value.length === 0){
        onFilters('name', event.target.value);      
      }
    },
    [onFilters]
  );


  useEffect(() => { 
    setWhatsappStatus(filters.whatsappStatus)
    setBotStatus(filters.botStatus)
    setStoreName(filters.name)
  }, [filters])
  


  
  const handleWhatsappStatus = useCallback(
    
    (newValue: any | null) => {
      const value = newValue.target.value;
      onFilters('whatsappStatus', value);
      
      if (value === 'connected') {
        onFilters('whatsappStatus', true);
        setWhatsappStatus(true);
      } else if (value === 'disconnected') {
        onFilters('whatsappStatus', false);
        setWhatsappStatus(false);
      } else {
        onFilters('whatsappStatus', null);
        setWhatsappStatus(null);
      }
    },
    [onFilters]
  );

  const handleBotStatus = useCallback(
    
    (newValue: any | null) => {
    
      const value = newValue.target.value;
      onFilters('botStatus', value);
      
      if (value === 'connected') {
        onFilters('botStatus', true);
        setBotStatus(true);
      } else if (value === 'disconnected') {
        onFilters('botStatus', false);
        setBotStatus(false);
      } else {
        onFilters('botStatus', null);
        setBotStatus(null);
      }
    },
    [onFilters]
  );

  return (
    <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing ={2}>
        <Grid item xs={12} md={3} sx={{ maxWidth: '100%' }}>
          <TextField
            fullWidth            
            value={storeName}
            onChange={handleFilterName}
            placeholder="Buscar tienda..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
    <Grid item xs={12} md={3} sx={{ maxWidth: '100%' }}>
      <FormControl fullWidth>
        <InputLabel>Estado de Whatsapp</InputLabel>
        <Select
          value={whatsappStatus === null ? 'all' : whatsappStatus ? 'connected' : 'disconnected'}
          onChange={handleWhatsappStatus}
        >
          <MenuItem value="all">Todos</MenuItem>
          <MenuItem value="connected">Conectado</MenuItem>
          <MenuItem value="disconnected">Desconectado</MenuItem>

        </Select>
      </FormControl>
    </Grid>
  
    <Grid item xs={12} md={3} sx={{ maxWidth: '100%' }}>
      <FormControl fullWidth>
        <InputLabel>Estado del Bot</InputLabel>
        <Select
          value={botStatus === null ? 'all' : botStatus ? 'connected' : 'disconnected'}
          onChange={handleBotStatus}
        >
          <MenuItem value="all">Todos</MenuItem>
          <MenuItem value="connected">Conectado</MenuItem>
          <MenuItem value="disconnected">Desconectado</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  
  </Grid>
  
  );
}
