import { useEffect, useCallback, useState } from 'react';
// routes
import useHasRoles from 'src/hooks/use-has-roles';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
//
import { useAuthContext } from '../hooks';


// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  jwt: paths.auth.jwt.login,
  auth0: paths.auth.auth0.login,
  amplify: paths.auth.amplify.login,
  firebase: paths.auth.firebase.login,
};

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const router = useRouter();

  const { authenticated, method } = useAuthContext();

  const [checked, setChecked] = useState(false);
  const isChangePassword = useHasRoles(['ROLE_CHANGE_PASSWORD']);

  

  const check = useCallback(() => {


    setChecked(authenticated);
    if (authenticated && isChangePassword) {
      router.replace(paths.auth.jwt.newPassword);
    } else if (!authenticated) {
      const loginPath = loginPaths[method];
      router.replace(loginPath);
    } else {
      setChecked(true);
    }
  }, [authenticated, method, router, isChangePassword]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
