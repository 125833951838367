import { useCallback, useEffect, useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// types
import { IProductTableFilters, IProductTableFilterValue } from 'src/types/product';
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { IStoreType } from 'src/types/store';
import { RHFSelect } from 'src/components/hook-form';
import { Box, Button, Typography } from '@mui/material';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import PaymentMethodsQuickInstanceForm from './quick-delivery-zone-form';

// ----------------------------------------------------------------------

type Props = {
  onAddNewDeliveryZone: VoidFunction;
};

export default function DeliveryZonesTableToolbar({
  onAddNewDeliveryZone,
}: Props) {
  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
         
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack
          spacing={2}
          direction="column"
          alignItems="start"
          justifyContent="space-between"
          sx={{ py: 2,  }}
        >
          <Button
            variant="contained"
            onClick={onAddNewDeliveryZone}
            endIcon={<Iconify icon="material-symbols:add" />}
          >
            Agregar Zona de Entrega
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
