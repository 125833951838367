import { useEffect, useState } from 'react';
import useLocales from 'src/locales/use-locales';
import useConversationStatus from 'src/hooks/use-conversation-status';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { enqueueSnackbar } from 'src/components/snackbar';
import { Badge, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

type Props = {
  conversationId: string;
  qualification: any;
  onChangeQualification?: VoidFunction;
};
const ConversationQualification = ({ conversationId, qualification, onChangeQualification }: Props) => {

  const [conversationStatus] = useConversationStatus();
  const [selectedOption, setSelectedOption] = useState('');
  const { t } = useLocales();
  useEffect(() => {
    setSelectedOption(conversationStatus.find((option) => option.name === qualification?.name) || '');
   
  }, [qualification, conversationStatus, conversationId]);

  const setQualification = (ev: any) => {
    const { base_path } = ev.target.value;
    axios
      .put(API_ENDPOINTS.conversation.setQualification(conversationId, base_path))
      .then(({ data }) => {
        setSelectedOption(ev.target.value);
        if(onChangeQualification) {
          onChangeQualification()
        }
        enqueueSnackbar('Se ha calificado la conversación');
      })
      .catch((error) => {});
  };
  const getColor = (qualificationClass: string) => {
    switch (qualificationClass) {
      case 'qualification-good':
        return 'lightGreen';
      case 'qualification-bad':
        return 'red';
      case 'qualification-regular':
        return 'darkorange';
      case 'qualification-api_closed':
        return 'grey';
      case 'qualification-testing_onboarding':
        return 'cyan';
      case 'qualification-full_commerce':
        return 'salmon';
      case 'qualification-personal':
        return 'purple';
      default:
        return 'default';
    }
  };
  return (
    <FormControl sx={{ flex: 1 }}>
      <InputLabel id="select-label">Califica la conversación</InputLabel>

      <Select
        labelId="select-label"
        onChange={setQualification}
        value={selectedOption}
        renderValue={(selected: any) => {
          const selectedQualification = conversationStatus.find(
            (option) => option.name === selected.name
          );
          return selectedQualification ? (
            <>
              <Badge
                variant="dot"
                sx={{
                  mr: 1,
                  '& .MuiBadge-badge': {
                    backgroundColor: getColor(selectedQualification.class),
                  },
                }}
              />
              {t(`qualification.${selectedQualification.name}`)}
            </>
          ) : (
            ''
          );
        }}
      >
        {conversationStatus.map((opcion: any) => (
          <MenuItem key={opcion.class} value={opcion}>
            <Badge
              variant="dot"
              sx={{
                mr: 1,
                '& .MuiBadge-badge': {
                  backgroundColor: getColor(opcion.class),
                },
              }}
            />
            {t(`qualification.${opcion.name}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default ConversationQualification;
