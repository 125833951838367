import { Helmet } from 'react-helmet-async';
import { ProductAddonsDetailsView } from 'src/sections/product-addons/view';


// ----------------------------------------------------------------------

export default function ProductAddonsDetailsPage() {
  return (
    <>
      <Helmet>
        <title> Detalle de categoría de variante</title>
      </Helmet>

      <ProductAddonsDetailsView />
    </>
  );
}
