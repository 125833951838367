import { useState, useEffect, useCallback } from 'react';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
// @mui
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
// utils
import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
// types
import { IJobItem } from 'src/types/job';
// components
import Iconify from 'src/components/iconify';
import Markdown from 'src/components/markdown';
import { IStoreItem, IStoreQuestion } from 'src/types/store';
import { scheduler } from 'timers/promises';
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import useLocales from 'src/locales/use-locales';
// Table Simple
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Scrollbar from 'src/components/scrollbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'src/components/snackbar';
import { useBoolean } from 'src/hooks/use-boolean';
import { RHFSelect } from 'src/components/hook-form';
import useHasRoles from 'src/hooks/use-has-roles';
import { PaymentMethodsView } from '../payment-methods/view';
import { DenyReasonListView } from '../deny-reason/view';

import QuestionQuickEditForm from './question-quick-edit-form';
import { DeliveryZonesView } from '../delivery-zones/view';

// hooks

// ----------------------------------------------------------------------

type Props = {
  currentStore: any;
};

export default function StoreDetailsContent({ currentStore }: Props) {
  const [dataQuestion, setData] = useState<IStoreQuestion>({ id: '', question: '', answer: '' });
  const [currentBillingPlan, setCurrentBillingPlan] = useState<any>();
  const [currentOrderMethod, setCurrentOrderMethod] = useState<boolean>();
  const [billingPlans, setBillingPlans] = useState<any[]>([]);
  const isAdmin = useHasRoles(['ROLE_ADMIN']);

  const { t } = useLocales();

  const router = useRouter();

  const {
    id,
    name,
    schedules_text,
    address,
    delivery_zone,
    waiting_time,
    prompt,
    created_at,
    delivery_fee,
    paymentMethods,
    delivery,
    takeaway,
    store_categories,
    faqs,
    billingPlan,
    hasChannelConnected,
    channelCount,
    new_method
  } = currentStore;
  const { enqueueSnackbar } = useSnackbar();

  const quickEdit = useBoolean();

  const showQuestion = useCallback(
    (id_question: string) => {
      axios
        .get(API_ENDPOINTS.store.storefaq_detail(id_question))
        .then(({ data }) => {
          setData(data);
          quickEdit.onTrue();
        })
        .catch((error) => {});
    },
    [setData, quickEdit]
  );

  const handleDeleteRow = useCallback(
    (id_question: string) => {
      axios
        .delete(API_ENDPOINTS.store.storefaq_delete(id_question))
        .then((val) => {
          enqueueSnackbar('Pregunta eliminada con éxito');
          window.location.reload();
        })
        .catch((err) => {});
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    setCurrentBillingPlan(currentStore?.billingPlan);
    setCurrentOrderMethod(currentStore?.new_method);
    if (isAdmin){
      axios
        .get(API_ENDPOINTS.billingPlan.list)
        .then(({ data }) => {
          setBillingPlans(data.rows);
        })
        .catch((error) => {
          // Handle error
        });
    }
  }, [currentStore, isAdmin]);

  const renderContent = (
    <Stack component={Card}  sx={{ p: 3 }} >
       <Stack direction="row" alignItems="center" spacing={8}>
        <Typography variant="h4">{name}</Typography>
      </Stack>
      
      <Stack spacing={2}>
        <Typography variant="h6">Categorías</Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          {store_categories &&
            store_categories.map((category: any) => (
              <Chip key={category.id} label={category?.name} variant="soft" />
            ))}
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h6">Métodos de entrega</Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          {delivery && <Chip key={`${id}-delivery`} label="Delivery" variant="soft" />}
          {takeaway && <Chip key={`${id}-takeaway`} label="Retiro en el local" variant="soft" />}
        </Stack>
      </Stack>
    </Stack>
  );

  const renderOverview = (
    <Stack component={Card} spacing={2} sx={{ p: 3 }}>
      {[
        {
          label: 'Fecha de creación',
          value: fDate(created_at),
          icon: <Iconify icon="solar:calendar-date-bold" />,
        },
        {
          label: 'Horarios de atención',
          value: schedules_text,
          icon: <Iconify icon="solar:calendar-date-bold" />,
        },
        {
          label: 'Tiempo de espera',
          value: waiting_time,
          icon: <Iconify icon="solar:clock-circle-bold" />,
        },
        {
          label: 'Costo de envío',
          value: fCurrency(delivery_fee),
          icon: <Iconify icon="solar:wad-of-money-bold" />,
        },
        {
          label: 'Zona de entrega',
          value: delivery_zone,
          icon: <Iconify icon="ic:outline-delivery-dining" />,
        }
      ].map((item) => (
        <Stack key={item.label} spacing={1.5} direction="row">
          {item.icon}
          <ListItemText
            primary={item.label}
            secondary={item.value}
            primaryTypographyProps={{
              typography: 'body2',
              color: 'text.secondary',
              mb: 0.5,
            }}
            secondaryTypographyProps={{
              typography: 'subtitle2',
              color: 'text.primary',
              component: 'span',
            }}
          />
        </Stack>
      ))}
    </Stack>
  );

  const renderCompany = (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={2}
      direction="row"
      sx={{ p: 3, borderRadius: 2, mt: 3 }}
    >
      <Stack spacing={1}>
        <Typography variant="subtitle1">{name}</Typography>
        <Typography variant="body2">
          {address?.street} {address?.houseNumber}
        </Typography>
        <Typography variant="body2">
          {address?.city?.name}, {address?.city?.province?.name},{' '}
          {address?.city?.province?.country?.name}
        </Typography>
      </Stack>
    </Stack>
  );

  const renderFaQ = (
    <Stack component={Card} spacing={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h4">Preguntas Frecuentes</Typography>

      <Stack spacing={2}>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Preguntas</TableCell>
                  <TableCell align="left">Respuestas</TableCell>
                  <TableCell align="left" colSpan={2}>
                    Opciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {faqs?.map((faq: any) => (
                  <TableRow key={faq.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left">{faq.question}</TableCell>
                    <TableCell align="left">{faq.answer}</TableCell>
                    <TableCell align="left">
                      <Tooltip title="Editar">
                        <IconButton
                          color={quickEdit.value ? 'inherit' : 'default'}
                          onClick={() => {
                            showQuestion(faq.id);
                          }}
                        >
                          <Iconify icon="solar:pen-bold" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Eliminar"
                        onClick={() => {
                          handleDeleteRow(faq.id);
                        }}
                      >
                        <IconButton color="error">
                          <Iconify icon="solar:trash-bin-trash-bold" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <QuestionQuickEditForm
          currentQuestion={dataQuestion}
          open={quickEdit.value}
          onClose={quickEdit.onFalse}
        />
      </Stack>
    </Stack>
  );
  const renderDeliveryZones = <DeliveryZonesView store={currentStore} />;
  const renderPaymentMethodsForm = <PaymentMethodsView store={currentStore} />;
  const renderRejectForm = <DenyReasonListView store={currentStore} />;
  const editStore = () => {
    router.push(paths.dashboard.store.edit(id));
  };
  const settingsStore = () => {
    router.push(paths.dashboard.store.settings(id));
  };
  const flowMessagesStore = () => {
    router.push(paths.dashboard.store.flowMessages(id));
  };

  const handleBillingPlan = useCallback(
    (event: any) => {          
      const BILLINGPLAN= billingPlans.find((item) => item.id === event.target.value);      
      setCurrentBillingPlan(BILLINGPLAN);
      axios.put(API_ENDPOINTS.billingPlan.update(currentStore.id, event.target.value)).then((val) => {        
        enqueueSnackbar('Plan de pago actualizado');
      }).catch((err)=> {
        console.log(err);
      })
    },
    [currentStore.id, enqueueSnackbar, billingPlans]
  );
  const handleOrdersMethod = useCallback(
    () => {                
      setCurrentOrderMethod((prevValue) => !prevValue);
      axios.put(API_ENDPOINTS.store.method(currentStore.id)).then((val) => {        
        enqueueSnackbar('Método de ingreso de pedidos actualizado');
      }).catch((err)=> {
        console.log(err);
      })
    },
    [currentStore.id, enqueueSnackbar]
  );
  return (
    <>
      <Grid container spacing={3} sx={{ p: 3 }}>
      <Grid xs={12} md={12}>
    <Stack direction='column' spacing={3} justifyContent="space-between">
    
      {isAdmin && <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={2}>
       {billingPlans && (
       <Stack        
          sx={
            {
               width: 300,             
            }
          }          
        >
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 1 },
            }}
          >
            <InputLabel>Plan de pago</InputLabel>
            <Select            
              value={parseInt(currentBillingPlan?.id, 10) || ''}
              onChange={handleBillingPlan}
              input={<OutlinedInput label="Plan de pago" />}
              renderValue={(selected) =>
                billingPlans.find((item: any) => item?.id === selected)?.name
              }
              sx={{ textTransform: 'capitalize' }}
            >
              {billingPlans.length>0 &&
                billingPlans.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    <Checkbox
                      disableRipple
                      size="small"
                      checked={currentBillingPlan?.id === option.id}
                    />
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>)}

       <Stack        
          sx={
            {
               width: 300,             
            }
          }          
        >
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 1 },
            }}
          >
            <InputLabel>Método de ingreso de pedidos</InputLabel>
            <Select            
              value={currentOrderMethod || false}
              onChange={handleOrdersMethod}
              input={<OutlinedInput label="Método de ingreso de pedidos" />}
              renderValue={(selected) =>
                currentOrderMethod ? 'Por Resumen' : 'Por Producto'
              }
              sx={{ textTransform: 'capitalize' }}
            >
              <MenuItem key='product' value={false as any}>
                    <Checkbox
                      disableRipple
                      size="small"
                      checked={!currentOrderMethod}
                    />
                    Por Producto
                  </MenuItem>
              <MenuItem key='summary' value={true as any}>
                    <Checkbox
                      disableRipple
                      size="small"
                      checked={currentOrderMethod}
                    />
                    Por Resumen
                  </MenuItem>
                  
            </Select>
          </FormControl>
        </Stack>
      </Stack>} 
      
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} >
     
        <Button         
          variant="contained"
          endIcon={<Iconify icon="solar:pen-bold" />}
          onClick={editStore}
          
        >
          Editar datos del comercio
        </Button>
        <Button
        
          variant="contained"          
          endIcon={<Iconify icon="material-symbols:restaurant-menu" />}
          onClick={settingsStore}
        >
          Carga tu propio Menú
        </Button>
        <Button
          
          variant="contained"          
          endIcon={<Iconify icon="lucide:bot-message-square" />}
          onClick={flowMessagesStore}
        >
          Configurar mensajes del bot
        </Button>
      </Stack>
    </Stack>
</Grid>
        <Grid xs={12} md={8}>
          {renderContent}

          {renderFaQ}
        </Grid>

        <Grid xs={12} md={4}>
          {renderOverview}

          {renderCompany}
        </Grid>
      </Grid>
      <Stack direction="column" spacing={3}>
        {renderPaymentMethodsForm}
        {renderRejectForm}
        {/* renderDeliveryZones */}
      </Stack>
    </>
  );
}
