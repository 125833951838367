import Container from '@mui/material/Container';

import { Helmet } from 'react-helmet-async';

// ----------------------------------------------------------------------

export default function TermsPage() {
  return (
    <>
      <Helmet>
        <title> Términos y Condiciones </title>
      </Helmet>
      <Container
      sx={{
        m: 0,
        pt: 10,
        height: '100%',
        width: '100%',
      }}
    >
    <iframe src='./assets/pdf/Términos y Condiciones VICI.pdf' title='Términos y condiciones' width='100%' height='100%' style={{'paddingTop': 0}}/>
      </Container>
    </>
  );
}
