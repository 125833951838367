import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notification: '',
    instanceState: '',
    conversationUpdate: '',
    newOrder: ''
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotificationQR: (state, action) => {
            state.notification = action.payload
        },
        setNotificationInstanceUpdate: (state, action) => {
            state.instanceState = action.payload
        },
        setNotificationConversationUpdate: (state, action) => {
            state.conversationUpdate = action.payload
        },
        setNotificationNewOrder: (state, action) => {
            state.newOrder = action.payload
        },
        resetNewOrder: (state) => {
            state.newOrder = initialState.newOrder;
        },
        reset: () => initialState,

    }
});

export const {setNotificationQR, setNotificationInstanceUpdate, setNotificationConversationUpdate, setNotificationNewOrder, resetNewOrder, reset} = notificationSlice.actions;
export default notificationSlice.reducer;