import { Helmet } from 'react-helmet-async';
import { DenyReasonListView } from 'src/sections/deny-reason/view';
// sections

// ----------------------------------------------------------------------

export default function DenyReasonListPage() {
  return (
    <>
      <Helmet>
        <title>Motivos de rechazo</title>
      </Helmet>

      <DenyReasonListView />
    </>
  );
}
