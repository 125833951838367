import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useStores from 'src/hooks/use-stores';
import { useForm } from 'react-hook-form';
import { Box, Checkbox, Divider, FormControlLabel, Paper } from '@mui/material';
import FormProvider, { RHFAutocomplete } from '../hook-form';

type Props = {
  onChange: Function;
  selectAll?: boolean;
  selectedStores?: any[];
};
const StoreSeachMultiple = ({ onChange, selectAll: all, selectedStores }: Props) => {
  const [stores] = useStores({ light: true });
  const [currentStores, setCurrentStores] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(all|| false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false); // Nuevo estado para controlar la carga inicial

  const defaultValues = useMemo(
    () => ({
      stores: [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const methods = useForm<any>({
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    trigger,
    getValues,
  } = methods;

  const onSubmit = useCallback(
    async (data: any) => {
      setCurrentStores(data.stores);
      onChange(data.stores);
    },
    [onChange]
  );
  useEffect(() => {    
    const selectedStoresFilter = stores.filter(store => selectedStores?.includes(store.id));
    setValue('stores', selectedStoresFilter);   
   }, [selectedStores, setValue, stores])
   
  const handleAutocompleteChange = useCallback(
    (selectedOption: any) => {
      // Actualiza el valor del control con la opción seleccionada
      setValue('stores', selectedOption);
      // Llama a onSubmit después de que se selecciona una opción
      onSubmit(getValues());
    },
    [getValues, onSubmit, setValue]
  );

const handleToggleSelectAll = useCallback(() => {  
  setSelectAll((prev) => {    
    if (!prev)  setValue('stores', stores);
    else  setValue('stores', []);
    return !prev;
  });
  onSubmit(getValues());
},[getValues, setValue, onSubmit, stores]);
useEffect(() => {  
  if (stores && stores.length > 0 && !initialLoad && all) { // Verifica si ya se han cargado las tiendas y si se debe seleccionar todo
    setInitialLoad(true); // Marca que la carga inicial ha ocurrido
    setSelectAll(true); // Selecciona todo
    setValue('stores', stores); // Establece todas las tiendas como seleccionadas
    onSubmit(getValues());
  }
}, [stores, all, initialLoad, setValue, handleToggleSelectAll, getValues, onSubmit]);
 
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {stores && (
        <RHFAutocomplete
          limitTags={5}
          name="stores"          
          label="Comercios"
          disableClearable
          autoHighlight
          multiple          
          onChange={(event, value) => {
            handleAutocompleteChange(value);
          }}
          PaperComponent={(paperProps) => {
            const { children, ...restPaperProps } = paperProps;
            return (
              <Paper {...restPaperProps}>
                <Box
                  onMouseDown={(e) => e.preventDefault()} // prevent blur
                  pl={1.5}
                  py={0.5}
                >
                  <FormControlLabel
                    onClick={(e) => {
                      e.preventDefault(); // prevent blur
                     handleToggleSelectAll();
                    }}
                    label="Seleccionar todos"
                    control={
                      <Checkbox id="select-all-checkbox" checked={selectAll}/>
                    }
                  />
                </Box>
                <Divider />
                {children}
              </Paper>
            );
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={stores.map((option) => option)}
          getOptionLabel={(option: any) => option?.name || ''}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
        />
      )}
    </FormProvider>
  );
};
export default StoreSeachMultiple;
