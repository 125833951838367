import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, MenuItem } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Unstable_Grid2';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Mixpanel } from 'src/Mixpanel';
import FormProvider, { RHFMultiSelect, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { useResponsive } from 'src/hooks/use-responsive';
import useStoreCategories from 'src/hooks/use-stores copy';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import { ICityItem, ICountryItem, IProvinceItem } from 'src/types/location';
import { IStoreItem } from 'src/types/store';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import * as Yup from 'yup';

// Table Simple
import useLocales from 'src/locales/use-locales';

type Props = {
  currentStore?: IStoreItem;
};
type FormValuesProps = any;

export default function StoreNewEditForm() {
  const { t } = useLocales();
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const NewStoreSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    schedule_text: Yup.string().required('Los horarios de atención son requeridos'),
    city_id: Yup.string().required('La ciudad es requerida'),
    country_id: Yup.string().required('El país es requerido'),
    province_id: Yup.string().required('La provincia es requerida'),
    street: Yup.string().required('La calle es requerida'),
    house_number: Yup.string().required('El número es requerido'),
    store_categories: Yup.array().min(
      1,
      'Se debe seleccionar al menos una categoría para la tienda'
    ),
  });
  const defaultValues: any = useMemo(
    () => ({
      name: '',
      schedule_text: '',
      street: '',
      house_number: '',
      country_id: '',
      province_id: '',
      city_id: '',
      store_categories: [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewStoreSchema),
    defaultValues,
    mode: 'onBlur',
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    watch,
    trigger,
  } = methods;

  const createStore = useCallback(
    async (params: FormValuesProps) => {
      setLoading(true);
      const { name, schedule_text, street, house_number, city_id, store_categories } = params;

      const DATA = {
        name,
        schedule_text,
        city: parseInt(city_id, 10),
        street,
        house_number,
        store_categories,
      };
      axios
        .post(API_ENDPOINTS.store.createBasic, DATA)
        .then(({ data }: any) => {
          const {id} =data;          
          setLoading(false);
          Mixpanel.track('Store Creation', { name });
          enqueueSnackbar('Comercio creado con éxito');
          reset();
          router.push(paths.dashboard.store.details(id));
        })
        .catch((error) => {
          enqueueSnackbar(t(error?.error), { variant: 'error' });
        });
    },
    [enqueueSnackbar, reset, router, t]
  );

  const onSubmit = useCallback(
    async (params: FormValuesProps) => {
      createStore(params);
    },
    [createStore]
  );
  const [categories] = useStoreCategories();

  useEffect(() => {
    getCountries();
    getAllProvinces();
    getAllCities();
  }, []);

  const getCountries = () => {
    axios
      .get(API_ENDPOINTS.country.list)
      .then(({ data }) => {
        setCountries(data);
      })
      .catch((error) => {});
  };

  // TODO reiniciar la seleccion de provincias y ciudades
  const getProvince = (ev: any) => {
    const countryId = ev.target.value;
    setValue('country_id', countryId);
    setValue('province_id', '');
    setValue('city_id', '');
    axios
      .get(API_ENDPOINTS.province.list, { params: { country: countryId } })
      .then(({ data }) => {
        setProvinces(data);
      })
      .catch((error) => {});
  };
  const getAllProvinces = () => {
    axios
      .get(API_ENDPOINTS.province.list)
      .then(({ data }) => {
        setProvinces(data);
      })
      .catch((error) => {});
  };
  const getAllCities = () => {
    axios
      .get(API_ENDPOINTS.city.light)
      .then(({ data }) => {
        setCities(data);
      })
      .catch((error) => {});
  };
  // TODO reiniciar la seleccion de provincias y ciudades
  const getCities = (ev: any) => {
    const provinceId = ev.target.value;
    setValue('province_id', provinceId);
    setValue('city_id', '');
    axios
      .get(API_ENDPOINTS.city.light, { params: { province: provinceId } })
      .then(({ data }) => {
        setCities(data);
      })
      .catch((error) => {});
  };
  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={loading}
          sx={{ ml: 2 }}
        >
          Crear comercio
        </LoadingButton>
      </Grid>
    </>
  );

  const renderDetails = (
    <>
      <Grid xs={12}>
          <CardHeader title="Nuevo comercio" sx={{my:2}} />
        <Card sx={{ p: 3 }}>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="name" label="Nombre" placeholder="Ingrese el nombre del comercio" />
            <RHFMultiSelect
              chip
              label="Categoría"
              name="store_categories"
              placeholder="Seleccione una o más categorias"
              options={categories.map((category: any) => ({
                value: category.id,
                label: category.name,
              }))}
            />
            <RHFSelect
              name="country_id"
              label="País"
              InputLabelProps={{ shrink: watch().country_id.value }}
              onChange={getProvince}
              defaultValue=""
            >
              {countries.map((country: ICountryItem) => (
                <MenuItem key={country?.id} value={country?.id}>
                  {country?.name}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFSelect
              name="province_id"
              placeholder="Provincia"
              label="Provincia"
              InputLabelProps={{ shrink: watch().province_id.value }}
              onChange={getCities}
              defaultValue=""
            >
              {provinces.map((province: IProvinceItem) => (
                <MenuItem key={province?.id} value={province?.id}>
                  {province?.name}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFSelect
              name="city_id"
              placeholder="Seleccione una ciudad"
              label="Ciudad"
              InputLabelProps={{ shrink: watch().city_id.value }}
              defaultValue=""
            >
              {Array.isArray(cities) &&
                cities.map((city: ICityItem) => (
                  <MenuItem key={city?.id} value={city?.id}>
                    {city?.name}
                  </MenuItem>
                ))}
            </RHFSelect>
            <RHFTextField name="street" placeholder="Ingrese la calle" label="Calle" />
            <RHFTextField
              name="house_number"
              placeholder="Ingrese el número de calle"
              label="Calle/Número"
            />
            <RHFTextField
              label="Horarios de atención"
              name="schedule_text"
              placeholder="Ingrese los horarios de atención"
              multiline
              rows={2}
            />
          </Box>
          {renderActions}
        </Card>
      </Grid>
    </>
  );

  
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {renderDetails}        
      </Grid>
    </FormProvider>
  );
}
