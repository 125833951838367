import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// utils
import { fCurrency } from 'src/utils/format-number';
// types
import { IOrderItem } from 'src/types/order';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Typography from '@mui/material/Typography';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import { useSnackbar } from 'src/components/snackbar';

// ----------------------------------------------------------------------

type Props = {
  // row: IOrderItem;
  row: any;
  selected: boolean;
  onViewRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  onAddAddon: VoidFunction;
  onEditAddon: Function;
};

export default function ProductAddonCategoryTableRow({
  row,
  selected,
  onViewRow,
  onSelectRow,
  onDeleteRow,
  onAddAddon,
  onEditAddon
}: Props) {
  const {id, name, store, productAddonsVisibles, min, max} = row;

  const confirm = useBoolean();

  const collapse = useBoolean();

  const popover = usePopover();
  
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const deleteAddon  = (idAddon: string) => {
    axios.delete(API_ENDPOINTS.productAddon.delete(idAddon)).then((val) => {
      
      enqueueSnackbar('Variante eliminada con éxito');
      router.reload();
    }).catch((err)=>{
      
    })
  }
  const renderPrimary = (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell>
        <Box
          onClick={onViewRow}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          {name}
        </Box>
      </TableCell>

      <TableCell>
      <Typography
    sx={{
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 4,
    }}
    variant="body1">
    {store?.name}  
</Typography>
          
      </TableCell>
   
      <TableCell>
      <Typography
    sx={{
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 4,
    }}
    variant="body1">
    {min}  
</Typography>
          
      </TableCell>
      <TableCell>
      <Typography
    sx={{
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 4,
    }}
    variant="body1">
    {max}  
</Typography>
          
      </TableCell>
   


      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  const renderSecondary = (
    <>
      <TableRow>
      <TableCell sx={{ p: 0, border: 'none' }} colSpan={8}>
        <Collapse
          in={collapse.value}
          timeout="auto"
          unmountOnExit
          sx={{ bgcolor: 'background.neutral' }}
        >
          <Stack component={Paper} sx={{ m: 1.5 }}>
            {productAddonsVisibles && productAddonsVisibles?.map((item: any) => (
              <Stack
                key={`addon-${item.id}`}
                direction="row"
                alignItems="center"
                sx={{
                  p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
                  '&:not(:last-of-type)': {
                    borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}`,
                  },
                }}
              >
                <ListItemText
                  primary={item?.name}
                  secondary={item?.product?.name}
                  primaryTypographyProps={{
                    typography: 'body2',
                  }}
                  secondaryTypographyProps={{
                    component: 'span',
                    color: 'text.disabled',
                    mt: 0.5,
                  }}
                />
                <Box sx={{ width: 110, textAlign: 'right' }}>{item?.price ? fCurrency(item?.price): 'Sin costo'}</Box>
                <Box sx={{ width: 110, textAlign: 'right' }}>
                <IconButton color="primary" onClick={() => onEditAddon(item)}>
                    <Iconify icon="solar:pen-bold" />
                  </IconButton>
                </Box>
                <Box sx={{ width: 110, textAlign: 'right' }}>
                <IconButton color="error" onClick={() => deleteAddon(item.id)}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Box>
              </Stack>
            ))}
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
   </>
  );
    const handleEdit = () =>{
      router.push(paths.dashboard.productAddonCategory.edit(id));
    }
  return (
    <>
      {renderPrimary}

      {renderSecondary}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem onClick={handleEdit}>
          <Iconify icon="solar:pen-bold" />
          Editar
        </MenuItem>
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Eliminar
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Eliminar Categoría"
        content="Estás seguro que deseas eliminar esta categoría de variante?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Eliminar
          </Button>
        }
      />
    </>
  );
}
