import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { useEffect, useState } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
// components
import { useParams } from 'src/routes/hook';
import { useSettingsContext } from 'src/components/settings';

import DenyReasonNewEditForm from '../deny-reason-new-edit-form';
//

// ----------------------------------------------------------------------

export default function DenyReasonEditView() {
  const settings = useSettingsContext();

  const  [currentDenyReason, setcurrentDenyReason] = useState(undefined);
  const params = useParams();

  const { id } = params;
  
  useEffect(() => {
    axios.get(API_ENDPOINTS.denyReason.details(id || '')).then(({data}) => {
      setcurrentDenyReason(data);
    })
  }, [id])
  

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <DenyReasonNewEditForm currentDenyReason={currentDenyReason} />
    </Container>
  );
}
