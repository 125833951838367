import { useEffect, useState } from 'react'
import { useAuthContext } from 'src/auth/hooks';
import { IUserRole } from 'src/types/user';
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const useHasRoles = (roles: string[]) => {
    const { user } = useAuthContext();
    const userRoles = user?.userRoles;
    const checkRole = (element: string) => 
    userRoles && userRoles.includes(element);
    return roles && roles?.some(checkRole);
}
export default useHasRoles;